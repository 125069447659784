import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from 'src/app/shared/constants/constants';

import { APIService, UpdateUserInput, User } from 'src/app/app-sync.service';
import { UsersService } from '../users.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('userModal', { static: false }) userModal:
    | TemplateRef<any>
    | undefined;
  isActive: boolean = true;
  isViewer: boolean = false;
  isGettingUser: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  masterDisplayMap: any = {};
  user: User;
  isBlocked: boolean = false;

  private selectedUserChangedSubscription: Subscription = new Subscription();
  private onUpdateUserListener: ZenObservable.Subscription = new Subscription();

  constructor(
    private api: APIService,
    private modalsService: ModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private masterService: MasterService
  ) {
    this.user = this.usersService.getActiveUser();
    this.isViewer = this.user.authGroup.endsWith('_VIEWERS');
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
  }

  async ngOnInit() {
    this.selectedUserChangedSubscription =
      this.usersService.selectedUserChanged.subscribe((user: User) => {
        this.user = user;
        this.isViewer = this.user.authGroup.endsWith('_VIEWERS');
        this.isBlocked = this.user.status.endsWith('0002');
      });

    await this.updateSelectedUser();

    // Subscripción a actualizaciones del usuario seleccionado
    this.onUpdateUserListener = this.api
      .OnUpdateUserListener(
        this.usersService.getSelectedUserFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.usersService.refreshSelectedUser();
        }
      });
  }

  /**
   * Actualiza el usuario seleccionado.
   * @return {Promise}
   * @private
   */
  private async updateSelectedUser(): Promise<void> {
    this.isGettingUser = true;
    await this.usersService.refreshSelectedUser().then(() => {
      this.setIsActive();
      this.isGettingUser = false;
    });
  }

  /**
   * Navega al formulario de edición del usuario.
   */
  onEditUser() {
    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de activación de un usuario y lo
   * activa en caso de aceptar.
   * Nota: activar es lo opuesto a bloquear.
   * @return {Promise}
   */
  async onActivateUser(): Promise<void> {
    this.modalTitle = appConstants.user.modalMessages.activate.title;
    this.modalQuestion =
      appConstants.user.modalMessages.activate.question.replace(
        '_',
        this.user.userId
      );
    this.modalMessage = appConstants.user.modalMessages.activate.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.userModal
    );

    if (modalResult) {
      this.isGettingUser = true;
      const updateUserInput: UpdateUserInput = {
        userId: this.user.userId,
        status: `${this.user.business.toUpperCase()}_${
          appConstants.user.codes.active
        }`,
        updatedBy: this.usersService.getActiveUser().userId,
      };
      await this.api.UpdateUser(updateUserInput).then(async () => {
        await this.updateSelectedUser();
      });
    }
  }

  /**
   * Ejecuta el modal de bloqueo de un usuario y lo
   * bloquea en caso de aceptar.
   * Nota: bloquear es lo opuesto a activar.
   * @return {Promise}
   */
  async onBlockUser(): Promise<void> {
    this.modalTitle = appConstants.user.modalMessages.block.title;
    this.modalQuestion = appConstants.user.modalMessages.block.question.replace(
      '_',
      this.user.userId
    );
    this.modalMessage = appConstants.user.modalMessages.block.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.userModal
    );

    if (modalResult) {
      this.isGettingUser = true;
      const updateUserInput: UpdateUserInput = {
        userId: this.user.userId,
        status: `${this.user.business.toUpperCase()}_${
          appConstants.user.codes.blocked
        }`,
        updatedBy: this.usersService.getActiveUser().userId,
      };
      await this.api.UpdateUser(updateUserInput).then(async () => {
        await this.updateSelectedUser();
      });
    }
  }

  /**
   * Ejecuta el modal de eliminación de un usuario y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteUser(user: User): Promise<void> {
    this.modalTitle = appConstants.user.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.user.modalMessages.delete.question.replace(
        '_',
        this.user.userId
      );
    this.modalMessage = appConstants.user.modalMessages.delete.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.userModal
    );

    if (modalResult) {
      await this.usersService.deleteSelectedUser();

      this.router
        .navigate(['/', user.business.toLowerCase(), 'users'])
        .then(() => console.log('navigate to users'));
    }
  }

  /**
   * Retorna un color dependiendo del estado del usuario.
   * @return {string}
   */
  getColor(): string {
    return this.usersService.getSatusColor(this.user);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del usuario.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.usersService.getImageSrc(status, this.user.business);
  }

  /**
   * Establece en valor de la variable isActive que da cuenta si
   * un usuario está activo o bloqueado.
   * @private
   */
  private setIsActive() {
    this.isActive =
      this.user.status ===
      `${this.user.business}_${appConstants.user.codes.active}`;
  }

  /**
   * Retorna la lista legible de negocios a los que tiene acceso el usuario.
   * @param {string} hasAccessTo ID de negocios separados por coma.
   * @return {string}
   */
  listBusinessWithAccess(hasAccessTo: string | null | undefined): string {
    let listToReturn: string[] = [];
    if (hasAccessTo) {
      for (let business of hasAccessTo.split(',')) {
        listToReturn.push(this.masterDisplayMap.BUSINESSES[business]);
      }
    }
    return listToReturn.join(', ');
  }

  /**
   * Retorna la lista legible de centros a los que el usuario tiene acceso.
   * @param {string} centers JSON en formato string. Cada atributo en un nombre
   * de negocio y su valor en una lista de ID de centros.
   * @return {string}
   */
  listCentersWithAccess(centers: string | null | undefined): string {
    const centersObject = JSON.parse(
      centers || `{"${this.user.business}": []}`
    );
    let listToReturn: string[] = [];

    for (const business of Object.keys(centersObject)) {
      for (const center of centersObject[business]) {
        if (this.masterDisplayMap.CENTERS[center]) {
          listToReturn.push(this.masterDisplayMap.CENTERS[center]);
        } else {
          listToReturn.push(center.split('_')[1]);
        }
      }
    }

    return listToReturn.join(', ');
  }

  ngOnDestroy(): void {
    this.selectedUserChangedSubscription.unsubscribe();
    this.onUpdateUserListener.unsubscribe();
    console.log('user-details.component subscriptions removed.');
  }
}
