<div class="modal-header">
  <h4 class="modal-title" id="update-attr-modal-basic-title">
    Detalles del Vehículo Archivado
  </h4>
  <button
    id="cisterna-details-update-attr-modal-close"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="d('Cross')"
  ></button>
</div>
<div style="padding: 10px" class="table-container">
  <table class="table table-hover">
    <caption>
      Detalles del Vehículo
    </caption>
    <tbody>
      <tr>
        <th class="align-middle" scope="row">Comentario de archivo</th>
        <td>
          <div>
            {{ comment }}
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">Patente</th>
        <td>{{ archive.licensePlate }}</td>
      </tr>
      <tr>
        <th scope="row">Fecha de Archivado</th>
        <td>{{ updatedAt | dateFormat | dateWithoutSeconds }}</td>
      </tr>
    </tbody>

    <tbody>
      <tr>
        <th scope="row">Sap Id</th>
        <td>{{ archive.sapId }}</td>
      </tr>
    </tbody>

    <tbody>
      <tr>
        <th scope="row">Trasportista</th>
        <td>{{ archive.carrier!.name }}</td>
      </tr>
      <tr>
        <th scope="row">Centro</th>
        <td>{{ archive.originalCenter! | master : "CENTERS" }}</td>
      </tr>
      <tr>
        <th scope="row">Marca</th>
        <td>{{ archive.brand | master : "VEHICLES#BRANDS" }}</td>
      </tr>
      <tr>
        <th scope="row">Modelo</th>
        <td>{{ archive.model }}</td>
      </tr>
      <tr>
        <th scope="row">Año de Fabricación</th>
        <td>{{ archive.year }}</td>
      </tr>
      <tr>
        <th scope="row">Antigüedad</th>
        <td>
          {{ currentYear - archive.year }} año{{
            currentYear - archive.year === 1 ? "" : "s"
          }}
        </td>
      </tr>
      <tr>
        <th scope="row">Color</th>
        <td>{{ archive.color | master : "VEHICLES#COLORS" }}</td>
      </tr>
      <tr>
        <th scope="row">Tipo</th>
        <td>{{ archive.type | master : "TRACTOS#TYPES" }}</td>
      </tr>
      <tr>
        <th scope="row">Motor</th>
        <td>
          {{ archive.engineType! | master : "TRACTOS#ENGINE_TYPES" }}
        </td>
      </tr>
      <tr>
        <th scope="row">Capacidad</th>
        <td>
          {{ archive.capacity | master : "TRACTOS#CAPACITIES" }}
        </td>
      </tr>
      <tr>
        <th scope="row">Usado en Minería</th>
        <td>{{ archive.forMining ? "Sí" : "No" }}</td>
      </tr>
      <tr>
        <th scope="row">Acreditación Ranchera</th>
        <td>{{ archive.hasRanchAccreditation ? "Sí" : "No" }}</td>
      </tr>
      <tr>
        <th scope="row">Es Spot</th>
        <td>{{ archive.isSpot ? "Sí" : "No" }}</td>
      </tr>
      <tr>
        <th scope="row">GPS IMEI</th>
        <td>{{ archive.gpsImei }}</td>
      </tr>
      <tr>
        <th scope="row">Cámara IMEI</th>
        <td>{{ archive.cameraImei }}</td>
      </tr>
      <tr>
        <th class="align-middle" scope="row">Centro de Apoyo</th>
        <td>
          <div>
            {{ archive.center | master : "CENTERS" }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
