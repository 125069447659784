export const appConstants = {
  entity: {
    codes: {
      toBeApproved: '0000001',
      available: '0000002',
      blocked: '0000003',
      inProcess: '0000004',
      rejected: '0000005',
    },
  },
  vehicle: {
    displayName: 'Vehículo',
    modalMessages: {
      delete: generateDeleteModalMessages('Vehículo', 'm'),
      approve: generateApproveModalMessages('Vehículo', 'm'),
      reject: generateRejectModalMessages('Vehículo', 'm'),
      archive: generateArchiveModalMessages('Vehículo', 'm'),
    },
    initialization: {
      __typename: 'Vehicle',
      vehicleId: '',
      brand: '',
      licensePlate: '',
      color: '',
      model: '',
      year: 0,
      imei: '',
      type: '',
      capacity: '',
      business: '',
      center: '',
      status: '',
      createdAt: '',
      updatedAt: '',
    },
    ageToBeConsideredOld: 7,
  },
  envasado: {
    displayName: 'Envasado',
    modalMessages: {
      delete: generateDeleteModalMessages('Envasado Rígido', 'm'),
      approve: generateApproveModalMessages('Envasado Rígido', 'm'),
      reject: generateRejectModalMessages('Envasado Rígido', 'm'),
      edit: generateEditModalMessages('Envasado Rígido', 'm'),
      archive: generateArchiveModalMessages('Envasado Rígido', 'm'),
    },
    initialization: {
      __typename: 'Envasado',
      envasadoId: '',
      brand: '',
      licensePlate: '',
      color: '',
      model: '',
      year: 0,
      type: '',
      capacity: '',
      business: '',
      gpsImei: '',
      cameraImei: '',
      forMining: false,
      engineType: '',
      hasRanchAccreditation: false,
      bodyType: '',
      maximumPalletCapacity: 0,
      height: 0,
      width: 0,
      length: 0,
      center: '',
      status: '',
      createdAt: '',
      updatedAt: '',
    },
    ageToBeConsideredOld: 7,
  },
  cisterna: {
    displayName: 'Cisterna',
    modalMessages: {
      delete: generateDeleteModalMessages('Cisterna', 'f'),
      approve: generateApproveModalMessages('Cisterna', 'f'),
      reject: generateRejectModalMessages('Cisterna', 'f'),
      edit: generateEditModalMessages('Cisterna', 'f'),
      archive: generateArchiveModalMessages('Cisterna', 'f'),
    },
    initialization: {
      __typename: 'Cisterna',
      cisternaId: '',
      brand: '',
      licensePlate: '',
      color: '',
      model: '',
      year: 0,
      type: '',
      capacity: '',
      business: '',
      subBusiness: '',
      gpsImei: '',
      cameraImei: '',
      forMining: false,
      hasRanchAccreditation: false,
      engineType: '',
      bulkheadType: '',
      hasPump: false,
      center: '',
      status: '',
      createdAt: '',
      updatedAt: '',
    },
    ageToBeConsideredOld: 7,
  },
  tracto: {
    displayName: 'Tracto',
    modalMessages: {
      delete: generateDeleteModalMessages('Tracto', 'm'),
      approve: generateApproveModalMessages('Tracto', 'm'),
      reject: generateRejectModalMessages('Tracto', 'm'),
      edit: generateEditModalMessages('Tracto', 'm'),
      archive: generateArchiveModalMessages('Tracto', 'm'),
    },
    initialization: {
      __typename: 'Tracto',
      tractoId: '',
      brand: '',
      licensePlate: '',
      color: '',
      model: '',
      year: 0,
      type: '',
      capacity: '',
      business: '',
      gpsImei: '',
      cameraImei: '',
      forMining: false,
      hasRanchAccreditation: false,
      engineType: '',
      center: '',
      status: '',
      createdAt: '',
      updatedAt: '',
    },
    ageToBeConsideredOld: 7,
  },
  tanque: {
    displayName: 'Tanque',
    modalMessages: {
      delete: generateDeleteModalMessages('Tanque', 'm'),
      approve: generateApproveModalMessages('Tanque', 'm'),
      reject: generateRejectModalMessages('Tanque', 'm'),
      edit: generateEditModalMessages('Tanque', 'm'),
      archive: generateArchiveModalMessages('Tanque', 'm'),
    },
    initialization: {
      __typename: 'Tanque',
      tanqueId: '',
      brand: '',
      licensePlate: '',
      color: '',
      model: '',
      year: 0,
      type: '',
      capacity: '',
      business: '',
      subBusiness: '',
      gpsImei: '',
      cameraImei: '',
      forMining: false,
      hasRanchAccreditation: false,
      bulkheadType: '',
      hasPump: false,
      height: 0,
      width: 0,
      length: 0,
      center: '',
      status: '',
      createdAt: '',
      updatedAt: '',
    },
  },
  semirremolque: {
    displayName: 'Semirremolque',
    modalMessages: {
      delete: generateDeleteModalMessages('Semirremolque', 'm'),
      approve: generateApproveModalMessages('Semirremolque', 'm'),
      reject: generateRejectModalMessages('Semirremolque', 'm'),
      edit: generateEditModalMessages('Semirremolque', 'm'),
      archive: generateArchiveModalMessages('Semirremolque', 'm'),
    },
    initialization: {
      __typename: 'Semirremolque',
      semirremolqueId: '',
      brand: '',
      licensePlate: '',
      color: '',
      model: '',
      year: 0,
      type: '',
      capacity: '',
      business: '',
      subBusiness: '',
      gpsImei: '',
      cameraImei: '',
      forMining: false,
      hasRanchAccreditation: false,
      rampType: '',
      MOPCode: '',
      maximumPalletCapacity: 0,
      height: 0,
      width: 0,
      length: 0,
      center: '',
      status: '',
      createdAt: '',
      updatedAt: '',
    },
  },
  couple: {
    modalMessages: {
      coupling: {
        title: 'Acoplar',
        question: '¿Desea acoplar el tracto ID _ al _ ID _?',
        message:
          'Tanto el tracto como el _ serán enviados a validar para actualizar sus estados.',
      },
      uncoupling: {
        title: 'Desacoplar',
        question: '¿Desea desacoplar el tracto ID _ del _ ID _?',
        message:
          'Tanto el tracto como el _ serán enviados a validar para actualizar sus estados.',
      },
    },
  },
  driver: {
    displayName: 'Conductor',
    modalMessages: {
      delete: generateDeleteModalMessages('Conductor', 'm'),
      approve: generateApproveModalMessages('Conductor', 'm'),
      reject: generateRejectModalMessages('Conductor', 'm'),
      exclude: generateBlockModalMessages('Conductor', 'm'),
      unlock: generateUnlockModalMessages('Conductor', 'm'),
      archive: generateArchiveModalMessages('Conductor', 'm'),
      edit: generateEditModalMessages('Conductor', 'm'),
    },
    initialization: {
      __typename: 'Driver',
      driverId: '',
      firstName: '',
      lastName: '',
      rut: '',
      birthDate: '',
      phone: '',
      email: '',
      business: '',
      center: '',
      status: '',
      createdAt: '',
      updatedAt: '',
    },
    blockingMotives: {
      speeding: 'Exceso de Velocidad',
    },
    age: {
      max: 70,
      min: 25,
    },
  },
  document: {
    codes: {
      toBeApproved: '0000001',
      valid: '0000002',
      expired: '0000003',
      inProcess: '0000004',
      recertification_of_manufacturing: '0000004',
      speeding_retraining: '0000014',
      commitment_letter_for_speeding: '0000015',
      explanatory_letter: '1000101',
    },
    initialization: {
      __typename: 'Document',
      documentId: '',
      name: '',
      s3Path: '',
      owner: '',
      expirationDate: '',
      masterValueId: '',
      status: '',
      issueDate: '',
      createdAt: '',
      updatedAt: '',
    },
  },
  user: {
    codes: {
      active: '0000001',
      blocked: '0000002',
    },
    modalMessages: {
      activate: {
        title: 'Activar Usuario',
        question: '¿Desea activar el usuario _?',
        message:
          'Esta acción permite al usuario navegar por la aplicación y realizar las acciones que su Grupo le permita.',
      },
      block: {
        title: 'Bloquear Usuario',
        question: '¿Desea bloquear el usuario _?',
        message:
          'Esta acción bloquea al usuario de navegar por la página y obtener datos.',
      },
      delete: generateDeleteModalMessages('Usuario', 'm', false),
    },
    initialization: {
      __typename: 'User',
      userId: '',
      firstName: '',
      lastName: '',
      rut: '',
      phone: '',
      email: '',
      business: '',
      authGroup: '',
      status: '',
      company: '',
      createdAt: '',
      updatedAt: '',
    },
  },
  carrier: {
    initialization: {
      __typename: 'Carrier',
      carrierId: '',
      name: '',
      rut: '',
      email: '',
      createdAt: '',
      updatedAt: '',
    },
  },
  rozForm: {
    initialization: {
      __typename: 'RozForm',
      formId: '',
      formDate: '',
      status: '',
      createdBy: '',
      updatedBy: '',
      createdAt: '',
      updatedAt: '',
    },
    codes: {
      approved: 'APPROVED',
      reproved: 'REPROVED',
    },
    modalMessages: {
      saveForm: {
        title: 'Guardar formulario',
        question: '¿Desea guardar el formulario para el vehículo _?',
        message:
          'se cargará automáticamente la próxima vez que se cargue este vehículo durante el mes.',
      },
      submitForm: {
        title: 'Enviar formulario',
        question:
          '¿Desea enviar el resultado del formulario para el vehículo _?',
        message:
          'se cargará automáticamente la próxima vez que se cargue este vehículo durante el mes.',
      },
      addComment: {
        title: 'Observación',
        question: 'Evaluando: _',
        message: 'Agregue una observación.',
      },
      deleteImage: {
        title: 'Borrar imagen',
        question: '¿Desea borrar la imagen de la evaluación: _?',
        message: 'Esta acción no puede ser deshecha.',
      },
    },
  },
  master: {
    initialization: {
      __typename: 'Master',
      businessId: '',
      masterId: '',
      masterGroup: '',
      valueId: '',
      valueToDisplay: '',
      createdAt: '',
      updatedAt: '',
    },
    arrayInitialization: {
      CARRIERS: {},
      CENTERS: {},
      'DOCUMENTS#STATUSES': {},
      'DRIVERS#DOCUMENT_TYPES': {},
      'DRIVERS#PERMISSION_NUMBER': {},
      'DRIVERS#STATUSES': {},
      'USERS#STATUSES': {},
      'VEHICLES#BRANDS': {},
      'VEHICLES#CAPACITIES': {},
      'VEHICLES#COLORS': {},
      'VEHICLES#DOCUMENT_TYPES': {},
      'VEHICLES#ROUTES': {},
      'VEHICLES#STATUSES': {},
      'VEHICLES#TRANSPORT_PLANNING_POINT': {},
      'VEHICLES#TYPES': {},
      VEHICLES: {},
    },
  },
  others: {
    modalMessages: {
      downloadExcel: {
        title: 'Descargar Datos',
        question: '¿Desea descargar datos de Roz en formato excel?',
        message: 'Seleccione a continuación los datos de interés.',
      },
    },
  },
  regex: {
    name: /^[a-zA-ZàáâäãåąćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
    rut: /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/,
    phone: /^\+\d{11}$/,
    licensePlate: /^([a-zA-Z0-9]{6})$/,
    year: /^[1-2]\d{3}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  },
  datatables: {
    options: {
      scrollX: true,
      pagingType: 'simple_numbers',
      pageLength: 10,
      lengthChange: false,
      language: {
        emptyTable: 'No se encontraron registros',
        info: 'Mostrando _START_ de _END_ de un total de _TOTAL_ registros',
        infoEmpty: 'Mostrando 0 de 0 de un total de 0 registros',
        infoFiltered: '(filtrado de un total de _MAX_ registros)',
        search: 'Buscar: ',
        searchPlaceholder: 'Buscar',
        zeroRecords: 'No se encontró coincidencia',
        paginate: {
          first: 'Primera',
          last: 'Última',
          next: '>',
          previous: '<',
        },
      },
    },
  },
  colors: {
    statuses: {
      toBeApproved: '#67C2F7',
      available: '#4CD187',
      blocked: '#EA212E',
      inProcess: '#A6A6A6',
      rejected: '#F7A6AB',
    },
  },
  motives: {
    exclude: {
      other: '1000001',
    },
    archive: {
      idChange: '0000001',
      other: '1000001',
    },
  },
} as const;

/**
 * Retorna un objeto con el título, pregunta y mensaje usado en modales de borrado.
 * @param {string} entityName Nombre de la entidad.
 * @param {'m' | 'f'} gender Género de la entidad.
 * @param {boolean} hasDocuments Indica si la entidad es dueña de documentos.
 */
function generateDeleteModalMessages(
  entityName: string,
  gender: 'm' | 'f',
  hasDocuments: boolean = true
) {
  let message: string = `Se eliminarán los datos ${
    gender === 'm' ? 'del' : 'de la'
  } ${entityName.toLowerCase()}`;
  message += hasDocuments
    ? ' pero el historial de documentos permanecerá almacenado.'
    : '.';
  return {
    title: `Borrar ${entityName}`,
    question: `¿Desea borrar ${
      gender === 'm' ? 'el' : 'la'
    } ${entityName.toLowerCase()} _?`,
    message: message,
  };
}

/**
 * Retorna un objeto con el título, pregunta y mensaje usado en modales de aprobación.
 * @param {string} entityName Nombre de la entidad.
 * @param {'m' | 'f'} gender Género de la entidad.
 */
function generateApproveModalMessages(entityName: string, gender: 'm' | 'f') {
  let message: string = 'Se aplicarán reglas de negocio para determinar si ';
  message += `${
    gender === 'm' ? 'el' : 'la'
  } ${entityName.toLowerCase()} queda disponible o bloqueado.`;
  return {
    title: `Aprobar ${entityName}`,
    question: `¿Desea aprobar ${
      gender === 'm' ? 'el' : 'la'
    } ${entityName.toLowerCase()} _?`,
    message: message,
  };
}

/**
 * Retorna un objeto con el título, pregunta y mensaje usado en modales de rechazo.
 * @param {string} entityName Nombre de la entidad.
 * @param {'m' | 'f'} gender Género de la entidad.
 */
function generateRejectModalMessages(entityName: string, gender: 'm' | 'f') {
  let message: string =
    'Su comentario irá adjunto en la notificación que se envíe.';
  return {
    title: `Rechazar ${entityName}`,
    question: `¿Desea rechazar ${
      gender === 'm' ? 'el' : 'la'
    } ${entityName.toLowerCase()} _?`,
    message: message,
  };
}

/**
 * Retorna un objeto con el título, pregunta y mensaje usado en modales de edición.
 * @param {string} entityName Nombre de la entidad.
 * @param {'m' | 'f'} gender Género de la entidad.
 */
function generateEditModalMessages(entityName: string, gender: 'm' | 'f') {
  let message: string = 'Los cambios se reflejarán en Roz y SAP.';
  return {
    title: `Editar ${entityName}`,
    question: `¿Desea editar ${
      gender === 'm' ? 'el' : 'la'
    } ${entityName.toLowerCase()} _?`,
    message: message,
  };
}
function generateBlockModalMessages(entityName: string, gender: 'm' | 'f') {
  let message: string = 'Seleccione el motivo para bloquear  al ' + entityName;
  return {
    title: `Bloquear ${entityName}`,
    question: `¿Desea bloquear  ${
      gender === 'm' ? 'el' : 'la'
    } ${entityName.toLowerCase()} _?`,
    message: message,
  };
}
/**
 * Retorna un objeto con el título, pregunta y mensaje usado en modales de desbloqueo.
 * @param {string} entityName Nombre de la entidad.
 * @param {'m' | 'f'} gender Género de la entidad.
 */
function generateUnlockModalMessages(entityName: string, gender: 'm' | 'f') {
  let message: string = 'Se aplicarán reglas de negocio para determinar si ';
  message += `${
    gender === 'm' ? 'el' : 'la'
  } ${entityName.toLowerCase()} queda disponible.`;
  return {
    title: `Desbloquear ${entityName}`,
    question: `¿Desea desbloquear ${
      gender === 'm' ? 'el' : 'la'
    } ${entityName.toLowerCase()} _?`,
    message: message,
  };
}

/**
 * Retorna un objeto con el título, pregunta y mensaje usado en modales de archivado.
 * @param {string} entityName Nombre de la entidad.
 * @param {'m' | 'f'} gender Género de la entidad.
 */
function generateArchiveModalMessages(entityName: string, gender: 'm' | 'f') {
  let message: string = `Esta acción archivará ${
    gender === 'm' ? 'el' : 'la'
  } ${entityName.toLowerCase()} y le permitirá crear una nueva entidad con las mismas características.`;
  message += `\n ${gender === 'm' ? 'El' : 'La'} ${
    gender === 'm' ? 'nuevo' : 'nueva'
  } ${entityName.toLowerCase()} quedará ${
    gender === 'm' ? 'vinculado al' : 'vinculada a la'
  } archivado.`;
  return {
    title: `Archivar ${entityName}`,
    question: `¿Desea archivar ${
      gender === 'm' ? 'el' : 'la'
    } ${entityName.toLowerCase()} _?`,
    message: message,
  };
}
