<footer class="rounded-top" style="background-color: #ccd7e1">
  <div class="container-fluid p-2 mt-3">
    <div class="row align-items-center" style="color: #336184; font-size: 13px">
      <div class="col-md-3 text-center">
        <img
          src="assets/images/logo/Copec_2025_90.png"
          height="35"
          width="111"
          alt="logoCopec90"
        />
      </div>
      <div class="col-md-6 text-center">
        © Copyright {{ currentYear }} Compañía de Petróleos de Chile Copec S.A.
        Todos los derechos reservados.
      </div>
      <div class="col-md-3 text-center">
        <img
          src="assets/images/logo/ROZ-alto-contraste.png"
          height="35"
          width="auto"
          alt="logoRoz"
        />
      </div>
    </div>
  </div>
</footer>
