import { AbstractControl, ValidationErrors } from '@angular/forms';

export function validarRUT(control: AbstractControl): ValidationErrors | null {
  const rut = control.value;

  if (!rut || typeof rut !== 'string') {
    return { invalidRUT: 'El RUT no puede estar vacío.' };
  }

  // Quitar puntos, guiones y espacios
  const rutLimpio = rut.replace(/\./g, '').replace(/-/g, '');

  if (!/^[0-9]+[0-9kK]{1}$/.test(rutLimpio)) {
    return {
      invalidRUT: 'El RUT debe contener solo números y un dígito verificador.',
    };
  }

  const cuerpo = rutLimpio.slice(0, -1);
  const dv = rutLimpio.slice(-1).toUpperCase();

  if (cuerpo.length < 7 || cuerpo.length > 8) {
    return { invalidRUT: 'El RUT no es válido.' };
  }

  // Calcular el dígito verificador
  let suma = 0;
  let multiplo = 2;

  for (let i = cuerpo.length - 1; i >= 0; i--) {
    suma += parseInt(cuerpo[i], 10) * multiplo;
    multiplo = multiplo < 7 ? multiplo + 1 : 2;
  }

  const resto = suma % 11;
  let dvCalculado: string;
  if (resto === 0) {
    dvCalculado = '0';
  } else if (resto === 1) {
    dvCalculado = 'K';
  } else {
    dvCalculado = (11 - resto).toString();
  }
  if (dv !== dvCalculado) {
    return { invalidRUT: 'El RUT no es válido.' };
  }

  return null; // RUT válido
}
