import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { appConstants } from 'src/app/shared/constants/constants';
import { FormValidators } from '../../../shared/interfaces/form-validators';
import { DocumentSpecsObject } from '../../../shared/interfaces/document-specs-object';

import {
  APIService,
  CreateVehicleInput,
  CreateVehicleMutation,
  UpdateVehicleInput,
  Vehicle,
  Document,
  CreateNotificationInput,
} from 'src/app/app-sync.service';
import { DocumentsService } from 'src/app/pages/documents/documents.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';
import { MasterService } from 'src/app/pages/master/master.service';
import { VehiclesService } from '../vehicles.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { SettingsService } from 'src/app/pages/settings/settings.service';
import { FormsService } from '../../../shared/services/forms.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { EditionsService } from '../../../shared/services/editions.service';
import { ArchivesService } from '../../../shared/services/archives.service';
import { CommonsService } from '../../../shared/services/commons.service';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.css'],
})
export class VehicleEditComponent implements OnInit, OnDestroy {
  entityName: string = 'Vehículo';
  selectedVehicle: Vehicle | undefined = undefined;
  vehicleIdentification: string = '';
  currentYear: number = new Date().getFullYear();
  editMode: boolean = true;
  archiveMode: boolean = false;
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isGettingVehicle: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};
  documentsSpecs: DocumentSpecsObject = {};
  selectedCenter: string = '';
  vehicleForm: FormGroup;
  vehicleHasTrailer: boolean = false;
  vehicleId: string = '';
  vehicleIsOld: boolean = false;
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  business: string;
  editionId: string = '';
  private circulation_permit_code: string = '0000007';

  get documentsControl() {
    return (<FormArray>this.vehicleForm.get('documents')).controls;
  }

  get disableSubmit() {
    return (
      this.vehicleForm.invalid ||
      this.vehicleForm.pristine ||
      this.vehicleForm.untouched
    );
  }

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private vehiclesService: VehiclesService,
    private formsService: FormsService,
    private editionsService: EditionsService,
    private archivesService: ArchivesService,
    private commonsService: CommonsService
  ) {
    this.vehicleForm = new FormGroup({
      carrier: new FormControl(null),
      licensePlate: new FormControl(null),
      brand: new FormControl(null),
      model: new FormControl(null),
      year: new FormControl(null),
      color: new FormControl(null),
      type: new FormControl(null),
      capacity: new FormControl(null),
      center: new FormControl(null),
      isSpot: new FormControl(null),
      imei: new FormControl(null),
      cameraImei: new FormControl(null),
      documents: this.formsService.documentsFormArrayInitialization(),
      trailerLicensePlate: new FormControl(null),
    });
    this.business = this.usersService.business.value.toUpperCase();
  }

  ngOnInit(): void {
    this.isGettingVehicle = true;
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.archiveMode = this.archivesService.getArchiving();
    this.selectedVehicle = this.vehiclesService.getSelectedVehicle();
    this.vehicleIdentification = this.commonsService.getIdentification(
      this.selectedVehicle.sapId!,
      this.selectedVehicle.licensePlate
    );

    this.route.params.subscribe(async (params: Params): Promise<void> => {
      // Si la ruta contiene un ID de vehículo
      // estamos en modo edición
      this.editMode = params['id'] != null;
      this.vehicleId = params['id'];

      this.initVehicleForm();
      if (!this.editMode) {
        if (this.settingsService.getSelectedModel() !== 'VEHICLE') {
          console.log('Loading document specifications');
          this.settingsService.setSelectedModel('VEHICLE');
          await this.settingsService.setDocumentsSpecs();
        } else {
          console.log('Reusing document specifications');
        }
        this.documentsSpecs = this.settingsService.getDocumentsSpecs();
      } else {
        this.editionId = `${this.business}#VEHICLE#${this.vehicleId}`;
        await this.editionsService.createOrUpdateEdition(this.editionId);
      }

      // Ordenamos alfabéticamente el formulario de editar las propiedades
      this.documentsControl.sort((a, b) => {
        const documentNameA = a.value.documentName.toLowerCase();
        const documentNameB = b.value.documentName.toLowerCase();
        return documentNameA.localeCompare(documentNameB);
      });

      this.isGettingVehicle = false;
    });
  }

  /* ------------------------------------------- */
  /* - Métodos para la creación del Formulario - */
  /* ------------------------------------------- */
  /**
   * Inicializa el formulario para crear o
   * editar un vehículo.
   * @private
   */
  private initVehicleForm(): void {
    // Inicialización del Formulario
    // Por default, los campos estarán vacíos.
    // Si el usuario es Transportista el campo carrier campo estará seleccionado.
    const editingOrArchiving: boolean = this.editMode || this.archiveMode;
    let initialVehicle: Vehicle = {
      ...appConstants.vehicle.initialization,
      carrier: appConstants.carrier.initialization,
    };
    let vehicleDocuments: FormArray =
      this.formsService.documentsFormArrayInitialization();

    // Si es transportista la única opción de carrier es él mismo.
    initialVehicle.carrier.carrierId = this.isCarrier
      ? this.usersService.getActiveUser().company
      : '';

    if (editingOrArchiving) {
      // En modo de Edición: cargamos los valores de los campos
      // según el vehículo escogido.
      // En modo Archivar: cargamos los valores de los campos según
      // el vehículo archivado (que sigue siendo el seleccionado).
      initialVehicle = this.selectedVehicle!;
      const selectedVehicleDocuments: Document[] =
        this.documentsService.getDocuments();

      const vehicleAge: number = this.currentYear - initialVehicle.year;
      let vehicleHasRecertificationOfManufacturing: boolean = false;
      this.vehicleHasTrailer = !!initialVehicle.trailerLicensePlate;

      let documentMasterValueIdsList: string[] = [];
      vehicleDocuments.removeAt(0);
      for (let document of selectedVehicleDocuments) {
        vehicleDocuments.push(
          this.formsService.createDocumentControlFromDocument(
            document,
            !this.archiveMode
          )
        );
        documentMasterValueIdsList.push(document.masterValueId);
        // Verificamos si el Vehículo tiene el Re-certificado de Fabricación
        if (
          document.masterValueId ===
          `${this.business}_${appConstants.document.codes.recertification_of_manufacturing}`
        ) {
          vehicleHasRecertificationOfManufacturing = true;
        }
      }

      // Si la edad del vehículo es >= appConstants.vehicle.ageToBeConsideredOld
      // y el vehículo NO tiene asignado un documento Re-certificado de Fabricación
      // se agrega al formulario.
      if (
        vehicleAge >= appConstants.vehicle.ageToBeConsideredOld &&
        !vehicleHasRecertificationOfManufacturing
      ) {
        // Agregamos el Re-certificado de Fabricación
        const index = this.master['VEHICLES#DOCUMENT_TYPES'].findIndex(
          (document: any) => {
            return (
              document.valueId ===
              `${this.business}_${appConstants.document.codes.recertification_of_manufacturing}`
            );
          }
        );
        const document = this.master['VEHICLES#DOCUMENT_TYPES'][index];

        vehicleDocuments.push(
          this.formsService.createDocumentControlFromMaster(document)
        );
        this.vehicleIsOld = true;
      }

      // Agregamos al formulario los documentos que existen en el maestro y que no
      // han sido cargados hasta el momento.
      vehicleDocuments = this.setAdditionalDocumentsFormGroup(
        initialVehicle.center,
        vehicleDocuments,
        documentMasterValueIdsList
      );
    } else {
      // En modo Creación: Creamos un FormControl por cada documento
      // requerido según el Maestro
      vehicleDocuments = this.setInitialDocumentsFormGroup('');
    }

    // Inicialización del formulario
    this.vehicleForm = new FormGroup({
      carrier: new FormControl(
        { value: initialVehicle.carrier.carrierId, disabled: this.editMode },
        Validators.required
      ),
      licensePlate: new FormControl(
        {
          value: initialVehicle.licensePlate,
          disabled: this.disableLicensePlate(),
        },
        [
          Validators.required,
          Validators.pattern(appConstants.regex.licensePlate),
        ],
        [this.validatorsService.licensePlateInUseValidator()]
      ),
      brand: new FormControl(
        { value: initialVehicle.brand, disabled: this.editMode },
        Validators.required
      ),
      model: new FormControl(
        { value: initialVehicle.model, disabled: this.editMode },
        Validators.required
      ),
      year: new FormControl(
        {
          value: initialVehicle.year === 0 ? null : initialVehicle.year,
          disabled: this.editMode,
        },
        [Validators.required, Validators.pattern(appConstants.regex.year)]
      ),
      color: new FormControl(
        { value: initialVehicle.color, disabled: this.editMode },
        Validators.required
      ),
      type: new FormControl(
        { value: initialVehicle.type, disabled: this.editMode },
        Validators.required
      ),
      capacity: new FormControl(
        { value: initialVehicle.capacity, disabled: this.editMode },
        Validators.required
      ),
      center: new FormControl(
        { value: initialVehicle.center, disabled: this.editMode },
        Validators.required
      ),
      isSpot: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialVehicle.isSpot
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      imei: new FormControl({
        value: initialVehicle.imei,
        disabled: this.editMode,
      }),
      cameraImei: new FormControl({
        value: initialVehicle.cameraImei,
        disabled: this.editMode,
      }),
      documents: vehicleDocuments,
    });

    // Si estamos en modo edición y el vehículo tiene
    // semirremolque agregamos el control de la patente.
    if (this.editMode && this.vehicleHasTrailer) {
      this.vehicleForm.addControl(
        'trailerLicensePlate',
        new FormControl(
          {
            value: initialVehicle.trailerLicensePlate,
            disabled: this.editMode,
          },
          [
            Validators.required,
            Validators.pattern(appConstants.regex.licensePlate),
          ]
        )
      );
    }
  }

  /**
   * Define el arreglo de controles de documentos para el formulario.
   * @param {string} center Centro al que pertenece el vehículo.
   * @return {FormArray}
   * @private
   */
  private setInitialDocumentsFormGroup(center: string): FormArray {
    let vehicleDocuments = this.formsService.documentsFormArrayInitialization();

    let documentTypes = this.master['VEHICLES#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    vehicleDocuments.removeAt(0);
    const business: string = this.usersService.business.value.toUpperCase();
    for (let document of documentTypes) {
      // Se excluyen:
      // - Re-certificado de Fabricación, ya que se agrega según el año de fabricación.
      // - Permiso de Circulación del semirremolque que se agrega si tiene semirremolque.
      if (
        document.valueId !==
          `${business}_${appConstants.document.codes.recertification_of_manufacturing}` &&
        document.valueId !== `${business}_${this.circulation_permit_code}`
      ) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        vehicleDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return vehicleDocuments;
  }

  /**
   * Dado un conjunto de documentos, agrega los controles de documentos
   * faltantes según el maestro, para el formulario.
   * @param {string} center Centro al que pertenece el vehículo.
   * @param {FormArray} vehicleDocuments Arreglo de controles de documentos.
   * @param {string[]} documentMasterValueIdsList Lista de Ids de documentos incluídos en el vehicleDocuments.
   * @return {FormArray}
   * @private
   */
  private setAdditionalDocumentsFormGroup(
    center: string,
    vehicleDocuments: FormArray,
    documentMasterValueIdsList: string[]
  ): FormArray {
    let documentTypes = this.master['VEHICLES#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    const business: string = this.usersService.business.value.toUpperCase();
    for (let document of documentTypes) {
      // Se excluye:
      // - Re-certificado de Fabricación, ya que se agrega según el año de fabricación.
      // - Permiso de Circulación del semirremolque que se agrega si tiene semirremolque.
      // Se agregan solo los documentos que no se encuentren ya en el arreglo de controles.
      if (
        document.valueId !==
          `${business}_${appConstants.document.codes.recertification_of_manufacturing}` &&
        document.valueId !== `${business}_${this.circulation_permit_code}` &&
        documentMasterValueIdsList.indexOf(document.valueId) === -1
      ) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        vehicleDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return vehicleDocuments;
  }

  /**
   * Crea un objeto con las funciones de validación para cada uno
   * de los controles del formulario.
   * @param {string }center Centro al que pertenece el vehículo.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {FormValidators}
   * @private
   */
  private getValidators(
    masterValueId: string,
    center: string = ''
  ): FormValidators {
    const isMandatory: boolean =
      this.documentsSpecs[center + '#' + masterValueId];
    return this.validatorsService.getDocumentValidatorForm(center, isMandatory);
  }

  /* ---------------------------------------- */
  /* - Métodos de validación del Formulario - */
  /* ---------------------------------------- */
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Determina si un control del formulario es válido.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  invalidControl(control: AbstractControl<any, any> | null): boolean {
    return !control?.valid;
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /* --------------------------------------- */
  /* - Métodos que modifican el Formulario - */
  /* --------------------------------------- */
  /**
   * Actualiza la obligatoriedad de los documentos
   * según el centro seleccionado.
   */
  onVehicleCenterChange(): void {
    if (this.selectedCenter === this.vehicleForm.get('center')?.value) {
      // No cambia el formulario
      console.log('Cambio de centro no altera el formulario');
    } else {
      // Actualizamos los documentos requeridos según el centro.
      this.selectedCenter = this.vehicleForm.get('center')?.value;
      console.log('Actualizamos los documentos requeridos según el centro');
      const vehicleDocuments = this.setInitialDocumentsFormGroup(
        this.selectedCenter
      );
      this.vehicleForm.removeControl('documents');
      this.vehicleForm.setControl('documents', vehicleDocuments);
    }
  }

  /**
   * Agrega el formulario de semirremolque para
   * vehículos que posean.
   */
  onVehicleTypeChange(): void {
    // En caso de que el vehículo sea de tipo
    // semirremolque, desplegamos el formulario
    // para semirremolques.
    if (this.vehicleForm.get('type')?.value === 'NOCORE_0000001') {
      this.onVehicleHasTrailer();
    } else {
      if (this.vehicleHasTrailer) {
        // Si antes se había seleccionado el valor NOCORE_0000001
        // la variable vehicleHasTrailer será verdadera
        // Removemos el control de formulario
        // asociado al semirremolque
        this.onVehicleHasNotTrailer();
      }
    }
  }

  /**
   * Ejecuta un flujo para agregar o remover el Re-certificado de fabricación
   * a la lista de documentos dependiendo de la edad del vehículo.
   */
  onVehicleYearChange(): void {
    // En caso de que el vehículo haya sido
    // fabricado hace 7 años o más, el
    // Re-certificado de Fabricación será solicitado.
    if (
      this.currentYear - this.vehicleForm.get('year')?.value >=
      appConstants.vehicle.ageToBeConsideredOld
    ) {
      this.onVehicleIsOld();
    } else {
      if (this.vehicleIsOld) {
        // Si antes se había seleccionado un año que hacía
        // que la edad del vehículo fuese >= appConstants.vehicle.ageToBeConsideredOld
        // la variable vehicleIsOld será verdadera
        // Removemos el control de formulario
        // asociado al Re-certificado de Fabricación
        this.onVehicleIsYoung();
      }
    }
  }

  /**
   * Agregar el Re-certificado de fabricación a la lista de documentos
   * y define la variable vehicleIsOld = True.
   * @private
   */
  private onVehicleIsOld(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    if (!this.vehicleIsOld) {
      // Agregamos el Re-certificado de Fabricación
      const index = this.master['VEHICLES#DOCUMENT_TYPES'].findIndex(
        (document: any) => {
          return (
            document.valueId ===
            `${business}_${appConstants.document.codes.recertification_of_manufacturing}`
          );
        }
      );
      const document = this.master['VEHICLES#DOCUMENT_TYPES'][index];

      (<FormArray>this.vehicleForm.get('documents')).push(
        this.formsService.createDocumentControlFromMaster(document)
      );
      this.vehicleIsOld = true;
    }
  }

  /**
   * Remueve el Re-certificado de fabricación a la lista de documentos
   * y define la variable vehicleIsOld = False.
   * @private
   */
  private onVehicleIsYoung(): void {
    const business = this.usersService.business.value.toUpperCase();
    let documentFormArray = <FormArray>this.vehicleForm.get('documents');
    const index = (<Array<any>>documentFormArray.value).findIndex(
      (document: any) => {
        return (
          document.documentMasterValueId ===
          `${business}_${appConstants.document.codes.recertification_of_manufacturing}`
        );
      }
    );
    documentFormArray.removeAt(index);
    this.vehicleForm.setControl('documents', documentFormArray);
    this.vehicleIsOld = false;
  }

  /**
   * Agrega el Permiso de Circulación del semirremolque a la lista de documentos
   * y define la variable vehicleHasTrailer = True.
   * @private
   */
  private onVehicleHasTrailer(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    if (!this.vehicleHasTrailer) {
      // Agregamos el control de la patente del semirremolque
      this.vehicleForm.addControl(
        'trailerLicensePlate',
        new FormControl(null, [
          Validators.required,
          Validators.pattern(appConstants.regex.licensePlate),
        ])
      );
      // Agregamos el Permiso de circulación del Semirremolque
      const index = this.master['VEHICLES#DOCUMENT_TYPES'].findIndex(
        (document: any) => {
          return (
            document.valueId === `${business}_${this.circulation_permit_code}`
          );
        }
      );
      const document = this.master['VEHICLES#DOCUMENT_TYPES'][index];

      (<FormArray>this.vehicleForm.get('documents')).push(
        this.formsService.createDocumentControlFromMaster(document)
      );
      this.vehicleHasTrailer = true;
    }
  }

  /**
   * Remueve el Permiso de Circulación del semirremolque a la lista de documentos
   * y define la variable vehicleHasTrailer = False.
   * @private
   */
  private onVehicleHasNotTrailer(): void {
    const business = this.usersService.business.value.toUpperCase();
    let documentFormArray = <FormArray>this.vehicleForm.get('documents');
    const index = (<Array<any>>documentFormArray.value).findIndex(
      (document: any) => {
        return (
          document.documentMasterValueId ===
          `${business}_${this.circulation_permit_code}`
        );
      }
    );
    documentFormArray.removeAt(index);
    this.vehicleForm.setControl('documents', documentFormArray);
    // Removemos el control de la patente del semirremolque
    this.vehicleForm.removeControl('trailerLicensePlate');
    this.vehicleHasTrailer = false;
  }

  /**
   * Asigna el archivo al control documentSourceFile
   * del documento.
   * @param {Event} event Evento input.
   * @param {number} index Índice del documento en el arreglo documents.
   */
  onChangeDocumentFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'documentSourceFile'
    const target = event.target as HTMLInputElement;
    console.log(' --- onChangeDocumentFile ---');
    console.log(target);
    console.log(target.files![0]);
    if (target.files!.length > 0) {
      const file = target.files![0];
      (<FormArray>this.vehicleForm.get('documents'))
        .at(index)
        .patchValue({ documentSourceFile: file });
    }
  }

  /**
   * Retorna el ID del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} ID del transportista.
   */
  carrierValueId(): string {
    return this.usersService.getActiveUser().company;
  }

  /**
   * Retorna el nombre del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} Nombre del transportista.
   */
  carrierValueToDisplay(): string {
    const company = this.usersService.getActiveUser().company;
    return this.masterDisplayMap['CARRIERS'][company];
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * requiere fechas de emisión y vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * es obligatorio para un centro.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isMandatoryDocument(masterValueId: string): boolean {
    const center = this.vehicleForm.get('center')?.value;
    if (this.documentsSpecs[center + '#' + masterValueId]) {
      return this.documentsSpecs[center + '#' + masterValueId];
    } else {
      return false;
    }
  }

  /**
   * Determina si se debe deshabilitar el input
   * para la patente.
   */
  private disableLicensePlate(): boolean {
    return (
      this.editMode ||
      (this.archiveMode && !this.archivesService.hasToChangeID())
    );
  }

  /* ------------------------------- */
  /* - Métodos asociados a botones - */
  /* ------------------------------- */
  /**
   * Crea o edita un vehículo.
   */
  async onSubmit(): Promise<void> {
    console.log(' onSubmit @ vehicle-edit.component ');
    console.log(this.vehicleForm.value);
    const newVehicle = this.vehicleForm.value;
    const documentList: DocumentFromForms[] =
      this.vehicleForm.value.documents.slice();
    const business: string = this.usersService.business.value.toUpperCase();

    if (this.disableLicensePlate()) {
      // Si la patente fue deshabilitada se asigna acá.
      newVehicle['licensePlate'] = this.selectedVehicle!.licensePlate;
    }

    console.log('newVehicle', newVehicle);
    console.log('documentList', documentList);

    const uploadDateYYYYMMDD = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    const uploadDateTimestamp = Math.floor(Date.now() / 1000).toString();

    if (this.editMode) {
      // Chequeamos si al menos un documento ha sido editado.
      // De lo contrario no se actualiza el vehículo.
      let vehicleEdited: boolean = false;
      documentList.forEach((document: any) => {
        vehicleEdited = vehicleEdited || Boolean(document.documentSourceFile);
        console.log(vehicleEdited);
      });

      if (!vehicleEdited) {
        this.feedbacksService.showFeedback(
          'Debe adjuntar al menos un documento para editar el vehículo',
          'danger'
        );
        return;
      }

      // Nota: En el modo edición los ID fueron fijados al momento de iniciar el formulario.
      this.feedbacksService.showFeedback(
        'Actualizando vehículo: ' + this.vehicleId,
        'info'
      );

      await this.notifyEdition(uploadDateTimestamp);

      // En modo edición, solo pueden editarse los documentos
      // Sin embargo, el vehículo vuelve a quedar en estado de "Por Aprobar"
      // 1. Se cambia en estatus del vehículo
      const updateVehicleInput: UpdateVehicleInput = {
        vehicleId: this.vehicleId,
        status: `${business}_${appConstants.entity.codes.toBeApproved}`,
        updatedBy: this.usersService.getActiveUser().userId,
      };

      await this.api
        .UpdateVehicle(updateVehicleInput)
        .then(() => {
          // 2. Se cargan los documentos del vehículo, si aplica.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'VEHICLE',
            this.vehicleId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar vehículo ${this.vehicleId}`
          );
        });
    } else {
      // Si es un flujo de archivado,
      // Se elimina primero la entidad anterior
      // para evitar conflictos si es la misma patente.
      if (this.archiveMode) {
        // 1. Eliminar entidad anterior
        // (en este punto sigue como la que está seleccionada)
        await this.vehiclesService.deleteSelectedVehicle(false);

        // 2. Liberamos el modo de archivado
        this.archivesService.setArchiving(false);
      }

      // En el modo crear los ID se toman desde el formulario.
      this.vehicleId = newVehicle.licensePlate.toUpperCase();

      this.feedbacksService.showFeedback(
        `Creando vehículo: ${this.vehicleId}`,
        'info'
      );

      // En modo creación o archivo:
      // 1. Se crea un nuevo vehículo.
      const createVehicleInput: CreateVehicleInput = {
        vehicleId: this.vehicleId,
        brand: newVehicle.brand,
        licensePlate: this.vehicleId, // La licencia corresponde con el ID
        color: newVehicle.color,
        model: newVehicle.model.toUpperCase(),
        year: newVehicle.year,
        type: newVehicle.type,
        capacity: newVehicle.capacity,
        business: business,
        center: newVehicle.center,
        originalCenter: newVehicle.center,
        isSpot: newVehicle.isSpot === 'true',
        blockingMotives: [],
        imei: newVehicle.imei,
        cameraImei: newVehicle.cameraImei,
        status: `${business}_${appConstants.entity.codes.toBeApproved}`, // Un nuevo vehículo debe ser aprobado
        company: newVehicle.carrier,
        carrierVehiclesCarrierId: newVehicle.carrier,
        trailerLicensePlate: newVehicle.trailerLicensePlate
          ? newVehicle.trailerLicensePlate.toUpperCase()
          : null,
        updatedBy: this.usersService.getActiveUser().userId,
        ...(this.archiveMode && {
          archiveId: this.archivesService.getArchiveIdForEntity(),
        }),
      };
      console.log('createVehicleInput', createVehicleInput);

      this.api
        .CreateVehicle(createVehicleInput)
        .then((vehicle: CreateVehicleMutation) => {
          this.feedbacksService.showFeedback(
            `Vehículo ${vehicle.vehicleId} creado correctamente`,
            'success'
          );

          // 2. Se cargan los documentos del vehículo.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'VEHICLE',
            this.vehicleId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp,
            this.archiveMode
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al crear vehículo ${this.vehicleId}`
          );
        });
    }

    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /**
   * Notifica la edición de una cisterna, si aplica.
   * @param {string} uploadDateTimestamp Timestamp de edición.
   */
  private async notifyEdition(uploadDateTimestamp: string): Promise<void> {
    // Si el vehículo editado viene de un estado "Disponible", "Rechazado" o "Bloqueado"
    // se notifica que fue editado
    const selectedVehicle: Vehicle = this.selectedVehicle!;
    if (
      selectedVehicle.status.endsWith(appConstants.entity.codes.rejected) ||
      selectedVehicle.status.endsWith(appConstants.entity.codes.blocked) ||
      selectedVehicle.status.endsWith(appConstants.entity.codes.available)
    ) {
      let initialStatus: string;
      let messageInitialStatus: string;
      if (selectedVehicle.status.endsWith(appConstants.entity.codes.rejected)) {
        initialStatus = 'FROMREJECTED';
        messageInitialStatus = 'rechazado';
      } else if (
        selectedVehicle.status.endsWith(appConstants.entity.codes.available)
      ) {
        initialStatus = 'FROMAVAILABLE';
        messageInitialStatus = 'disponible';
      } else {
        initialStatus = 'FROMBLOCKED';
        messageInitialStatus = 'bloqueado';
      }
      const creatNotificationInput: CreateNotificationInput = {
        businessId: selectedVehicle.business,
        notificationId:
          'VEHICLE#' + selectedVehicle.vehicleId + '#' + uploadDateTimestamp,
        status: 'TO_BE_SENT',
        model: 'VEHICLE',
        motive: `UPDATE#${initialStatus}#TOTOBEAPPROVED`,
        vehicleId: selectedVehicle.vehicleId,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async () => {
          let message: string =
            'Se ha enviado una notificación por la actualización ';
          message += `del vehículo ${this.vehicleIdentification} previamente ${messageInitialStatus}.`;
          this.feedbacksService.showFeedback(message, 'info');
        });
    }
  }

  /**
   * Navega al detalle del vehículo.
   */
  onCancel(): void {
    // Si el usuario cancela, se termina el flujo de archivado.
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
    // Regresamos al detalle del vehículo.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate to details'));
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm() {
    console.log('-- Current Form --');
    console.log(this.vehicleForm);
  }

  async ngOnDestroy(): Promise<void> {
    if (this.editionId !== '') {
      await this.editionsService.releaseEdition(this.editionId);
    }
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
  }
}
