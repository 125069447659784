<div class="row align-items-end">
  <div class="col-sm-6 text-center" *ngIf="!isGettingDriver">
    <div>
      <small id="driver-dates" class="text-muted">
        Creado: {{ driver.createdAt | dateFormat | dateWithoutSeconds }}<br />
        Actualizado: {{ driver.updatedAt | dateFormat | dateWithoutSeconds }}
      </small>
    </div>
    <div class="btn-group divCenter">
      <button
        id="driver-details-approve"
        type="button"
        class="btn btn-outline-primary my-2"
        (click)="onApproveDriver()"
        *ngIf="driverNeedsApproval && showButton('approve')"
      >
        Aprobar
      </button>
      <button
        id="driver-details-reject"
        type="button"
        class="btn btn-outline-danger my-2"
        style="padding-left: 10px"
        (click)="onRejectDriver()"
        *ngIf="driverCanBeRejected && showButton('reject')"
      >
        Rechazar
      </button>
    </div>
  </div>
  <div class="col-sm-6 text-end">
    <div class="btn-group" style="padding-top: 60px">
      <div ngbDropdown class="d-inline-block">
        <button
          type="button"
          class="btn btn-outline-primary dropdownButton mx-2"
          id="driver-details-actions"
          *ngIf="!isGettingDriver && showButton('actions')"
          ngbDropdownToggle
        >
          Acciones
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownActions">
          <a
            id="driver-details-edit"
            *ngIf="!isGettingDriver && showButton('edit')"
            (click)="onEditDriver()"
            class="dropdown-item"
            ngbDropdownItem
          >
            Actualizar documentos</a
          >
          <a
            id="driver-details-block"
            *ngIf="!isGettingDriver && showButton('block')"
            (click)="onExcludeDriver()"
            class="dropdown-item"
            ngbDropdownItem
          >
            Bloquear</a
          >
          <a
            id="driver-details-unblock"
            *ngIf="!isGettingDriver && showButton('unlock')"
            (click)="onUnlockDriver()"
            class="dropdown-item"
            ngbDropdownItem
          >
            Desbloquear</a
          >
          <div class="dropdown-divider"></div>
          <a
            id="driver-details-archive"
            *ngIf="!isGettingDriver && showButton('archive')"
            (click)="onArchiveDriver()"
            ngbDropdownItem
          >
            Archivar</a
          >
          <a
            id="driver-details-delete"
            (click)="onDeleteDriver()"
            *ngIf="!isGettingDriver && showButton('delete')"
            ngbDropdownItem
          >
            Borrar</a
          >
        </div>
      </div>
      <button
        id="driver-details-refresh"
        class="btn btn-outline-secondary"
        *ngIf="!isGettingDriver"
        (click)="onRefreshDriver()"
      >
        <span>
          <img
            ngSrc="assets/images/icons/actualizar.svg"
            height="25"
            width="25"
            alt="Actualizar"
          />
        </span>
      </button>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-sm-6 text-center my-5" *ngIf="isGettingDriver">
    <app-spinner message="Cargando detalles del conductor..."></app-spinner>
  </div>
  <div
    class="col-sm-6 box-details-cab"
    [ngStyle]="{ border: '3px solid ' + getColor() }"
    *ngIf="!isGettingDriver"
  >
    <div class="div-box-sap-id">
      <h4 [ngStyle]="{ color: getColor(), 'font-weight': 'bold' }">
        SAP ID: {{ driver.sapId! | sapId }}
      </h4>
    </div>
    <div style="display: inline">
      <p><strong>Transportista</strong>: <br />{{ driver.carrier!.name }}</p>
      <p><strong>RUT</strong>: <br />{{ driver.rut }}</p>
      <div class="divCenter" style="text-align: center">
        <p *ngIf="showBlockingMotives">
          <strong
            >Bloqueado el
            {{
              driver.lastStatusInformedAt || driver.updatedAt
                | dateFormat
                | dateWithoutSeconds
            }}
            por</strong
          >:<br />
          <span
            class="badge border rounded-4 text-bg-light mx-1"
            *ngFor="let motive of driver.blockingMotives"
          >
            {{ motive }}
          </span>
        </p>
      </div>
    </div>

    <div class="divCenter">
      <h5>
        <span
          class="badge"
          [ngStyle]="{ 'background-color': getColor() }"
          style="width: 200px; border-radius: 60px"
        >
          <span>
            <img
              [src]="getImageSrc(driver.status)"
              height="25"
              width="25"
              alt="Estado"
            />
          </span>
          {{ driver.status | master : "DRIVERS#STATUSES" }}</span
        >
      </h5>
      <div *ngIf="hasComment">
        <label
          class="custom-file-upload pt-2 mx-1 float-end text-secondary"
          style="cursor: pointer"
        >
          <a id="entity-comment" title="Comentario" (click)="onViewComment()">
            <span class="material-symbols-outlined">comment</span>
          </a>
        </label>
      </div>
    </div>
    <div class="divCenter mb-2" *ngIf="hasComment && showComment">
      <div class="collapse comment" [ngClass]="showCommentClass()">
        <div class="card card-body">
          {{ driver.comment }}
        </div>
      </div>
    </div>

    <div class="divCenter">
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasToExpireDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            driver.documentsToExpire
          }}</span>
          por vencer</span
        >
      </h6>
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasExpiredDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            driver.documentsExpired
          }}</span>
          vencido</span
        >
      </h6>
    </div>

    <div ngbAccordion style="padding: 10px">
      <div ngbAccordionItem [collapsed]="true">
        <h2 ngbAccordionHeader>
          <button id="driver-details-accordion" ngbAccordionButton>
            Ver detalles
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="table-container">
            <ng-template>
              <table class="table table-hover">
                <caption>
                  Detalles del Conductor
                </caption>
                <tbody>
                  <tr>
                    <th scope="row">Centro</th>
                    <td>{{ driver.originalCenter! | master : "CENTERS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nombre</th>
                    <td>{{ driver.firstName }} {{ driver.lastName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Correo</th>
                    <td>{{ driver.email }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Teléfono</th>
                    <td>{{ driver.phone | phone }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Fecha de Nacimiento</th>
                    <td>{{ driver.birthDate }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Edad</th>
                    <td [ngClass]="driverAgeClass()">
                      {{ driverAge || 0 }} años
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Centro de Apoyo</th>
                    <td>
                      <div>
                        <!-- TMSNOCORE-275 pospuesta: se agrega un hidden-->
                        <label
                          *ngIf="showButton('edit-center')"
                          class="float-start me-1"
                          style="cursor: pointer"
                          hidden
                        >
                          <a
                            id="update-center"
                            title="Actualizar Centro de Apoyo"
                            (click)="onUpdateDriver()"
                          >
                            <span
                              class="material-symbols-outlined text-secondary"
                              >edit_square</span
                            >
                          </a>
                        </label>
                        {{ driver.center | master : "CENTERS" }}
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="driver.archiveId">
                    <th scope="row">Archivo</th>
                    <td>
                      <label
                        class="custom-file-upload pt-2 mx-1"
                        style="cursor: pointer"
                      >
                        <a
                          id="driver-archive-details"
                          title="Visualizar Detalle"
                          (click)="onViewArchiveDetails()"
                        >
                          <span class="material-symbols-outlined text-secondary"
                            >docs</span
                          >
                        </a>
                      </label>
                      <label
                        class="custom-file-upload pt-2 mx-1"
                        style="cursor: pointer"
                        *ngIf="hasDocumentOnArchive()"
                      >
                        <a
                          id="preview-driver-archive-docs"
                          title="Visualizar Documento"
                          (click)="onViewDocumentArchive()"
                        >
                          <span class="material-symbols-outlined text-secondary"
                            >preview</span
                          >
                        </a>
                      </label>
                      <label
                        class="custom-file-upload pt-2 mx-1"
                        style="cursor: pointer"
                        *ngIf="hasDocumentOnArchive()"
                      >
                        <a
                          id="download-archive-docs"
                          title="Descargar Documento"
                          (click)="onDownloadArchiveDocument()"
                        >
                          <span class="material-symbols-outlined text-secondary"
                            >download</span
                          >
                        </a>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <app-documents></app-documents>
  </div>
</div>

<!-- Modals -->
<ng-template #driverModal let-c="close" let-d="dismiss">
  <app-modal-with-comment
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [showCommentForm]="modalWithComment"
    [mandatoryComment]="isBeingRejected"
    [commentForm]="commentForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-with-comment>
</ng-template>
<ng-template #excludeModal let-c="close" let-d="dismiss">
  <app-modal-for-exclusions
    [modalTitle]="modalExcludeTitle"
    [modalQuestion]="modalExcludeQuestion"
    [modalMessage]="modalExcludeMessage"
    [exclusionForm]="exclusionForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-for-exclusions>
</ng-template>

<!-- Update Attribute Modal -->
<ng-template #updateDriverAttributeModal let-c="close" let-d="dismiss">
  <app-modal-change-attributes
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [attributeForm]="updateAttributeForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-change-attributes>
</ng-template>

<!-- Archive Modal -->
<ng-template #archiveDriverModal let-c="close" let-d="dismiss">
  <app-modal-for-archiving
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [archiveForm]="archiveForm"
    [isDriver]="true"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-for-archiving>
</ng-template>

<!--Archive Details Modal-->
<ng-template #driverArchiveDetailsModal let-c="close" let-d="dismiss">
  <app-modal-details
    [archive]="driverArchive?.entity"
    [updatedAt]="driverArchive?.updatedAt"
    [comment]="driverArchive?.comment"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  >
  </app-modal-details>
</ng-template>

<!--Document Viewer Modal-->
<ng-template #driverArchiveDocumentViewer let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
        <button
          id="document-item-modal-cross"
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="d('Cross')"
        ></button>
      </div>
      <div class="modal-body">
        <div class="text-center my-5" *ngIf="isGettingURL">
          <app-spinner message="Generando Vista Previa"></app-spinner>
        </div>
        <pdf-viewer
          [src]="pdfSource"
          [render-text]="true"
          [original-size]="false"
          [fit-to-page]="true"
          (page-rendered)="pageRendered()"
          style="width: 800px; height: 70vh"
        >
        </pdf-viewer>
      </div>
      <div class="modal-footer" *ngIf="!isGettingURL">
        <button
          id="document-item-modal-accept"
          type="button"
          class="btn btn-outline-primary"
          (click)="c('Accept')"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</ng-template>
