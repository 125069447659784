import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { appConstants } from '../../shared/constants/constants';

import {
  APIService,
  DeleteDriverInput,
  DeleteUserInput,
  Driver,
  DriversByBusinessAndDriverIdQuery,
  DriversByCenterAndDriverIdQuery,
  DriversByCompanyAndDriverIdQuery,
  GetDriverQuery,
  ModelDriverFilterInput,
  ModelSubscriptionDriverFilterInput,
  ModelSubscriptionIDInput,
  UpdateDriverInput,
  UpdateDriverMutation,
  User,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { nextToken } from '../../shared/types/next-token';

@Injectable({
  providedIn: 'root',
})
export class DriversService implements OnDestroy {
  driversChanged = new Subject<Driver[]>();
  selectedDriverChanged = new Subject<Driver>();

  private readonly initialDriver: Driver;
  private drivers: Driver[] = [];
  private driversFilterForSubscriptions: ModelSubscriptionDriverFilterInput =
    {};
  private selectedDriver: Driver;
  private selectedDriverFilterForSubscriptions: ModelDriverFilterInput = {};
  private businessSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.initialDriver = {
      ...appConstants.driver.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedDriver = this.initialDriver;
    this.setDriversFilter();
    this.setSelectedDriverFilterForSubscriptions();
    // El servicio de conductores inicia con el header, por
    // lo que debemos actualizar los filtros ante cambios de negocio.
    this.businessSubscription = this.usersService.business.subscribe(
      (): void => {
        this.setDriversFilter();
      }
    );
  }

  // --------------------------------------
  // Métodos para el conductor seleccionado
  // --------------------------------------
  /**
   * Configura el conductor seleccionado para referencia de
   * todos los componentes.
   * @param {Driver} driver Conductor seleccionado.
   */
  setSelectedDriver(driver: Driver): void {
    this.selectedDriver = driver;
    this.setSelectedDriverFilterForSubscriptions();
    this.selectedDriverChanged.next({ ...this.selectedDriver });
    console.log('selectedDriver', this.selectedDriver);

    // Documentos
    this.documentsService.setId('driverId', this.selectedDriver.driverId);
    this.documentsService.setSelectedModel('DRIVER');
    this.documentsService.setSelectedCenter(driver.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del conductor actualizados.'));
  }

  /**
   * Actualiza los datos del conductor seleccionado.
   * @return {Promise}
   */
  async refreshSelectedDriver(): Promise<void> {
    const getDriverResult: GetDriverQuery = await this.api.GetDriver(
      this.selectedDriver.driverId
    );
    this.setSelectedDriver(<Driver>getDriverResult);
  }

  /**
   * Retorna el conductor seleccionado.
   * @return {Driver}
   */
  getSelectedDriver(): Driver {
    return { ...this.selectedDriver };
  }

  // ------------------------------------
  // Métodos para la lista de Conductores
  // ------------------------------------
  /**
   * Configura la lista de conductores para referencia de
   * todos los componentes.
   * @param {Driver[]} drivers Lista de conductores.
   */
  setDrivers(drivers: Driver[]): void {
    this.drivers = drivers;
    this.setDriversFilter();
    this.driversChanged.next(this.drivers.slice());
  }

  /**
   * Actualiza la lista de conductores.
   * @return {Promise}
   */
  async refreshDrivers(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListDrivers: Driver[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListDrivers = await this.listDriversByCarrier(activeUser);
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListDrivers = await this.listDriversByBusiness(business);
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListDrivers = await this.listDriversByCenter(activeUser, business);
    } else if (activeUser.authGroup.endsWith('_DRIVERS')) {
      // Los conductores podrán acceder solo a su registro
      let tempDriver: GetDriverQuery = await this.api.GetDriver(
        activeUser.rut.replace(/[^0-9K]/g, '')
      );
      tempListDrivers.push(<Driver>tempDriver);
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo conductores. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setDrivers(tempListDrivers.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @private
   */
  private async listDriversByBusiness(business: string): Promise<Driver[]> {
    let listDriversResult: DriversByBusinessAndDriverIdQuery;
    listDriversResult = await this.api.DriversByBusinessAndDriverId(business);

    let tempListDrivers: Driver[] = <Driver[]>listDriversResult.items;
    let nextToken: nextToken = listDriversResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListDriversResult: DriversByBusinessAndDriverIdQuery =
        await this.api.DriversByBusinessAndDriverId(
          business,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListDrivers.push(...(<Driver[]>loopListDriversResult.items));
      nextToken = loopListDriversResult.nextToken;
    }
    return tempListDrivers.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @private
   */
  private async listDriversByCenter(
    activeUser: User,
    business: string
  ): Promise<Driver[]> {
    let tempListDrivers: Driver[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const driversPromises: Promise<Driver[]>[] = centers.map(
      async (center: string): Promise<Driver[]> => {
        let listDriversResult: DriversByCenterAndDriverIdQuery;

        listDriversResult = await this.api.DriversByCenterAndDriverId(center);
        let drivers: Driver[] = <Driver[]>listDriversResult.items;

        let nextToken: nextToken = listDriversResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListDriversResult: DriversByCenterAndDriverIdQuery =
            await this.api.DriversByCenterAndDriverId(
              center,
              undefined,
              undefined,
              undefined,
              100,
              nextToken
            );
          drivers.push(...(<Driver[]>loopListDriversResult.items));
          nextToken = loopListDriversResult.nextToken;
        }

        return drivers;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Driver[][] = await Promise.all(driversPromises);

    // Combinar todos los resultados en una sola lista
    results.forEach((drivers: Driver[]): void => {
      tempListDrivers.push(...drivers);
    });

    return tempListDrivers.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @private
   */
  private async listDriversByCarrier(activeUser: User): Promise<Driver[]> {
    let listDriversResult: DriversByCompanyAndDriverIdQuery;
    listDriversResult = await this.api.DriversByCompanyAndDriverId(
      activeUser.company
    );

    let tempListDrivers: Driver[] = <Driver[]>listDriversResult.items;
    let nextToken: nextToken = listDriversResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListDriversResult: DriversByCompanyAndDriverIdQuery =
        await this.api.DriversByCompanyAndDriverId(
          activeUser.company,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListDrivers.push(...(<Driver[]>loopListDriversResult.items));
      nextToken = loopListDriversResult.nextToken;
    }
    return tempListDrivers.slice();
  }

  /**
   * Retorna la lista de conductores.
   * @return {Driver[]}
   */
  getDrivers(): Driver[] {
    return this.drivers.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de conductores y subscripciones.
   * @private
   */
  private setDriversFilter(): void {
    this.driversFilterForSubscriptions =
      this.usersService.getEntitiesFilter(true);
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de conductores.
   * @return {ModelSubscriptionDriverFilterInput}
   */
  getDriversFilterForSubscriptions(): ModelSubscriptionDriverFilterInput {
    return { ...this.driversFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del conductor seleccionado.
   */
  private setSelectedDriverFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedDriver.driverId,
    };
    this.selectedDriverFilterForSubscriptions = {
      driverId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del conductor seleccionado.
   * @return {ModelSubscriptionDriverFilterInput}
   */
  getSelectedDriverFilterForSubscriptions(): ModelSubscriptionDriverFilterInput {
    return { ...this.selectedDriverFilterForSubscriptions };
  }

  // ------------
  // Métodos CRUD
  // ------------
  /**
   * Elimina un conductor y el usuario asociado.
   * @param {Driver} driver Conductores.
   * @param deleteDocuments {boolean}: define si se le pedirá al backend
   * que elimine los documentos de la entidad.
   */
  async deleteDriver(driver: Driver, deleteDocuments: boolean = true) {
    // Actualizamos para dejar constancia
    // del usuario que pidió la eliminación.
    const updateDriverInput: UpdateDriverInput = {
      driverId: driver.driverId,
      updatedBy: this.usersService.getActiveUser().userId,
      comment: `deleteDocuments: ${deleteDocuments}`,
    };

    await this.api
      .UpdateDriver(updateDriverInput)
      .then(async (driver: UpdateDriverMutation): Promise<void> => {
        const deleteDriverInput: DeleteDriverInput = {
          driverId: driver.driverId,
        };
        await this.api
          .DeleteDriver(deleteDriverInput)
          .then(async () => {
            this.feedbacksService.showFeedback(
              `Conductor ${driver.sapId!} eliminado.`,
              'success'
            );

            // 2. Se elimina el usuario de Cognito.
            this.feedbacksService.showFeedback(
              `Eliminando usuario ${driver.email}.`,
              'info'
            );
            const deleteUserInput: DeleteUserInput = {
              userId: driver.email,
            };

            await this.api
              .DeleteUser(deleteUserInput)
              .then(() => {
                this.feedbacksService.showFeedback(
                  `Usuario ${driver.email} eliminado.`,
                  'success'
                );
              })
              .catch((response: any): void => {
                this.feedbacksService.showErrorFeedbacks(
                  response,
                  `Error al eliminar usuario ${driver.email}`
                );
              });
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al borrar conductor ${driver.sapId}`
            );
          });
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al actualizar conductor ${driver.sapId}`
        );
      });
  }

  ngOnDestroy(): void {
    this.businessSubscription.unsubscribe();
    console.log('driver.service subscriptions removed.');
  }
}
