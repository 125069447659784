import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';

import { DocumentsModule } from '../documents/documents.module';
import { MasterModule } from '../master/master.module';
import { SharedModule } from '../../shared/shared.module';

import { TanqueDetailsComponent } from './tanque-details/tanque-details.component';
import { TanqueEditComponent } from './tanque-edit/tanque-edit.component';
import { TanqueNoSelectedComponent } from './tanque-no-selected/tanque-no-selected.component';
import { TanquesComponent } from './tanques.component';
import { TanquesListComponent } from './tanques-list/tanques-list.component';
import { TanquesRoutingModule } from './tanques-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    TanquesComponent,
    TanquesListComponent,
    TanqueDetailsComponent,
    TanqueNoSelectedComponent,
    TanqueEditComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DataTablesModule,
    DocumentsModule,
    MasterModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TanquesRoutingModule,
    NgOptimizedImage,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    PdfViewerModule,
  ],
})
export class TanquesModule {}
