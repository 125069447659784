/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from '@angular/core';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { Observable } from 'zen-observable-ts';

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateCarrier: OnCreateCarrierSubscription;
  onUpdateCarrier: OnUpdateCarrierSubscription;
  onDeleteCarrier: OnDeleteCarrierSubscription;
  onCreateVehicle: OnCreateVehicleSubscription;
  onUpdateVehicle: OnUpdateVehicleSubscription;
  onDeleteVehicle: OnDeleteVehicleSubscription;
  onCreateEnvasado: OnCreateEnvasadoSubscription;
  onUpdateEnvasado: OnUpdateEnvasadoSubscription;
  onDeleteEnvasado: OnDeleteEnvasadoSubscription;
  onCreateCisterna: OnCreateCisternaSubscription;
  onUpdateCisterna: OnUpdateCisternaSubscription;
  onDeleteCisterna: OnDeleteCisternaSubscription;
  onCreateTracto: OnCreateTractoSubscription;
  onUpdateTracto: OnUpdateTractoSubscription;
  onDeleteTracto: OnDeleteTractoSubscription;
  onCreateTanque: OnCreateTanqueSubscription;
  onUpdateTanque: OnUpdateTanqueSubscription;
  onDeleteTanque: OnDeleteTanqueSubscription;
  onCreateSemirremolque: OnCreateSemirremolqueSubscription;
  onUpdateSemirremolque: OnUpdateSemirremolqueSubscription;
  onDeleteSemirremolque: OnDeleteSemirremolqueSubscription;
  onCreateCouple: OnCreateCoupleSubscription;
  onUpdateCouple: OnUpdateCoupleSubscription;
  onDeleteCouple: OnDeleteCoupleSubscription;
  onCreateDriver: OnCreateDriverSubscription;
  onUpdateDriver: OnUpdateDriverSubscription;
  onDeleteDriver: OnDeleteDriverSubscription;
  onCreateDocument: OnCreateDocumentSubscription;
  onUpdateDocument: OnUpdateDocumentSubscription;
  onDeleteDocument: OnDeleteDocumentSubscription;
  onCreateMaster: OnCreateMasterSubscription;
  onUpdateMaster: OnUpdateMasterSubscription;
  onDeleteMaster: OnDeleteMasterSubscription;
  onCreateDocumentSpecs: OnCreateDocumentSpecsSubscription;
  onUpdateDocumentSpecs: OnUpdateDocumentSpecsSubscription;
  onDeleteDocumentSpecs: OnDeleteDocumentSpecsSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateNotification: OnCreateNotificationSubscription;
  onUpdateNotification: OnUpdateNotificationSubscription;
  onDeleteNotification: OnDeleteNotificationSubscription;
  onCreateMail: OnCreateMailSubscription;
  onUpdateMail: OnUpdateMailSubscription;
  onDeleteMail: OnDeleteMailSubscription;
  onCreateSpeeding: OnCreateSpeedingSubscription;
  onUpdateSpeeding: OnUpdateSpeedingSubscription;
  onDeleteSpeeding: OnDeleteSpeedingSubscription;
  onCreateFormQuestionTemplate: OnCreateFormQuestionTemplateSubscription;
  onUpdateFormQuestionTemplate: OnUpdateFormQuestionTemplateSubscription;
  onDeleteFormQuestionTemplate: OnDeleteFormQuestionTemplateSubscription;
  onCreateRozForm: OnCreateRozFormSubscription;
  onUpdateRozForm: OnUpdateRozFormSubscription;
  onDeleteRozForm: OnDeleteRozFormSubscription;
  onCreateEvaluatedRozForm: OnCreateEvaluatedRozFormSubscription;
  onUpdateEvaluatedRozForm: OnUpdateEvaluatedRozFormSubscription;
  onDeleteEvaluatedRozForm: OnDeleteEvaluatedRozFormSubscription;
  onCreateFormQuestion: OnCreateFormQuestionSubscription;
  onUpdateFormQuestion: OnUpdateFormQuestionSubscription;
  onDeleteFormQuestion: OnDeleteFormQuestionSubscription;
  onCreateEdition: OnCreateEditionSubscription;
  onUpdateEdition: OnUpdateEditionSubscription;
  onDeleteEdition: OnDeleteEditionSubscription;
  onCreateMailPreference: OnCreateMailPreferenceSubscription;
  onUpdateMailPreference: OnUpdateMailPreferenceSubscription;
  onDeleteMailPreference: OnDeleteMailPreferenceSubscription;
  onCreateDriverExclusion: OnCreateDriverExclusionSubscription;
  onUpdateDriverExclusion: OnUpdateDriverExclusionSubscription;
  onDeleteDriverExclusion: OnDeleteDriverExclusionSubscription;
  onCreateStatus: OnCreateStatusSubscription;
  onUpdateStatus: OnUpdateStatusSubscription;
  onDeleteStatus: OnDeleteStatusSubscription;
  onCreateArchive: OnCreateArchiveSubscription;
  onUpdateArchive: OnUpdateArchiveSubscription;
  onDeleteArchive: OnDeleteArchiveSubscription;
};

export type CreateCarrierInput = {
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  owner?: string | null;
};

export type ModelCarrierConditionInput = {
  name?: ModelStringInput | null;
  rut?: ModelStringInput | null;
  email?: ModelStringInput | null;
  additionalEmails?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelCarrierConditionInput | null> | null;
  or?: Array<ModelCarrierConditionInput | null> | null;
  not?: ModelCarrierConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type Carrier = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: ModelVehicleConnection | null;
  envasados?: ModelEnvasadoConnection | null;
  cisternas?: ModelCisternaConnection | null;
  tractos?: ModelTractoConnection | null;
  tanques?: ModelTanqueConnection | null;
  semirremolques?: ModelSemirremolqueConnection | null;
  drivers?: ModelDriverConnection | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelVehicleConnection = {
  __typename: 'ModelVehicleConnection';
  items: Array<Vehicle | null>;
  nextToken?: string | null;
};

export type Vehicle = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: Carrier;
  documents?: ModelDocumentConnection | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type ModelDocumentConnection = {
  __typename: 'ModelDocumentConnection';
  items: Array<Document | null>;
  nextToken?: string | null;
};

export type Document = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: Vehicle | null;
  envasado?: Envasado | null;
  cisterna?: Cisterna | null;
  tracto?: Tracto | null;
  tanque?: Tanque | null;
  semirremolque?: Semirremolque | null;
  driver?: Driver | null;
  archive?: Archive | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type Envasado = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: ModelDocumentConnection | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: Carrier;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type Cisterna = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: ModelDocumentConnection | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: Carrier;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type Tracto = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: ModelDocumentConnection | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: Carrier;
  couple?: Couple | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type Couple = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: Tracto;
  tanque?: Tanque | null;
  semirremolque?: Semirremolque | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type Tanque = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: ModelDocumentConnection | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: Carrier;
  couple?: Couple | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type Semirremolque = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: ModelDocumentConnection | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: Carrier;
  couple?: Couple | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type Driver = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: Carrier;
  documents?: ModelDocumentConnection | null;
  speedings?: ModelSpeedingConnection | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type ModelSpeedingConnection = {
  __typename: 'ModelSpeedingConnection';
  items: Array<Speeding | null>;
  nextToken?: string | null;
};

export type Speeding = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type Archive = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: ModelDocumentConnection | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelEnvasadoConnection = {
  __typename: 'ModelEnvasadoConnection';
  items: Array<Envasado | null>;
  nextToken?: string | null;
};

export type ModelCisternaConnection = {
  __typename: 'ModelCisternaConnection';
  items: Array<Cisterna | null>;
  nextToken?: string | null;
};

export type ModelTractoConnection = {
  __typename: 'ModelTractoConnection';
  items: Array<Tracto | null>;
  nextToken?: string | null;
};

export type ModelTanqueConnection = {
  __typename: 'ModelTanqueConnection';
  items: Array<Tanque | null>;
  nextToken?: string | null;
};

export type ModelSemirremolqueConnection = {
  __typename: 'ModelSemirremolqueConnection';
  items: Array<Semirremolque | null>;
  nextToken?: string | null;
};

export type ModelDriverConnection = {
  __typename: 'ModelDriverConnection';
  items: Array<Driver | null>;
  nextToken?: string | null;
};

export type UpdateCarrierInput = {
  carrierId: string;
  name?: string | null;
  rut?: string | null;
  email?: string | null;
  additionalEmails?: Array<string | null> | null;
  owner?: string | null;
};

export type DeleteCarrierInput = {
  carrierId: string;
};

export type CreateVehicleInput = {
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierVehiclesCarrierId?: string | null;
};

export type ModelVehicleConditionInput = {
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  color?: ModelStringInput | null;
  model?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  imei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  isSpot?: ModelBooleanInput | null;
  sapId?: ModelStringInput | null;
  trailerLicensePlate?: ModelStringInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelVehicleConditionInput | null> | null;
  or?: Array<ModelVehicleConditionInput | null> | null;
  not?: ModelVehicleConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  carrierVehiclesCarrierId?: ModelIDInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateVehicleInput = {
  vehicleId: string;
  brand?: string | null;
  licensePlate?: string | null;
  color?: string | null;
  model?: string | null;
  year?: number | null;
  type?: string | null;
  capacity?: string | null;
  business?: string | null;
  center?: string | null;
  originalCenter?: string | null;
  comment?: string | null;
  imei?: string | null;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status?: string | null;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierVehiclesCarrierId?: string | null;
};

export type DeleteVehicleInput = {
  vehicleId: string;
};

export type CreateEnvasadoInput = {
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierEnvasadosCarrierId?: string | null;
};

export type ModelEnvasadoConditionInput = {
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  engineType?: ModelStringInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  bodyType?: ModelStringInput | null;
  maximumPalletCapacity?: ModelIntInput | null;
  height?: ModelFloatInput | null;
  width?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelEnvasadoConditionInput | null> | null;
  or?: Array<ModelEnvasadoConditionInput | null> | null;
  not?: ModelEnvasadoConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  carrierEnvasadosCarrierId?: ModelIDInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateEnvasadoInput = {
  envasadoId: string;
  sapId?: string | null;
  brand?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  status?: string | null;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color?: string | null;
  year?: number | null;
  type?: string | null;
  capacity?: string | null;
  business?: string | null;
  center?: string | null;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei?: string | null;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierEnvasadosCarrierId?: string | null;
};

export type DeleteEnvasadoInput = {
  envasadoId: string;
};

export type CreateCisternaInput = {
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierCisternasCarrierId?: string | null;
};

export type ModelCisternaConditionInput = {
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  engineType?: ModelStringInput | null;
  bulkheadType?: ModelStringInput | null;
  hasPump?: ModelBooleanInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelCisternaConditionInput | null> | null;
  or?: Array<ModelCisternaConditionInput | null> | null;
  not?: ModelCisternaConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  carrierCisternasCarrierId?: ModelIDInput | null;
};

export type UpdateCisternaInput = {
  cisternaId: string;
  sapId?: string | null;
  brand?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  status?: string | null;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color?: string | null;
  year?: number | null;
  type?: string | null;
  capacity?: string | null;
  business?: string | null;
  subBusiness?: string | null;
  center?: string | null;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei?: string | null;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierCisternasCarrierId?: string | null;
};

export type DeleteCisternaInput = {
  cisternaId: string;
};

export type CreateTractoInput = {
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type ModelTractoConditionInput = {
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  engineType?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelTractoConditionInput | null> | null;
  or?: Array<ModelTractoConditionInput | null> | null;
  not?: ModelTractoConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  carrierTractosCarrierId?: ModelIDInput | null;
  tractoCoupleCoupleId?: ModelIDInput | null;
};

export type UpdateTractoInput = {
  tractoId: string;
  sapId?: string | null;
  brand?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  status?: string | null;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color?: string | null;
  year?: number | null;
  type?: string | null;
  capacity?: string | null;
  business?: string | null;
  center?: string | null;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei?: string | null;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type DeleteTractoInput = {
  tractoId: string;
};

export type CreateTanqueInput = {
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type ModelTanqueConditionInput = {
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  height?: ModelFloatInput | null;
  width?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  bulkheadType?: ModelStringInput | null;
  hasPump?: ModelBooleanInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelTanqueConditionInput | null> | null;
  or?: Array<ModelTanqueConditionInput | null> | null;
  not?: ModelTanqueConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  carrierTanquesCarrierId?: ModelIDInput | null;
  tanqueCoupleCoupleId?: ModelIDInput | null;
};

export type UpdateTanqueInput = {
  tanqueId: string;
  sapId?: string | null;
  brand?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  status?: string | null;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color?: string | null;
  year?: number | null;
  type?: string | null;
  capacity?: string | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business?: string | null;
  subBusiness?: string | null;
  center?: string | null;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei?: string | null;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type DeleteTanqueInput = {
  tanqueId: string;
};

export type CreateSemirremolqueInput = {
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type ModelSemirremolqueConditionInput = {
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  maximumPalletCapacity?: ModelIntInput | null;
  height?: ModelFloatInput | null;
  width?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  rampType?: ModelStringInput | null;
  MOPCode?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelSemirremolqueConditionInput | null> | null;
  or?: Array<ModelSemirremolqueConditionInput | null> | null;
  not?: ModelSemirremolqueConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  carrierSemirremolquesCarrierId?: ModelIDInput | null;
  semirremolqueCoupleCoupleId?: ModelIDInput | null;
};

export type UpdateSemirremolqueInput = {
  semirremolqueId: string;
  sapId?: string | null;
  brand?: string | null;
  licensePlate?: string | null;
  model?: string | null;
  status?: string | null;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color?: string | null;
  year?: number | null;
  type?: string | null;
  capacity?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business?: string | null;
  subBusiness?: string | null;
  center?: string | null;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei?: string | null;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type DeleteSemirremolqueInput = {
  semirremolqueId: string;
};

export type CreateCoupleInput = {
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  owner?: string | null;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type ModelCoupleConditionInput = {
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  company?: ModelStringInput | null;
  status?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelCoupleConditionInput | null> | null;
  or?: Array<ModelCoupleConditionInput | null> | null;
  not?: ModelCoupleConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  coupleTractoTractoId?: ModelIDInput | null;
  coupleTanqueTanqueId?: ModelIDInput | null;
  coupleSemirremolqueSemirremolqueId?: ModelIDInput | null;
};

export type UpdateCoupleInput = {
  coupleId: string;
  business?: string | null;
  center?: string | null;
  comment?: string | null;
  company?: string | null;
  status?: string | null;
  owner?: string | null;
  coupleTractoTractoId?: string | null;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type DeleteCoupleInput = {
  coupleId: string;
};

export type CreateDriverInput = {
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierDriversCarrierId?: string | null;
};

export type ModelDriverConditionInput = {
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  rut?: ModelStringInput | null;
  birthDate?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelDriverConditionInput | null> | null;
  or?: Array<ModelDriverConditionInput | null> | null;
  not?: ModelDriverConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  carrierDriversCarrierId?: ModelIDInput | null;
};

export type UpdateDriverInput = {
  driverId: string;
  firstName?: string | null;
  lastName?: string | null;
  rut?: string | null;
  birthDate?: string | null;
  phone?: string | null;
  email?: string | null;
  business?: string | null;
  center?: string | null;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status?: string | null;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  carrierDriversCarrierId?: string | null;
};

export type DeleteDriverInput = {
  driverId: string;
};

export type CreateDocumentInput = {
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  owner?: string | null;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type ModelDocumentConditionInput = {
  masterValueId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  status?: ModelStringInput | null;
  s3Path?: ModelStringInput | null;
  issueDate?: ModelStringInput | null;
  expirationDate?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelDocumentConditionInput | null> | null;
  or?: Array<ModelDocumentConditionInput | null> | null;
  not?: ModelDocumentConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  vehicleDocumentsVehicleId?: ModelIDInput | null;
  envasadoDocumentsEnvasadoId?: ModelIDInput | null;
  cisternaDocumentsCisternaId?: ModelIDInput | null;
  tractoDocumentsTractoId?: ModelIDInput | null;
  tanqueDocumentsTanqueId?: ModelIDInput | null;
  semirremolqueDocumentsSemirremolqueId?: ModelIDInput | null;
  driverDocumentsDriverId?: ModelIDInput | null;
  archiveDocumentsArchiveId?: ModelIDInput | null;
  archiveDocumentsArchivedAt?: ModelIDInput | null;
};

export type UpdateDocumentInput = {
  documentId: string;
  masterValueId?: string | null;
  name?: string | null;
  status?: string | null;
  s3Path?: string | null;
  issueDate?: string | null;
  expirationDate?: string | null;
  owner?: string | null;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type DeleteDocumentInput = {
  documentId: string;
};

export type CreateMasterInput = {
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
};

export type ModelMasterConditionInput = {
  masterGroup?: ModelStringInput | null;
  valueId?: ModelStringInput | null;
  valueToDisplay?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelMasterConditionInput | null> | null;
  or?: Array<ModelMasterConditionInput | null> | null;
  not?: ModelMasterConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type Master = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMasterInput = {
  businessId: string;
  masterId: string;
  masterGroup?: string | null;
  valueId?: string | null;
  valueToDisplay?: string | null;
  sapId?: string | null;
  owner?: string | null;
};

export type DeleteMasterInput = {
  businessId: string;
  masterId: string;
};

export type CreateDocumentSpecsInput = {
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
};

export type ModelDocumentSpecsConditionInput = {
  isMandatory?: ModelBooleanInput | null;
  canBlock?: ModelBooleanInput | null;
  extensionDays?: ModelIntInput | null;
  updatedBy?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelDocumentSpecsConditionInput | null> | null;
  or?: Array<ModelDocumentSpecsConditionInput | null> | null;
  not?: ModelDocumentSpecsConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type DocumentSpecs = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDocumentSpecsInput = {
  businessId: string;
  documentSpecsId: string;
  isMandatory?: boolean | null;
  canBlock?: boolean | null;
  extensionDays?: number | null;
  updatedBy?: string | null;
  owner?: string | null;
};

export type DeleteDocumentSpecsInput = {
  businessId: string;
  documentSpecsId: string;
};

export type CreateUserInput = {
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
};

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  rut?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  business?: ModelStringInput | null;
  hasAccessTo?: ModelStringInput | null;
  authGroup?: ModelStringInput | null;
  status?: ModelStringInput | null;
  company?: ModelStringInput | null;
  centers?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type User = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserInput = {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  rut?: string | null;
  phone?: string | null;
  email?: string | null;
  business?: string | null;
  hasAccessTo?: string | null;
  authGroup?: string | null;
  status?: string | null;
  company?: string | null;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
};

export type DeleteUserInput = {
  userId: string;
};

export type CreateNotificationInput = {
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
};

export type ModelNotificationConditionInput = {
  status?: ModelStringInput | null;
  retries?: ModelIntInput | null;
  motive?: ModelStringInput | null;
  model?: ModelStringInput | null;
  vehicleId?: ModelStringInput | null;
  driverId?: ModelStringInput | null;
  envasadoId?: ModelStringInput | null;
  cisternaId?: ModelStringInput | null;
  tractoId?: ModelStringInput | null;
  tanqueId?: ModelStringInput | null;
  semirremolqueId?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelNotificationConditionInput | null> | null;
  or?: Array<ModelNotificationConditionInput | null> | null;
  not?: ModelNotificationConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type Notification = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateNotificationInput = {
  businessId: string;
  notificationId: string;
  status?: string | null;
  retries?: number | null;
  motive?: string | null;
  model?: string | null;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
};

export type DeleteNotificationInput = {
  businessId: string;
  notificationId: string;
};

export type CreateMailInput = {
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
};

export type ModelMailConditionInput = {
  receivers?: ModelStringInput | null;
  title?: ModelStringInput | null;
  content?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelMailConditionInput | null> | null;
  or?: Array<ModelMailConditionInput | null> | null;
  not?: ModelMailConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type Mail = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMailInput = {
  businessId: string;
  mailId: string;
  receivers?: string | null;
  title?: string | null;
  content?: string | null;
  owner?: string | null;
};

export type DeleteMailInput = {
  businessId: string;
  mailId: string;
};

export type CreateSpeedingInput = {
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
};

export type ModelSpeedingConditionInput = {
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  speedLimit?: ModelIntInput | null;
  actualSpeed?: ModelIntInput | null;
  counted?: ModelBooleanInput | null;
  driverId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelSpeedingConditionInput | null> | null;
  or?: Array<ModelSpeedingConditionInput | null> | null;
  not?: ModelSpeedingConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateSpeedingInput = {
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit?: number | null;
  actualSpeed?: number | null;
  counted?: boolean | null;
  driverId?: string | null;
  owner?: string | null;
};

export type DeleteSpeedingInput = {
  speedingId: string;
  speedingDatetime: string;
};

export type CreateFormQuestionTemplateInput = {
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
};

export type ModelFormQuestionTemplateConditionInput = {
  question?: ModelStringInput | null;
  criticality?: ModelStringInput | null;
  weight?: ModelFloatInput | null;
  order?: ModelIntInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelFormQuestionTemplateConditionInput | null> | null;
  or?: Array<ModelFormQuestionTemplateConditionInput | null> | null;
  not?: ModelFormQuestionTemplateConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type FormQuestionTemplate = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFormQuestionTemplateInput = {
  formTemplateId: string;
  formQuestionTemplateId: string;
  question?: string | null;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
};

export type DeleteFormQuestionTemplateInput = {
  formTemplateId: string;
  formQuestionTemplateId: string;
};

export type CreateRozFormInput = {
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  owner?: string | null;
};

export type ModelRozFormConditionInput = {
  status?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelRozFormConditionInput | null> | null;
  or?: Array<ModelRozFormConditionInput | null> | null;
  not?: ModelRozFormConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type RozForm = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: ModelFormQuestionConnection | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelFormQuestionConnection = {
  __typename: 'ModelFormQuestionConnection';
  items: Array<FormQuestion | null>;
  nextToken?: string | null;
};

export type FormQuestion = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: RozForm;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type UpdateRozFormInput = {
  formId: string;
  formDate: string;
  status?: string | null;
  comment?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
};

export type DeleteRozFormInput = {
  formId: string;
  formDate: string;
};

export type CreateEvaluatedRozFormInput = {
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
};

export type ModelEvaluatedRozFormConditionInput = {
  business?: ModelIDInput | null;
  subBusiness?: ModelStringInput | null;
  carrier?: ModelStringInput | null;
  center?: ModelStringInput | null;
  formDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  questions?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  s3Path?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelEvaluatedRozFormConditionInput | null> | null;
  or?: Array<ModelEvaluatedRozFormConditionInput | null> | null;
  not?: ModelEvaluatedRozFormConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type EvaluatedRozForm = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEvaluatedRozFormInput = {
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate?: string | null;
  status?: string | null;
  comment?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  questions?: Array<string | null> | null;
  score?: number | null;
  s3Path?: string | null;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
};

export type DeleteEvaluatedRozFormInput = {
  formId: string;
  evaluatedAt: string;
};

export type CreateFormQuestionInput = {
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  owner?: string | null;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type ModelFormQuestionConditionInput = {
  question?: ModelStringInput | null;
  criticality?: ModelStringInput | null;
  weight?: ModelFloatInput | null;
  order?: ModelIntInput | null;
  answer?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  imageS3Path?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelFormQuestionConditionInput | null> | null;
  or?: Array<ModelFormQuestionConditionInput | null> | null;
  not?: ModelFormQuestionConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  rozFormQuestionsFormId?: ModelIDInput | null;
  rozFormQuestionsFormDate?: ModelIDInput | null;
};

export type UpdateFormQuestionInput = {
  formQuestionId: string;
  question?: string | null;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  owner?: string | null;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type DeleteFormQuestionInput = {
  formQuestionId: string;
};

export type CreateEditionInput = {
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
};

export type ModelEditionConditionInput = {
  active?: ModelBooleanInput | null;
  editor?: ModelStringInput | null;
  editorId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelEditionConditionInput | null> | null;
  or?: Array<ModelEditionConditionInput | null> | null;
  not?: ModelEditionConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type Edition = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEditionInput = {
  editionId: string;
  active?: boolean | null;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
};

export type DeleteEditionInput = {
  editionId: string;
};

export type CreateMailPreferenceInput = {
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
};

export type ModelMailPreferenceConditionInput = {
  name?: ModelStringInput | null;
  receive?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelMailPreferenceConditionInput | null> | null;
  or?: Array<ModelMailPreferenceConditionInput | null> | null;
  not?: ModelMailPreferenceConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type MailPreference = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMailPreferenceInput = {
  userId: string;
  mailId: string;
  name?: string | null;
  receive?: boolean | null;
  owner?: string | null;
};

export type DeleteMailPreferenceInput = {
  userId: string;
  mailId: string;
};

export type CreateDriverExclusionInput = {
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
};

export type ModelDriverExclusionConditionInput = {
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  exclusionMotives?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelDriverExclusionConditionInput | null> | null;
  or?: Array<ModelDriverExclusionConditionInput | null> | null;
  not?: ModelDriverExclusionConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type DriverExclusion = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDriverExclusionInput = {
  driverId: string;
  startDate?: string | null;
  endDate?: string | null;
  exclusionMotives?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
};

export type DeleteDriverExclusionInput = {
  driverId: string;
};

export type CreateStatusInput = {
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
};

export type ModelStatusConditionInput = {
  status?: ModelStringInput | null;
  startedAt?: ModelStringInput | null;
  endedAt?: ModelStringInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  carrier?: ModelStringInput | null;
  center?: ModelStringInput | null;
  modelTypeName?: ModelStringInput | null;
  type?: ModelStringInput | null;
  entityId?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  coupleId?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  aboutToExpireDocuments?: ModelStringInput | null;
  expiredDocuments?: ModelStringInput | null;
  missingDocuments?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelStatusConditionInput | null> | null;
  or?: Array<ModelStatusConditionInput | null> | null;
  not?: ModelStatusConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type Status = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateStatusInput = {
  statusId: string;
  status?: string | null;
  startedAt?: string | null;
  endedAt?: string | null;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  modelTypeName?: string | null;
  type?: string | null;
  entityId?: string | null;
  sapId?: string | null;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
};

export type DeleteStatusInput = {
  statusId: string;
};

export type CreateArchiveInput = {
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  comment?: string | null;
  entity: string;
  owner?: string | null;
};

export type ModelArchiveConditionInput = {
  archivedBy?: ModelStringInput | null;
  business?: ModelStringInput | null;
  carrier?: ModelStringInput | null;
  center?: ModelStringInput | null;
  modelTypeName?: ModelStringInput | null;
  motiveForArchiving?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  entity?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelArchiveConditionInput | null> | null;
  or?: Array<ModelArchiveConditionInput | null> | null;
  not?: ModelArchiveConditionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdateArchiveInput = {
  archiveId: string;
  archivedAt: string;
  archivedBy?: string | null;
  business?: string | null;
  carrier?: string | null;
  center?: string | null;
  modelTypeName?: string | null;
  motiveForArchiving?: string | null;
  comment?: string | null;
  entity?: string | null;
  owner?: string | null;
};

export type DeleteArchiveInput = {
  archiveId: string;
  archivedAt: string;
};

export type ModelCarrierFilterInput = {
  carrierId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  rut?: ModelStringInput | null;
  email?: ModelStringInput | null;
  additionalEmails?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCarrierFilterInput | null> | null;
  or?: Array<ModelCarrierFilterInput | null> | null;
  not?: ModelCarrierFilterInput | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ModelCarrierConnection = {
  __typename: 'ModelCarrierConnection';
  items: Array<Carrier | null>;
  nextToken?: string | null;
};

export type ModelVehicleFilterInput = {
  vehicleId?: ModelIDInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  color?: ModelStringInput | null;
  model?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  imei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  isSpot?: ModelBooleanInput | null;
  sapId?: ModelStringInput | null;
  trailerLicensePlate?: ModelStringInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVehicleFilterInput | null> | null;
  or?: Array<ModelVehicleFilterInput | null> | null;
  not?: ModelVehicleFilterInput | null;
  carrierVehiclesCarrierId?: ModelIDInput | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelEnvasadoFilterInput = {
  envasadoId?: ModelIDInput | null;
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  engineType?: ModelStringInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  bodyType?: ModelStringInput | null;
  maximumPalletCapacity?: ModelIntInput | null;
  height?: ModelFloatInput | null;
  width?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEnvasadoFilterInput | null> | null;
  or?: Array<ModelEnvasadoFilterInput | null> | null;
  not?: ModelEnvasadoFilterInput | null;
  carrierEnvasadosCarrierId?: ModelIDInput | null;
};

export type ModelCisternaFilterInput = {
  cisternaId?: ModelIDInput | null;
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  engineType?: ModelStringInput | null;
  bulkheadType?: ModelStringInput | null;
  hasPump?: ModelBooleanInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCisternaFilterInput | null> | null;
  or?: Array<ModelCisternaFilterInput | null> | null;
  not?: ModelCisternaFilterInput | null;
  carrierCisternasCarrierId?: ModelIDInput | null;
};

export type ModelTractoFilterInput = {
  tractoId?: ModelIDInput | null;
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  engineType?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTractoFilterInput | null> | null;
  or?: Array<ModelTractoFilterInput | null> | null;
  not?: ModelTractoFilterInput | null;
  carrierTractosCarrierId?: ModelIDInput | null;
  tractoCoupleCoupleId?: ModelIDInput | null;
};

export type ModelTanqueFilterInput = {
  tanqueId?: ModelIDInput | null;
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  height?: ModelFloatInput | null;
  width?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  bulkheadType?: ModelStringInput | null;
  hasPump?: ModelBooleanInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTanqueFilterInput | null> | null;
  or?: Array<ModelTanqueFilterInput | null> | null;
  not?: ModelTanqueFilterInput | null;
  carrierTanquesCarrierId?: ModelIDInput | null;
  tanqueCoupleCoupleId?: ModelIDInput | null;
};

export type ModelSemirremolqueFilterInput = {
  semirremolqueId?: ModelIDInput | null;
  sapId?: ModelStringInput | null;
  brand?: ModelStringInput | null;
  licensePlate?: ModelStringInput | null;
  model?: ModelStringInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  color?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  capacity?: ModelStringInput | null;
  maximumPalletCapacity?: ModelIntInput | null;
  height?: ModelFloatInput | null;
  width?: ModelFloatInput | null;
  length?: ModelFloatInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  gpsImei?: ModelStringInput | null;
  cameraImei?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  forMining?: ModelBooleanInput | null;
  hasRanchAccreditation?: ModelBooleanInput | null;
  isSpot?: ModelBooleanInput | null;
  rampType?: ModelStringInput | null;
  MOPCode?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSemirremolqueFilterInput | null> | null;
  or?: Array<ModelSemirremolqueFilterInput | null> | null;
  not?: ModelSemirremolqueFilterInput | null;
  carrierSemirremolquesCarrierId?: ModelIDInput | null;
  semirremolqueCoupleCoupleId?: ModelIDInput | null;
};

export type ModelCoupleFilterInput = {
  coupleId?: ModelIDInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  company?: ModelStringInput | null;
  status?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCoupleFilterInput | null> | null;
  or?: Array<ModelCoupleFilterInput | null> | null;
  not?: ModelCoupleFilterInput | null;
  coupleTractoTractoId?: ModelIDInput | null;
  coupleTanqueTanqueId?: ModelIDInput | null;
  coupleSemirremolqueSemirremolqueId?: ModelIDInput | null;
};

export type ModelCoupleConnection = {
  __typename: 'ModelCoupleConnection';
  items: Array<Couple | null>;
  nextToken?: string | null;
};

export type ModelDriverFilterInput = {
  driverId?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  rut?: ModelStringInput | null;
  birthDate?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  business?: ModelStringInput | null;
  center?: ModelStringInput | null;
  originalCenter?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  documentsToExpire?: ModelIntInput | null;
  documentsExpired?: ModelIntInput | null;
  status?: ModelStringInput | null;
  lastStatusInformed?: ModelStringInput | null;
  lastStatusInformedAt?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  company?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  archiveId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDriverFilterInput | null> | null;
  or?: Array<ModelDriverFilterInput | null> | null;
  not?: ModelDriverFilterInput | null;
  carrierDriversCarrierId?: ModelIDInput | null;
};

export type ModelDocumentFilterInput = {
  documentId?: ModelIDInput | null;
  masterValueId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  status?: ModelStringInput | null;
  s3Path?: ModelStringInput | null;
  issueDate?: ModelStringInput | null;
  expirationDate?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDocumentFilterInput | null> | null;
  or?: Array<ModelDocumentFilterInput | null> | null;
  not?: ModelDocumentFilterInput | null;
  vehicleDocumentsVehicleId?: ModelIDInput | null;
  envasadoDocumentsEnvasadoId?: ModelIDInput | null;
  cisternaDocumentsCisternaId?: ModelIDInput | null;
  tractoDocumentsTractoId?: ModelIDInput | null;
  tanqueDocumentsTanqueId?: ModelIDInput | null;
  semirremolqueDocumentsSemirremolqueId?: ModelIDInput | null;
  driverDocumentsDriverId?: ModelIDInput | null;
  archiveDocumentsArchiveId?: ModelIDInput | null;
  archiveDocumentsArchivedAt?: ModelIDInput | null;
};

export type ModelMasterFilterInput = {
  businessId?: ModelIDInput | null;
  masterId?: ModelIDInput | null;
  masterGroup?: ModelStringInput | null;
  valueId?: ModelStringInput | null;
  valueToDisplay?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMasterFilterInput | null> | null;
  or?: Array<ModelMasterFilterInput | null> | null;
  not?: ModelMasterFilterInput | null;
};

export type ModelMasterConnection = {
  __typename: 'ModelMasterConnection';
  items: Array<Master | null>;
  nextToken?: string | null;
};

export type ModelDocumentSpecsFilterInput = {
  businessId?: ModelIDInput | null;
  documentSpecsId?: ModelIDInput | null;
  isMandatory?: ModelBooleanInput | null;
  canBlock?: ModelBooleanInput | null;
  extensionDays?: ModelIntInput | null;
  updatedBy?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDocumentSpecsFilterInput | null> | null;
  or?: Array<ModelDocumentSpecsFilterInput | null> | null;
  not?: ModelDocumentSpecsFilterInput | null;
};

export type ModelDocumentSpecsConnection = {
  __typename: 'ModelDocumentSpecsConnection';
  items: Array<DocumentSpecs | null>;
  nextToken?: string | null;
};

export type ModelUserFilterInput = {
  userId?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  rut?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  business?: ModelStringInput | null;
  hasAccessTo?: ModelStringInput | null;
  authGroup?: ModelStringInput | null;
  status?: ModelStringInput | null;
  company?: ModelStringInput | null;
  centers?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelUserConnection = {
  __typename: 'ModelUserConnection';
  items: Array<User | null>;
  nextToken?: string | null;
};

export type ModelNotificationFilterInput = {
  businessId?: ModelIDInput | null;
  notificationId?: ModelIDInput | null;
  status?: ModelStringInput | null;
  retries?: ModelIntInput | null;
  motive?: ModelStringInput | null;
  model?: ModelStringInput | null;
  vehicleId?: ModelStringInput | null;
  driverId?: ModelStringInput | null;
  envasadoId?: ModelStringInput | null;
  cisternaId?: ModelStringInput | null;
  tractoId?: ModelStringInput | null;
  tanqueId?: ModelStringInput | null;
  semirremolqueId?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNotificationFilterInput | null> | null;
  or?: Array<ModelNotificationFilterInput | null> | null;
  not?: ModelNotificationFilterInput | null;
};

export type ModelNotificationConnection = {
  __typename: 'ModelNotificationConnection';
  items: Array<Notification | null>;
  nextToken?: string | null;
};

export type ModelMailFilterInput = {
  businessId?: ModelIDInput | null;
  mailId?: ModelIDInput | null;
  receivers?: ModelStringInput | null;
  title?: ModelStringInput | null;
  content?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMailFilterInput | null> | null;
  or?: Array<ModelMailFilterInput | null> | null;
  not?: ModelMailFilterInput | null;
};

export type ModelMailConnection = {
  __typename: 'ModelMailConnection';
  items: Array<Mail | null>;
  nextToken?: string | null;
};

export type ModelSpeedingFilterInput = {
  speedingId?: ModelIDInput | null;
  speedingDatetime?: ModelIDInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  speedLimit?: ModelIntInput | null;
  actualSpeed?: ModelIntInput | null;
  counted?: ModelBooleanInput | null;
  driverId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSpeedingFilterInput | null> | null;
  or?: Array<ModelSpeedingFilterInput | null> | null;
  not?: ModelSpeedingFilterInput | null;
};

export type ModelFormQuestionTemplateFilterInput = {
  formTemplateId?: ModelIDInput | null;
  formQuestionTemplateId?: ModelIDInput | null;
  question?: ModelStringInput | null;
  criticality?: ModelStringInput | null;
  weight?: ModelFloatInput | null;
  order?: ModelIntInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFormQuestionTemplateFilterInput | null> | null;
  or?: Array<ModelFormQuestionTemplateFilterInput | null> | null;
  not?: ModelFormQuestionTemplateFilterInput | null;
};

export type ModelFormQuestionTemplateConnection = {
  __typename: 'ModelFormQuestionTemplateConnection';
  items: Array<FormQuestionTemplate | null>;
  nextToken?: string | null;
};

export type ModelRozFormFilterInput = {
  formId?: ModelIDInput | null;
  formDate?: ModelIDInput | null;
  status?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelRozFormFilterInput | null> | null;
  or?: Array<ModelRozFormFilterInput | null> | null;
  not?: ModelRozFormFilterInput | null;
};

export type ModelRozFormConnection = {
  __typename: 'ModelRozFormConnection';
  items: Array<RozForm | null>;
  nextToken?: string | null;
};

export type ModelEvaluatedRozFormFilterInput = {
  formId?: ModelIDInput | null;
  evaluatedAt?: ModelIDInput | null;
  business?: ModelIDInput | null;
  subBusiness?: ModelStringInput | null;
  carrier?: ModelStringInput | null;
  center?: ModelStringInput | null;
  formDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  questions?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  s3Path?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  year?: ModelIntInput | null;
  type?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEvaluatedRozFormFilterInput | null> | null;
  or?: Array<ModelEvaluatedRozFormFilterInput | null> | null;
  not?: ModelEvaluatedRozFormFilterInput | null;
};

export type ModelEvaluatedRozFormConnection = {
  __typename: 'ModelEvaluatedRozFormConnection';
  items: Array<EvaluatedRozForm | null>;
  nextToken?: string | null;
};

export type ModelFormQuestionFilterInput = {
  formQuestionId?: ModelIDInput | null;
  question?: ModelStringInput | null;
  criticality?: ModelStringInput | null;
  weight?: ModelFloatInput | null;
  order?: ModelIntInput | null;
  answer?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  imageS3Path?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFormQuestionFilterInput | null> | null;
  or?: Array<ModelFormQuestionFilterInput | null> | null;
  not?: ModelFormQuestionFilterInput | null;
  rozFormQuestionsFormId?: ModelIDInput | null;
  rozFormQuestionsFormDate?: ModelIDInput | null;
};

export type ModelEditionFilterInput = {
  editionId?: ModelIDInput | null;
  active?: ModelBooleanInput | null;
  editor?: ModelStringInput | null;
  editorId?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEditionFilterInput | null> | null;
  or?: Array<ModelEditionFilterInput | null> | null;
  not?: ModelEditionFilterInput | null;
};

export type ModelEditionConnection = {
  __typename: 'ModelEditionConnection';
  items: Array<Edition | null>;
  nextToken?: string | null;
};

export type ModelMailPreferenceFilterInput = {
  userId?: ModelIDInput | null;
  mailId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  receive?: ModelBooleanInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMailPreferenceFilterInput | null> | null;
  or?: Array<ModelMailPreferenceFilterInput | null> | null;
  not?: ModelMailPreferenceFilterInput | null;
};

export type ModelMailPreferenceConnection = {
  __typename: 'ModelMailPreferenceConnection';
  items: Array<MailPreference | null>;
  nextToken?: string | null;
};

export type ModelDriverExclusionFilterInput = {
  driverId?: ModelIDInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  exclusionMotives?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelDriverExclusionFilterInput | null> | null;
  or?: Array<ModelDriverExclusionFilterInput | null> | null;
  not?: ModelDriverExclusionFilterInput | null;
};

export type ModelDriverExclusionConnection = {
  __typename: 'ModelDriverExclusionConnection';
  items: Array<DriverExclusion | null>;
  nextToken?: string | null;
};

export type ModelStatusFilterInput = {
  statusId?: ModelIDInput | null;
  status?: ModelStringInput | null;
  startedAt?: ModelStringInput | null;
  endedAt?: ModelStringInput | null;
  business?: ModelStringInput | null;
  subBusiness?: ModelStringInput | null;
  carrier?: ModelStringInput | null;
  center?: ModelStringInput | null;
  modelTypeName?: ModelStringInput | null;
  type?: ModelStringInput | null;
  entityId?: ModelStringInput | null;
  sapId?: ModelStringInput | null;
  coupleId?: ModelStringInput | null;
  blockingMotives?: ModelStringInput | null;
  aboutToExpireDocuments?: ModelStringInput | null;
  expiredDocuments?: ModelStringInput | null;
  missingDocuments?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStatusFilterInput | null> | null;
  or?: Array<ModelStatusFilterInput | null> | null;
  not?: ModelStatusFilterInput | null;
};

export type ModelStatusConnection = {
  __typename: 'ModelStatusConnection';
  items: Array<Status | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelArchiveFilterInput = {
  archiveId?: ModelIDInput | null;
  archivedAt?: ModelIDInput | null;
  archivedBy?: ModelStringInput | null;
  business?: ModelStringInput | null;
  carrier?: ModelStringInput | null;
  center?: ModelStringInput | null;
  modelTypeName?: ModelStringInput | null;
  motiveForArchiving?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  entity?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelArchiveFilterInput | null> | null;
  or?: Array<ModelArchiveFilterInput | null> | null;
  not?: ModelArchiveFilterInput | null;
};

export type ModelArchiveConnection = {
  __typename: 'ModelArchiveConnection';
  items: Array<Archive | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionCarrierFilterInput = {
  carrierId?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  rut?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  additionalEmails?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCarrierFilterInput | null> | null;
  or?: Array<ModelSubscriptionCarrierFilterInput | null> | null;
  carrierVehiclesCarrierId?: ModelSubscriptionIDInput | null;
  carrierEnvasadosCarrierId?: ModelSubscriptionIDInput | null;
  carrierCisternasCarrierId?: ModelSubscriptionIDInput | null;
  carrierTractosCarrierId?: ModelSubscriptionIDInput | null;
  carrierTanquesCarrierId?: ModelSubscriptionIDInput | null;
  carrierSemirremolquesCarrierId?: ModelSubscriptionIDInput | null;
  carrierDriversCarrierId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionVehicleFilterInput = {
  vehicleId?: ModelSubscriptionIDInput | null;
  brand?: ModelSubscriptionStringInput | null;
  licensePlate?: ModelSubscriptionStringInput | null;
  color?: ModelSubscriptionStringInput | null;
  model?: ModelSubscriptionStringInput | null;
  year?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  capacity?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  originalCenter?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  imei?: ModelSubscriptionStringInput | null;
  cameraImei?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  documentsToExpire?: ModelSubscriptionIntInput | null;
  documentsExpired?: ModelSubscriptionIntInput | null;
  status?: ModelSubscriptionStringInput | null;
  lastStatusInformed?: ModelSubscriptionStringInput | null;
  lastStatusInformedAt?: ModelSubscriptionStringInput | null;
  isSpot?: ModelSubscriptionBooleanInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  trailerLicensePlate?: ModelSubscriptionStringInput | null;
  company?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  archiveId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionVehicleFilterInput | null> | null;
  or?: Array<ModelSubscriptionVehicleFilterInput | null> | null;
  vehicleDocumentsVehicleId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionEnvasadoFilterInput = {
  envasadoId?: ModelSubscriptionIDInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  brand?: ModelSubscriptionStringInput | null;
  licensePlate?: ModelSubscriptionStringInput | null;
  model?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  lastStatusInformed?: ModelSubscriptionStringInput | null;
  lastStatusInformedAt?: ModelSubscriptionStringInput | null;
  color?: ModelSubscriptionStringInput | null;
  year?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  capacity?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  originalCenter?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  gpsImei?: ModelSubscriptionStringInput | null;
  cameraImei?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  forMining?: ModelSubscriptionBooleanInput | null;
  engineType?: ModelSubscriptionStringInput | null;
  hasRanchAccreditation?: ModelSubscriptionBooleanInput | null;
  isSpot?: ModelSubscriptionBooleanInput | null;
  bodyType?: ModelSubscriptionStringInput | null;
  maximumPalletCapacity?: ModelSubscriptionIntInput | null;
  height?: ModelSubscriptionFloatInput | null;
  width?: ModelSubscriptionFloatInput | null;
  length?: ModelSubscriptionFloatInput | null;
  documentsToExpire?: ModelSubscriptionIntInput | null;
  documentsExpired?: ModelSubscriptionIntInput | null;
  company?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  archiveId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionEnvasadoFilterInput | null> | null;
  or?: Array<ModelSubscriptionEnvasadoFilterInput | null> | null;
  envasadoDocumentsEnvasadoId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionCisternaFilterInput = {
  cisternaId?: ModelSubscriptionIDInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  brand?: ModelSubscriptionStringInput | null;
  licensePlate?: ModelSubscriptionStringInput | null;
  model?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  lastStatusInformed?: ModelSubscriptionStringInput | null;
  lastStatusInformedAt?: ModelSubscriptionStringInput | null;
  color?: ModelSubscriptionStringInput | null;
  year?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  capacity?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  subBusiness?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  originalCenter?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  gpsImei?: ModelSubscriptionStringInput | null;
  cameraImei?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  forMining?: ModelSubscriptionBooleanInput | null;
  hasRanchAccreditation?: ModelSubscriptionBooleanInput | null;
  isSpot?: ModelSubscriptionBooleanInput | null;
  engineType?: ModelSubscriptionStringInput | null;
  bulkheadType?: ModelSubscriptionStringInput | null;
  hasPump?: ModelSubscriptionBooleanInput | null;
  documentsToExpire?: ModelSubscriptionIntInput | null;
  documentsExpired?: ModelSubscriptionIntInput | null;
  company?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  archiveId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCisternaFilterInput | null> | null;
  or?: Array<ModelSubscriptionCisternaFilterInput | null> | null;
  cisternaDocumentsCisternaId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionTractoFilterInput = {
  tractoId?: ModelSubscriptionIDInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  brand?: ModelSubscriptionStringInput | null;
  licensePlate?: ModelSubscriptionStringInput | null;
  model?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  lastStatusInformed?: ModelSubscriptionStringInput | null;
  lastStatusInformedAt?: ModelSubscriptionStringInput | null;
  color?: ModelSubscriptionStringInput | null;
  year?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  capacity?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  originalCenter?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  gpsImei?: ModelSubscriptionStringInput | null;
  cameraImei?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  forMining?: ModelSubscriptionBooleanInput | null;
  hasRanchAccreditation?: ModelSubscriptionBooleanInput | null;
  isSpot?: ModelSubscriptionBooleanInput | null;
  engineType?: ModelSubscriptionStringInput | null;
  documentsToExpire?: ModelSubscriptionIntInput | null;
  documentsExpired?: ModelSubscriptionIntInput | null;
  company?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  archiveId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTractoFilterInput | null> | null;
  or?: Array<ModelSubscriptionTractoFilterInput | null> | null;
  tractoDocumentsTractoId?: ModelSubscriptionIDInput | null;
  tractoCoupleCoupleId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionTanqueFilterInput = {
  tanqueId?: ModelSubscriptionIDInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  brand?: ModelSubscriptionStringInput | null;
  licensePlate?: ModelSubscriptionStringInput | null;
  model?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  lastStatusInformed?: ModelSubscriptionStringInput | null;
  lastStatusInformedAt?: ModelSubscriptionStringInput | null;
  color?: ModelSubscriptionStringInput | null;
  year?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  capacity?: ModelSubscriptionStringInput | null;
  height?: ModelSubscriptionFloatInput | null;
  width?: ModelSubscriptionFloatInput | null;
  length?: ModelSubscriptionFloatInput | null;
  business?: ModelSubscriptionStringInput | null;
  subBusiness?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  originalCenter?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  gpsImei?: ModelSubscriptionStringInput | null;
  cameraImei?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  forMining?: ModelSubscriptionBooleanInput | null;
  hasRanchAccreditation?: ModelSubscriptionBooleanInput | null;
  isSpot?: ModelSubscriptionBooleanInput | null;
  bulkheadType?: ModelSubscriptionStringInput | null;
  hasPump?: ModelSubscriptionBooleanInput | null;
  documentsToExpire?: ModelSubscriptionIntInput | null;
  documentsExpired?: ModelSubscriptionIntInput | null;
  company?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  archiveId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTanqueFilterInput | null> | null;
  or?: Array<ModelSubscriptionTanqueFilterInput | null> | null;
  tanqueDocumentsTanqueId?: ModelSubscriptionIDInput | null;
  tanqueCoupleCoupleId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionSemirremolqueFilterInput = {
  semirremolqueId?: ModelSubscriptionIDInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  brand?: ModelSubscriptionStringInput | null;
  licensePlate?: ModelSubscriptionStringInput | null;
  model?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  lastStatusInformed?: ModelSubscriptionStringInput | null;
  lastStatusInformedAt?: ModelSubscriptionStringInput | null;
  color?: ModelSubscriptionStringInput | null;
  year?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  capacity?: ModelSubscriptionStringInput | null;
  maximumPalletCapacity?: ModelSubscriptionIntInput | null;
  height?: ModelSubscriptionFloatInput | null;
  width?: ModelSubscriptionFloatInput | null;
  length?: ModelSubscriptionFloatInput | null;
  business?: ModelSubscriptionStringInput | null;
  subBusiness?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  originalCenter?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  gpsImei?: ModelSubscriptionStringInput | null;
  cameraImei?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  forMining?: ModelSubscriptionBooleanInput | null;
  hasRanchAccreditation?: ModelSubscriptionBooleanInput | null;
  isSpot?: ModelSubscriptionBooleanInput | null;
  rampType?: ModelSubscriptionStringInput | null;
  MOPCode?: ModelSubscriptionStringInput | null;
  documentsToExpire?: ModelSubscriptionIntInput | null;
  documentsExpired?: ModelSubscriptionIntInput | null;
  company?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  archiveId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSemirremolqueFilterInput | null> | null;
  or?: Array<ModelSubscriptionSemirremolqueFilterInput | null> | null;
  semirremolqueDocumentsSemirremolqueId?: ModelSubscriptionIDInput | null;
  semirremolqueCoupleCoupleId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionCoupleFilterInput = {
  coupleId?: ModelSubscriptionIDInput | null;
  business?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  company?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCoupleFilterInput | null> | null;
  or?: Array<ModelSubscriptionCoupleFilterInput | null> | null;
  coupleTractoTractoId?: ModelSubscriptionIDInput | null;
  coupleTanqueTanqueId?: ModelSubscriptionIDInput | null;
  coupleSemirremolqueSemirremolqueId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionDriverFilterInput = {
  driverId?: ModelSubscriptionIDInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  rut?: ModelSubscriptionStringInput | null;
  birthDate?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  originalCenter?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  documentsToExpire?: ModelSubscriptionIntInput | null;
  documentsExpired?: ModelSubscriptionIntInput | null;
  status?: ModelSubscriptionStringInput | null;
  lastStatusInformed?: ModelSubscriptionStringInput | null;
  lastStatusInformedAt?: ModelSubscriptionStringInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  company?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  archiveId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionDriverFilterInput | null> | null;
  or?: Array<ModelSubscriptionDriverFilterInput | null> | null;
  driverDocumentsDriverId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionDocumentFilterInput = {
  documentId?: ModelSubscriptionIDInput | null;
  masterValueId?: ModelSubscriptionStringInput | null;
  name?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  s3Path?: ModelSubscriptionStringInput | null;
  issueDate?: ModelSubscriptionStringInput | null;
  expirationDate?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionDocumentFilterInput | null> | null;
  or?: Array<ModelSubscriptionDocumentFilterInput | null> | null;
};

export type ModelSubscriptionMasterFilterInput = {
  businessId?: ModelSubscriptionIDInput | null;
  masterId?: ModelSubscriptionIDInput | null;
  masterGroup?: ModelSubscriptionStringInput | null;
  valueId?: ModelSubscriptionStringInput | null;
  valueToDisplay?: ModelSubscriptionStringInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMasterFilterInput | null> | null;
  or?: Array<ModelSubscriptionMasterFilterInput | null> | null;
};

export type ModelSubscriptionDocumentSpecsFilterInput = {
  businessId?: ModelSubscriptionIDInput | null;
  documentSpecsId?: ModelSubscriptionIDInput | null;
  isMandatory?: ModelSubscriptionBooleanInput | null;
  canBlock?: ModelSubscriptionBooleanInput | null;
  extensionDays?: ModelSubscriptionIntInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionDocumentSpecsFilterInput | null> | null;
  or?: Array<ModelSubscriptionDocumentSpecsFilterInput | null> | null;
};

export type ModelSubscriptionUserFilterInput = {
  userId?: ModelSubscriptionIDInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  rut?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  hasAccessTo?: ModelSubscriptionStringInput | null;
  authGroup?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  company?: ModelSubscriptionStringInput | null;
  centers?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserFilterInput | null> | null;
};

export type ModelSubscriptionNotificationFilterInput = {
  businessId?: ModelSubscriptionIDInput | null;
  notificationId?: ModelSubscriptionIDInput | null;
  status?: ModelSubscriptionStringInput | null;
  retries?: ModelSubscriptionIntInput | null;
  motive?: ModelSubscriptionStringInput | null;
  model?: ModelSubscriptionStringInput | null;
  vehicleId?: ModelSubscriptionStringInput | null;
  driverId?: ModelSubscriptionStringInput | null;
  envasadoId?: ModelSubscriptionStringInput | null;
  cisternaId?: ModelSubscriptionStringInput | null;
  tractoId?: ModelSubscriptionStringInput | null;
  tanqueId?: ModelSubscriptionStringInput | null;
  semirremolqueId?: ModelSubscriptionStringInput | null;
  userId?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionNotificationFilterInput | null> | null;
  or?: Array<ModelSubscriptionNotificationFilterInput | null> | null;
};

export type ModelSubscriptionMailFilterInput = {
  businessId?: ModelSubscriptionIDInput | null;
  mailId?: ModelSubscriptionIDInput | null;
  receivers?: ModelSubscriptionStringInput | null;
  title?: ModelSubscriptionStringInput | null;
  content?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMailFilterInput | null> | null;
  or?: Array<ModelSubscriptionMailFilterInput | null> | null;
};

export type ModelSubscriptionSpeedingFilterInput = {
  speedingId?: ModelSubscriptionIDInput | null;
  speedingDatetime?: ModelSubscriptionIDInput | null;
  latitude?: ModelSubscriptionStringInput | null;
  longitude?: ModelSubscriptionStringInput | null;
  speedLimit?: ModelSubscriptionIntInput | null;
  actualSpeed?: ModelSubscriptionIntInput | null;
  counted?: ModelSubscriptionBooleanInput | null;
  driverId?: ModelSubscriptionIDInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSpeedingFilterInput | null> | null;
  or?: Array<ModelSubscriptionSpeedingFilterInput | null> | null;
};

export type ModelSubscriptionFormQuestionTemplateFilterInput = {
  formTemplateId?: ModelSubscriptionIDInput | null;
  formQuestionTemplateId?: ModelSubscriptionIDInput | null;
  question?: ModelSubscriptionStringInput | null;
  criticality?: ModelSubscriptionStringInput | null;
  weight?: ModelSubscriptionFloatInput | null;
  order?: ModelSubscriptionIntInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionFormQuestionTemplateFilterInput | null> | null;
  or?: Array<ModelSubscriptionFormQuestionTemplateFilterInput | null> | null;
};

export type ModelSubscriptionRozFormFilterInput = {
  formId?: ModelSubscriptionIDInput | null;
  formDate?: ModelSubscriptionIDInput | null;
  status?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  createdBy?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionRozFormFilterInput | null> | null;
  or?: Array<ModelSubscriptionRozFormFilterInput | null> | null;
  rozFormQuestionsFormId?: ModelSubscriptionIDInput | null;
  rozFormQuestionsFormDate?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionEvaluatedRozFormFilterInput = {
  formId?: ModelSubscriptionIDInput | null;
  evaluatedAt?: ModelSubscriptionIDInput | null;
  business?: ModelSubscriptionIDInput | null;
  subBusiness?: ModelSubscriptionStringInput | null;
  carrier?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  formDate?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  createdBy?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  questions?: ModelSubscriptionStringInput | null;
  score?: ModelSubscriptionFloatInput | null;
  s3Path?: ModelSubscriptionStringInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  year?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionEvaluatedRozFormFilterInput | null> | null;
  or?: Array<ModelSubscriptionEvaluatedRozFormFilterInput | null> | null;
};

export type ModelSubscriptionFormQuestionFilterInput = {
  formQuestionId?: ModelSubscriptionIDInput | null;
  question?: ModelSubscriptionStringInput | null;
  criticality?: ModelSubscriptionStringInput | null;
  weight?: ModelSubscriptionFloatInput | null;
  order?: ModelSubscriptionIntInput | null;
  answer?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  imageS3Path?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionFormQuestionFilterInput | null> | null;
  or?: Array<ModelSubscriptionFormQuestionFilterInput | null> | null;
};

export type ModelSubscriptionEditionFilterInput = {
  editionId?: ModelSubscriptionIDInput | null;
  active?: ModelSubscriptionBooleanInput | null;
  editor?: ModelSubscriptionStringInput | null;
  editorId?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionEditionFilterInput | null> | null;
  or?: Array<ModelSubscriptionEditionFilterInput | null> | null;
};

export type ModelSubscriptionMailPreferenceFilterInput = {
  userId?: ModelSubscriptionIDInput | null;
  mailId?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  receive?: ModelSubscriptionBooleanInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionMailPreferenceFilterInput | null> | null;
  or?: Array<ModelSubscriptionMailPreferenceFilterInput | null> | null;
};

export type ModelSubscriptionDriverExclusionFilterInput = {
  driverId?: ModelSubscriptionIDInput | null;
  startDate?: ModelSubscriptionStringInput | null;
  endDate?: ModelSubscriptionStringInput | null;
  exclusionMotives?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionDriverExclusionFilterInput | null> | null;
  or?: Array<ModelSubscriptionDriverExclusionFilterInput | null> | null;
};

export type ModelSubscriptionStatusFilterInput = {
  statusId?: ModelSubscriptionIDInput | null;
  status?: ModelSubscriptionStringInput | null;
  startedAt?: ModelSubscriptionStringInput | null;
  endedAt?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  subBusiness?: ModelSubscriptionStringInput | null;
  carrier?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  modelTypeName?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  entityId?: ModelSubscriptionStringInput | null;
  sapId?: ModelSubscriptionStringInput | null;
  coupleId?: ModelSubscriptionStringInput | null;
  blockingMotives?: ModelSubscriptionStringInput | null;
  aboutToExpireDocuments?: ModelSubscriptionStringInput | null;
  expiredDocuments?: ModelSubscriptionStringInput | null;
  missingDocuments?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionStatusFilterInput | null> | null;
  or?: Array<ModelSubscriptionStatusFilterInput | null> | null;
};

export type ModelSubscriptionArchiveFilterInput = {
  archiveId?: ModelSubscriptionIDInput | null;
  archivedAt?: ModelSubscriptionIDInput | null;
  archivedBy?: ModelSubscriptionStringInput | null;
  business?: ModelSubscriptionStringInput | null;
  carrier?: ModelSubscriptionStringInput | null;
  center?: ModelSubscriptionStringInput | null;
  modelTypeName?: ModelSubscriptionStringInput | null;
  motiveForArchiving?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  entity?: ModelSubscriptionStringInput | null;
  owner?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionArchiveFilterInput | null> | null;
  or?: Array<ModelSubscriptionArchiveFilterInput | null> | null;
  archiveDocumentsArchiveId?: ModelSubscriptionIDInput | null;
  archiveDocumentsArchivedAt?: ModelSubscriptionIDInput | null;
};

export type CreateCarrierMutation = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: {
    __typename: 'ModelVehicleConnection';
    items: Array<{
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  envasados?: {
    __typename: 'ModelEnvasadoConnection';
    items: Array<{
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cisternas?: {
    __typename: 'ModelCisternaConnection';
    items: Array<{
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tractos?: {
    __typename: 'ModelTractoConnection';
    items: Array<{
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tanques?: {
    __typename: 'ModelTanqueConnection';
    items: Array<{
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  semirremolques?: {
    __typename: 'ModelSemirremolqueConnection';
    items: Array<{
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  drivers?: {
    __typename: 'ModelDriverConnection';
    items: Array<{
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCarrierMutation = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: {
    __typename: 'ModelVehicleConnection';
    items: Array<{
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  envasados?: {
    __typename: 'ModelEnvasadoConnection';
    items: Array<{
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cisternas?: {
    __typename: 'ModelCisternaConnection';
    items: Array<{
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tractos?: {
    __typename: 'ModelTractoConnection';
    items: Array<{
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tanques?: {
    __typename: 'ModelTanqueConnection';
    items: Array<{
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  semirremolques?: {
    __typename: 'ModelSemirremolqueConnection';
    items: Array<{
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  drivers?: {
    __typename: 'ModelDriverConnection';
    items: Array<{
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCarrierMutation = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: {
    __typename: 'ModelVehicleConnection';
    items: Array<{
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  envasados?: {
    __typename: 'ModelEnvasadoConnection';
    items: Array<{
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cisternas?: {
    __typename: 'ModelCisternaConnection';
    items: Array<{
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tractos?: {
    __typename: 'ModelTractoConnection';
    items: Array<{
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tanques?: {
    __typename: 'ModelTanqueConnection';
    items: Array<{
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  semirremolques?: {
    __typename: 'ModelSemirremolqueConnection';
    items: Array<{
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  drivers?: {
    __typename: 'ModelDriverConnection';
    items: Array<{
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateVehicleMutation = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type UpdateVehicleMutation = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type DeleteVehicleMutation = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type CreateEnvasadoMutation = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type UpdateEnvasadoMutation = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type DeleteEnvasadoMutation = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type CreateCisternaMutation = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type UpdateCisternaMutation = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type DeleteCisternaMutation = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type CreateTractoMutation = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type UpdateTractoMutation = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type DeleteTractoMutation = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type CreateTanqueMutation = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type UpdateTanqueMutation = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type DeleteTanqueMutation = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type CreateSemirremolqueMutation = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type UpdateSemirremolqueMutation = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type DeleteSemirremolqueMutation = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type CreateCoupleMutation = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  };
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type UpdateCoupleMutation = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  };
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type DeleteCoupleMutation = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  };
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type CreateDriverMutation = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  speedings?: {
    __typename: 'ModelSpeedingConnection';
    items: Array<{
      __typename: 'Speeding';
      speedingId: string;
      speedingDatetime: string;
      latitude?: string | null;
      longitude?: string | null;
      speedLimit: number;
      actualSpeed: number;
      counted?: boolean | null;
      driverId: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type UpdateDriverMutation = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  speedings?: {
    __typename: 'ModelSpeedingConnection';
    items: Array<{
      __typename: 'Speeding';
      speedingId: string;
      speedingDatetime: string;
      latitude?: string | null;
      longitude?: string | null;
      speedLimit: number;
      actualSpeed: number;
      counted?: boolean | null;
      driverId: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type DeleteDriverMutation = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  speedings?: {
    __typename: 'ModelSpeedingConnection';
    items: Array<{
      __typename: 'Speeding';
      speedingId: string;
      speedingDatetime: string;
      latitude?: string | null;
      longitude?: string | null;
      speedLimit: number;
      actualSpeed: number;
      counted?: boolean | null;
      driverId: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type CreateDocumentMutation = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: {
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null;
  envasado?: {
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null;
  cisterna?: {
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null;
  tracto?: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null;
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  driver?: {
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null;
  archive?: {
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type UpdateDocumentMutation = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: {
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null;
  envasado?: {
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null;
  cisterna?: {
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null;
  tracto?: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null;
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  driver?: {
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null;
  archive?: {
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type DeleteDocumentMutation = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: {
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null;
  envasado?: {
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null;
  cisterna?: {
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null;
  tracto?: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null;
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  driver?: {
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null;
  archive?: {
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type CreateMasterMutation = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMasterMutation = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMasterMutation = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateDocumentSpecsMutation = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDocumentSpecsMutation = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDocumentSpecsMutation = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateNotificationMutation = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateNotificationMutation = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteNotificationMutation = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMailMutation = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMailMutation = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMailMutation = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSpeedingMutation = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSpeedingMutation = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSpeedingMutation = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateFormQuestionTemplateMutation = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFormQuestionTemplateMutation = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteFormQuestionTemplateMutation = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateRozFormMutation = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: {
    __typename: 'ModelFormQuestionConnection';
    items: Array<{
      __typename: 'FormQuestion';
      formQuestionId: string;
      question: string;
      criticality?: string | null;
      weight?: number | null;
      order?: number | null;
      answer?: string | null;
      comment?: string | null;
      imageS3Path?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      rozFormQuestionsFormId?: string | null;
      rozFormQuestionsFormDate?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRozFormMutation = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: {
    __typename: 'ModelFormQuestionConnection';
    items: Array<{
      __typename: 'FormQuestion';
      formQuestionId: string;
      question: string;
      criticality?: string | null;
      weight?: number | null;
      order?: number | null;
      answer?: string | null;
      comment?: string | null;
      imageS3Path?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      rozFormQuestionsFormId?: string | null;
      rozFormQuestionsFormDate?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRozFormMutation = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: {
    __typename: 'ModelFormQuestionConnection';
    items: Array<{
      __typename: 'FormQuestion';
      formQuestionId: string;
      question: string;
      criticality?: string | null;
      weight?: number | null;
      order?: number | null;
      answer?: string | null;
      comment?: string | null;
      imageS3Path?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      rozFormQuestionsFormId?: string | null;
      rozFormQuestionsFormDate?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateEvaluatedRozFormMutation = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEvaluatedRozFormMutation = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEvaluatedRozFormMutation = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateFormQuestionMutation = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: {
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type UpdateFormQuestionMutation = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: {
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type DeleteFormQuestionMutation = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: {
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type CreateEditionMutation = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEditionMutation = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEditionMutation = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMailPreferenceMutation = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMailPreferenceMutation = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMailPreferenceMutation = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateDriverExclusionMutation = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDriverExclusionMutation = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDriverExclusionMutation = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateStatusMutation = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateStatusMutation = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteStatusMutation = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateArchiveMutation = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateArchiveMutation = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteArchiveMutation = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetCarrierQuery = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: {
    __typename: 'ModelVehicleConnection';
    items: Array<{
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  envasados?: {
    __typename: 'ModelEnvasadoConnection';
    items: Array<{
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cisternas?: {
    __typename: 'ModelCisternaConnection';
    items: Array<{
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tractos?: {
    __typename: 'ModelTractoConnection';
    items: Array<{
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tanques?: {
    __typename: 'ModelTanqueConnection';
    items: Array<{
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  semirremolques?: {
    __typename: 'ModelSemirremolqueConnection';
    items: Array<{
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  drivers?: {
    __typename: 'ModelDriverConnection';
    items: Array<{
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCarriersQuery = {
  __typename: 'ModelCarrierConnection';
  items: Array<{
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVehicleQuery = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type ListVehiclesQuery = {
  __typename: 'ModelVehicleConnection';
  items: Array<{
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type VehiclesByBusinessAndVehicleIdQuery = {
  __typename: 'ModelVehicleConnection';
  items: Array<{
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type VehiclesByCenterAndVehicleIdQuery = {
  __typename: 'ModelVehicleConnection';
  items: Array<{
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type VehiclesByCompanyAndVehicleIdQuery = {
  __typename: 'ModelVehicleConnection';
  items: Array<{
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEnvasadoQuery = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type ListEnvasadosQuery = {
  __typename: 'ModelEnvasadoConnection';
  items: Array<{
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type EnvasadosByBusinessAndEnvasadoIdQuery = {
  __typename: 'ModelEnvasadoConnection';
  items: Array<{
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type EnvasadosByCenterAndEnvasadoIdQuery = {
  __typename: 'ModelEnvasadoConnection';
  items: Array<{
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type EnvasadosByCompanyAndEnvasadoIdQuery = {
  __typename: 'ModelEnvasadoConnection';
  items: Array<{
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCisternaQuery = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type ListCisternasQuery = {
  __typename: 'ModelCisternaConnection';
  items: Array<{
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CisternasByBusinessAndCisternaIdQuery = {
  __typename: 'ModelCisternaConnection';
  items: Array<{
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CisternasByCenterAndCisternaIdQuery = {
  __typename: 'ModelCisternaConnection';
  items: Array<{
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CisternasByCompanyAndCisternaIdQuery = {
  __typename: 'ModelCisternaConnection';
  items: Array<{
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTractoQuery = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type ListTractosQuery = {
  __typename: 'ModelTractoConnection';
  items: Array<{
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type TractosByBusinessAndTractoIdQuery = {
  __typename: 'ModelTractoConnection';
  items: Array<{
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type TractosByCenterAndTractoIdQuery = {
  __typename: 'ModelTractoConnection';
  items: Array<{
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type TractosByCompanyAndTractoIdQuery = {
  __typename: 'ModelTractoConnection';
  items: Array<{
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTanqueQuery = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type ListTanquesQuery = {
  __typename: 'ModelTanqueConnection';
  items: Array<{
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type TanquesByBusinessAndTanqueIdQuery = {
  __typename: 'ModelTanqueConnection';
  items: Array<{
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type TanquesByCenterAndTanqueIdQuery = {
  __typename: 'ModelTanqueConnection';
  items: Array<{
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type TanquesByCompanyAndTanqueIdQuery = {
  __typename: 'ModelTanqueConnection';
  items: Array<{
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSemirremolqueQuery = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type ListSemirremolquesQuery = {
  __typename: 'ModelSemirremolqueConnection';
  items: Array<{
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SemirremolquesByBusinessAndSemirremolqueIdQuery = {
  __typename: 'ModelSemirremolqueConnection';
  items: Array<{
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SemirremolquesByCenterAndSemirremolqueIdQuery = {
  __typename: 'ModelSemirremolqueConnection';
  items: Array<{
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SemirremolquesByCompanyAndSemirremolqueIdQuery = {
  __typename: 'ModelSemirremolqueConnection';
  items: Array<{
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCoupleQuery = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  };
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type ListCouplesQuery = {
  __typename: 'ModelCoupleConnection';
  items: Array<{
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CouplesByBusinessAndCoupleIdQuery = {
  __typename: 'ModelCoupleConnection';
  items: Array<{
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CouplesByCenterAndCoupleIdQuery = {
  __typename: 'ModelCoupleConnection';
  items: Array<{
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CouplesByCompanyAndCoupleIdQuery = {
  __typename: 'ModelCoupleConnection';
  items: Array<{
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetDriverQuery = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  speedings?: {
    __typename: 'ModelSpeedingConnection';
    items: Array<{
      __typename: 'Speeding';
      speedingId: string;
      speedingDatetime: string;
      latitude?: string | null;
      longitude?: string | null;
      speedLimit: number;
      actualSpeed: number;
      counted?: boolean | null;
      driverId: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type ListDriversQuery = {
  __typename: 'ModelDriverConnection';
  items: Array<{
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type DriversByBusinessAndDriverIdQuery = {
  __typename: 'ModelDriverConnection';
  items: Array<{
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type DriversByCenterAndDriverIdQuery = {
  __typename: 'ModelDriverConnection';
  items: Array<{
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type DriversByCompanyAndDriverIdQuery = {
  __typename: 'ModelDriverConnection';
  items: Array<{
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetDocumentQuery = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: {
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null;
  envasado?: {
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null;
  cisterna?: {
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null;
  tracto?: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null;
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  driver?: {
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null;
  archive?: {
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type ListDocumentsQuery = {
  __typename: 'ModelDocumentConnection';
  items: Array<{
    __typename: 'Document';
    documentId: string;
    masterValueId: string;
    name: string;
    status: string;
    s3Path: string;
    issueDate: string;
    expirationDate: string;
    vehicle?: {
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null;
    envasado?: {
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null;
    cisterna?: {
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null;
    tracto?: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null;
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    driver?: {
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null;
    archive?: {
      __typename: 'Archive';
      archiveId: string;
      archivedAt: string;
      archivedBy: string;
      business: string;
      carrier: string;
      center: string;
      modelTypeName: string;
      motiveForArchiving: string;
      comment?: string | null;
      entity: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    vehicleDocumentsVehicleId?: string | null;
    envasadoDocumentsEnvasadoId?: string | null;
    cisternaDocumentsCisternaId?: string | null;
    tractoDocumentsTractoId?: string | null;
    tanqueDocumentsTanqueId?: string | null;
    semirremolqueDocumentsSemirremolqueId?: string | null;
    driverDocumentsDriverId?: string | null;
    archiveDocumentsArchiveId?: string | null;
    archiveDocumentsArchivedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetMasterQuery = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMastersQuery = {
  __typename: 'ModelMasterConnection';
  items: Array<{
    __typename: 'Master';
    businessId: string;
    masterId: string;
    masterGroup: string;
    valueId: string;
    valueToDisplay: string;
    sapId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetDocumentSpecsQuery = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListDocumentSpecsQuery = {
  __typename: 'ModelDocumentSpecsConnection';
  items: Array<{
    __typename: 'DocumentSpecs';
    businessId: string;
    documentSpecsId: string;
    isMandatory: boolean;
    canBlock: boolean;
    extensionDays: number;
    updatedBy?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: 'ModelUserConnection';
  items: Array<{
    __typename: 'User';
    userId: string;
    firstName: string;
    lastName: string;
    rut: string;
    phone: string;
    email: string;
    business: string;
    hasAccessTo?: string | null;
    authGroup: string;
    status: string;
    company: string;
    centers?: string | null;
    updatedBy?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UsersByBusinessAndUserIdQuery = {
  __typename: 'ModelUserConnection';
  items: Array<{
    __typename: 'User';
    userId: string;
    firstName: string;
    lastName: string;
    rut: string;
    phone: string;
    email: string;
    business: string;
    hasAccessTo?: string | null;
    authGroup: string;
    status: string;
    company: string;
    centers?: string | null;
    updatedBy?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UsersByCompanyAndUserIdQuery = {
  __typename: 'ModelUserConnection';
  items: Array<{
    __typename: 'User';
    userId: string;
    firstName: string;
    lastName: string;
    rut: string;
    phone: string;
    email: string;
    business: string;
    hasAccessTo?: string | null;
    authGroup: string;
    status: string;
    company: string;
    centers?: string | null;
    updatedBy?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetNotificationQuery = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListNotificationsQuery = {
  __typename: 'ModelNotificationConnection';
  items: Array<{
    __typename: 'Notification';
    businessId: string;
    notificationId: string;
    status: string;
    retries?: number | null;
    motive: string;
    model: string;
    vehicleId?: string | null;
    driverId?: string | null;
    envasadoId?: string | null;
    cisternaId?: string | null;
    tractoId?: string | null;
    tanqueId?: string | null;
    semirremolqueId?: string | null;
    userId?: string | null;
    comment?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetMailQuery = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMailQuery = {
  __typename: 'ModelMailConnection';
  items: Array<{
    __typename: 'Mail';
    businessId: string;
    mailId: string;
    receivers: string;
    title: string;
    content: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSpeedingQuery = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSpeedingsQuery = {
  __typename: 'ModelSpeedingConnection';
  items: Array<{
    __typename: 'Speeding';
    speedingId: string;
    speedingDatetime: string;
    latitude?: string | null;
    longitude?: string | null;
    speedLimit: number;
    actualSpeed: number;
    counted?: boolean | null;
    driverId: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SpeedingsByDriverIdAndSpeedingDatetimeQuery = {
  __typename: 'ModelSpeedingConnection';
  items: Array<{
    __typename: 'Speeding';
    speedingId: string;
    speedingDatetime: string;
    latitude?: string | null;
    longitude?: string | null;
    speedLimit: number;
    actualSpeed: number;
    counted?: boolean | null;
    driverId: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetFormQuestionTemplateQuery = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListFormQuestionTemplatesQuery = {
  __typename: 'ModelFormQuestionTemplateConnection';
  items: Array<{
    __typename: 'FormQuestionTemplate';
    formTemplateId: string;
    formQuestionTemplateId: string;
    question: string;
    criticality?: string | null;
    weight?: number | null;
    order?: number | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRozFormQuery = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: {
    __typename: 'ModelFormQuestionConnection';
    items: Array<{
      __typename: 'FormQuestion';
      formQuestionId: string;
      question: string;
      criticality?: string | null;
      weight?: number | null;
      order?: number | null;
      answer?: string | null;
      comment?: string | null;
      imageS3Path?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      rozFormQuestionsFormId?: string | null;
      rozFormQuestionsFormDate?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListRozFormsQuery = {
  __typename: 'ModelRozFormConnection';
  items: Array<{
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetEvaluatedRozFormQuery = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListEvaluatedRozFormsQuery = {
  __typename: 'ModelEvaluatedRozFormConnection';
  items: Array<{
    __typename: 'EvaluatedRozForm';
    formId: string;
    evaluatedAt: string;
    business?: string | null;
    subBusiness?: string | null;
    carrier?: string | null;
    center?: string | null;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions: Array<string | null>;
    score: number;
    s3Path: string;
    sapId?: string | null;
    year?: number | null;
    type?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EvaluatedRozFormsByBusinessAndEvaluatedAtQuery = {
  __typename: 'ModelEvaluatedRozFormConnection';
  items: Array<{
    __typename: 'EvaluatedRozForm';
    formId: string;
    evaluatedAt: string;
    business?: string | null;
    subBusiness?: string | null;
    carrier?: string | null;
    center?: string | null;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions: Array<string | null>;
    score: number;
    s3Path: string;
    sapId?: string | null;
    year?: number | null;
    type?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EvaluatedRozFormsByCarrierAndEvaluatedAtQuery = {
  __typename: 'ModelEvaluatedRozFormConnection';
  items: Array<{
    __typename: 'EvaluatedRozForm';
    formId: string;
    evaluatedAt: string;
    business?: string | null;
    subBusiness?: string | null;
    carrier?: string | null;
    center?: string | null;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions: Array<string | null>;
    score: number;
    s3Path: string;
    sapId?: string | null;
    year?: number | null;
    type?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EvaluatedRozFormsByCenterAndEvaluatedAtQuery = {
  __typename: 'ModelEvaluatedRozFormConnection';
  items: Array<{
    __typename: 'EvaluatedRozForm';
    formId: string;
    evaluatedAt: string;
    business?: string | null;
    subBusiness?: string | null;
    carrier?: string | null;
    center?: string | null;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions: Array<string | null>;
    score: number;
    s3Path: string;
    sapId?: string | null;
    year?: number | null;
    type?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetFormQuestionQuery = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: {
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type ListFormQuestionsQuery = {
  __typename: 'ModelFormQuestionConnection';
  items: Array<{
    __typename: 'FormQuestion';
    formQuestionId: string;
    question: string;
    criticality?: string | null;
    weight?: number | null;
    order?: number | null;
    answer?: string | null;
    comment?: string | null;
    imageS3Path?: string | null;
    form: {
      __typename: 'RozForm';
      formId: string;
      formDate: string;
      status: string;
      comment?: string | null;
      createdBy: string;
      updatedBy: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    rozFormQuestionsFormId?: string | null;
    rozFormQuestionsFormDate?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEditionQuery = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListEditionsQuery = {
  __typename: 'ModelEditionConnection';
  items: Array<{
    __typename: 'Edition';
    editionId: string;
    active: boolean;
    editor?: string | null;
    editorId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetMailPreferenceQuery = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMailPreferencesQuery = {
  __typename: 'ModelMailPreferenceConnection';
  items: Array<{
    __typename: 'MailPreference';
    userId: string;
    mailId: string;
    name: string;
    receive: boolean;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetDriverExclusionQuery = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListDriverExclusionsQuery = {
  __typename: 'ModelDriverExclusionConnection';
  items: Array<{
    __typename: 'DriverExclusion';
    driverId: string;
    startDate: string;
    endDate: string;
    exclusionMotives: Array<string | null>;
    comment?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetStatusQuery = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListStatusesQuery = {
  __typename: 'ModelStatusConnection';
  items: Array<{
    __typename: 'Status';
    statusId: string;
    status: string;
    startedAt: string;
    endedAt?: string | null;
    business: string;
    subBusiness?: string | null;
    carrier: string;
    center: string;
    modelTypeName: string;
    type?: string | null;
    entityId: string;
    sapId: string;
    coupleId?: string | null;
    blockingMotives?: Array<string | null> | null;
    aboutToExpireDocuments?: Array<string | null> | null;
    expiredDocuments?: Array<string | null> | null;
    missingDocuments?: Array<string | null> | null;
    comment?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StatusesByBusinessAndStartedAtQuery = {
  __typename: 'ModelStatusConnection';
  items: Array<{
    __typename: 'Status';
    statusId: string;
    status: string;
    startedAt: string;
    endedAt?: string | null;
    business: string;
    subBusiness?: string | null;
    carrier: string;
    center: string;
    modelTypeName: string;
    type?: string | null;
    entityId: string;
    sapId: string;
    coupleId?: string | null;
    blockingMotives?: Array<string | null> | null;
    aboutToExpireDocuments?: Array<string | null> | null;
    expiredDocuments?: Array<string | null> | null;
    missingDocuments?: Array<string | null> | null;
    comment?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StatusesByCarrierAndStartedAtQuery = {
  __typename: 'ModelStatusConnection';
  items: Array<{
    __typename: 'Status';
    statusId: string;
    status: string;
    startedAt: string;
    endedAt?: string | null;
    business: string;
    subBusiness?: string | null;
    carrier: string;
    center: string;
    modelTypeName: string;
    type?: string | null;
    entityId: string;
    sapId: string;
    coupleId?: string | null;
    blockingMotives?: Array<string | null> | null;
    aboutToExpireDocuments?: Array<string | null> | null;
    expiredDocuments?: Array<string | null> | null;
    missingDocuments?: Array<string | null> | null;
    comment?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StatusesByCenterAndStartedAtQuery = {
  __typename: 'ModelStatusConnection';
  items: Array<{
    __typename: 'Status';
    statusId: string;
    status: string;
    startedAt: string;
    endedAt?: string | null;
    business: string;
    subBusiness?: string | null;
    carrier: string;
    center: string;
    modelTypeName: string;
    type?: string | null;
    entityId: string;
    sapId: string;
    coupleId?: string | null;
    blockingMotives?: Array<string | null> | null;
    aboutToExpireDocuments?: Array<string | null> | null;
    expiredDocuments?: Array<string | null> | null;
    missingDocuments?: Array<string | null> | null;
    comment?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StatusesByEntityIdAndStartedAtQuery = {
  __typename: 'ModelStatusConnection';
  items: Array<{
    __typename: 'Status';
    statusId: string;
    status: string;
    startedAt: string;
    endedAt?: string | null;
    business: string;
    subBusiness?: string | null;
    carrier: string;
    center: string;
    modelTypeName: string;
    type?: string | null;
    entityId: string;
    sapId: string;
    coupleId?: string | null;
    blockingMotives?: Array<string | null> | null;
    aboutToExpireDocuments?: Array<string | null> | null;
    expiredDocuments?: Array<string | null> | null;
    missingDocuments?: Array<string | null> | null;
    comment?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetArchiveQuery = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListArchivesQuery = {
  __typename: 'ModelArchiveConnection';
  items: Array<{
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ArchivesByBusinessAndArchivedAtQuery = {
  __typename: 'ModelArchiveConnection';
  items: Array<{
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ArchivesByCarrierAndArchivedAtQuery = {
  __typename: 'ModelArchiveConnection';
  items: Array<{
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ArchivesByCenterAndArchivedAtQuery = {
  __typename: 'ModelArchiveConnection';
  items: Array<{
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateCarrierSubscription = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: {
    __typename: 'ModelVehicleConnection';
    items: Array<{
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  envasados?: {
    __typename: 'ModelEnvasadoConnection';
    items: Array<{
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cisternas?: {
    __typename: 'ModelCisternaConnection';
    items: Array<{
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tractos?: {
    __typename: 'ModelTractoConnection';
    items: Array<{
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tanques?: {
    __typename: 'ModelTanqueConnection';
    items: Array<{
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  semirremolques?: {
    __typename: 'ModelSemirremolqueConnection';
    items: Array<{
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  drivers?: {
    __typename: 'ModelDriverConnection';
    items: Array<{
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCarrierSubscription = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: {
    __typename: 'ModelVehicleConnection';
    items: Array<{
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  envasados?: {
    __typename: 'ModelEnvasadoConnection';
    items: Array<{
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cisternas?: {
    __typename: 'ModelCisternaConnection';
    items: Array<{
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tractos?: {
    __typename: 'ModelTractoConnection';
    items: Array<{
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tanques?: {
    __typename: 'ModelTanqueConnection';
    items: Array<{
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  semirremolques?: {
    __typename: 'ModelSemirremolqueConnection';
    items: Array<{
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  drivers?: {
    __typename: 'ModelDriverConnection';
    items: Array<{
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCarrierSubscription = {
  __typename: 'Carrier';
  carrierId: string;
  name: string;
  rut: string;
  email: string;
  additionalEmails?: Array<string | null> | null;
  vehicles?: {
    __typename: 'ModelVehicleConnection';
    items: Array<{
      __typename: 'Vehicle';
      vehicleId: string;
      brand: string;
      licensePlate: string;
      color: string;
      model: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      imei: string;
      cameraImei?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      isSpot?: boolean | null;
      sapId?: string | null;
      trailerLicensePlate?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierVehiclesCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  envasados?: {
    __typename: 'ModelEnvasadoConnection';
    items: Array<{
      __typename: 'Envasado';
      envasadoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      engineType?: string | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bodyType?: string | null;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierEnvasadosCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cisternas?: {
    __typename: 'ModelCisternaConnection';
    items: Array<{
      __typename: 'Cisterna';
      cisternaId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierCisternasCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tractos?: {
    __typename: 'ModelTractoConnection';
    items: Array<{
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  tanques?: {
    __typename: 'ModelTanqueConnection';
    items: Array<{
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  semirremolques?: {
    __typename: 'ModelSemirremolqueConnection';
    items: Array<{
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  drivers?: {
    __typename: 'ModelDriverConnection';
    items: Array<{
      __typename: 'Driver';
      driverId: string;
      firstName: string;
      lastName: string;
      rut: string;
      birthDate?: string | null;
      phone: string;
      email: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      blockingMotives?: Array<string | null> | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      sapId?: string | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierDriversCarrierId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVehicleSubscription = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type OnUpdateVehicleSubscription = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type OnDeleteVehicleSubscription = {
  __typename: 'Vehicle';
  vehicleId: string;
  brand: string;
  licensePlate: string;
  color: string;
  model: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  imei: string;
  cameraImei?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  isSpot?: boolean | null;
  sapId?: string | null;
  trailerLicensePlate?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierVehiclesCarrierId?: string | null;
};

export type OnCreateEnvasadoSubscription = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type OnUpdateEnvasadoSubscription = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type OnDeleteEnvasadoSubscription = {
  __typename: 'Envasado';
  envasadoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  engineType?: string | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bodyType?: string | null;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierEnvasadosCarrierId?: string | null;
};

export type OnCreateCisternaSubscription = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type OnUpdateCisternaSubscription = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type OnDeleteCisternaSubscription = {
  __typename: 'Cisterna';
  cisternaId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierCisternasCarrierId?: string | null;
};

export type OnCreateTractoSubscription = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type OnUpdateTractoSubscription = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type OnDeleteTractoSubscription = {
  __typename: 'Tracto';
  tractoId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  engineType?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTractosCarrierId?: string | null;
  tractoCoupleCoupleId?: string | null;
};

export type OnCreateTanqueSubscription = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type OnUpdateTanqueSubscription = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type OnDeleteTanqueSubscription = {
  __typename: 'Tanque';
  tanqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  bulkheadType?: string | null;
  hasPump?: boolean | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierTanquesCarrierId?: string | null;
  tanqueCoupleCoupleId?: string | null;
};

export type OnCreateSemirremolqueSubscription = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type OnUpdateSemirremolqueSubscription = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type OnDeleteSemirremolqueSubscription = {
  __typename: 'Semirremolque';
  semirremolqueId: string;
  sapId?: string | null;
  brand: string;
  licensePlate: string;
  model: string;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  color: string;
  year: number;
  type: string;
  capacity: string;
  maximumPalletCapacity?: number | null;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  business: string;
  subBusiness: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  gpsImei: string;
  cameraImei: string;
  blockingMotives?: Array<string | null> | null;
  forMining?: boolean | null;
  hasRanchAccreditation?: boolean | null;
  isSpot?: boolean | null;
  rampType?: string | null;
  MOPCode?: string | null;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  couple?: {
    __typename: 'Couple';
    coupleId: string;
    business: string;
    center: string;
    comment?: string | null;
    company: string;
    status: string;
    tracto: {
      __typename: 'Tracto';
      tractoId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      business: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      engineType?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTractosCarrierId?: string | null;
      tractoCoupleCoupleId?: string | null;
    };
    tanque?: {
      __typename: 'Tanque';
      tanqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      bulkheadType?: string | null;
      hasPump?: boolean | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierTanquesCarrierId?: string | null;
      tanqueCoupleCoupleId?: string | null;
    } | null;
    semirremolque?: {
      __typename: 'Semirremolque';
      semirremolqueId: string;
      sapId?: string | null;
      brand: string;
      licensePlate: string;
      model: string;
      status: string;
      lastStatusInformed?: string | null;
      lastStatusInformedAt?: string | null;
      color: string;
      year: number;
      type: string;
      capacity: string;
      maximumPalletCapacity?: number | null;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      business: string;
      subBusiness: string;
      center: string;
      originalCenter?: string | null;
      comment?: string | null;
      gpsImei: string;
      cameraImei: string;
      blockingMotives?: Array<string | null> | null;
      forMining?: boolean | null;
      hasRanchAccreditation?: boolean | null;
      isSpot?: boolean | null;
      rampType?: string | null;
      MOPCode?: string | null;
      documentsToExpire?: number | null;
      documentsExpired?: number | null;
      company?: string | null;
      updatedBy?: string | null;
      archiveId?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      carrierSemirremolquesCarrierId?: string | null;
      semirremolqueCoupleCoupleId?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    coupleTractoTractoId: string;
    coupleTanqueTanqueId?: string | null;
    coupleSemirremolqueSemirremolqueId?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierSemirremolquesCarrierId?: string | null;
  semirremolqueCoupleCoupleId?: string | null;
};

export type OnCreateCoupleSubscription = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  };
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type OnUpdateCoupleSubscription = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  };
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type OnDeleteCoupleSubscription = {
  __typename: 'Couple';
  coupleId: string;
  business: string;
  center: string;
  comment?: string | null;
  company: string;
  status: string;
  tracto: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  };
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  coupleTractoTractoId: string;
  coupleTanqueTanqueId?: string | null;
  coupleSemirremolqueSemirremolqueId?: string | null;
};

export type OnCreateDriverSubscription = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  speedings?: {
    __typename: 'ModelSpeedingConnection';
    items: Array<{
      __typename: 'Speeding';
      speedingId: string;
      speedingDatetime: string;
      latitude?: string | null;
      longitude?: string | null;
      speedLimit: number;
      actualSpeed: number;
      counted?: boolean | null;
      driverId: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type OnUpdateDriverSubscription = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  speedings?: {
    __typename: 'ModelSpeedingConnection';
    items: Array<{
      __typename: 'Speeding';
      speedingId: string;
      speedingDatetime: string;
      latitude?: string | null;
      longitude?: string | null;
      speedLimit: number;
      actualSpeed: number;
      counted?: boolean | null;
      driverId: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type OnDeleteDriverSubscription = {
  __typename: 'Driver';
  driverId: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthDate?: string | null;
  phone: string;
  email: string;
  business: string;
  center: string;
  originalCenter?: string | null;
  comment?: string | null;
  blockingMotives?: Array<string | null> | null;
  documentsToExpire?: number | null;
  documentsExpired?: number | null;
  status: string;
  lastStatusInformed?: string | null;
  lastStatusInformedAt?: string | null;
  sapId?: string | null;
  company?: string | null;
  carrier: {
    __typename: 'Carrier';
    carrierId: string;
    name: string;
    rut: string;
    email: string;
    additionalEmails?: Array<string | null> | null;
    vehicles?: {
      __typename: 'ModelVehicleConnection';
      nextToken?: string | null;
    } | null;
    envasados?: {
      __typename: 'ModelEnvasadoConnection';
      nextToken?: string | null;
    } | null;
    cisternas?: {
      __typename: 'ModelCisternaConnection';
      nextToken?: string | null;
    } | null;
    tractos?: {
      __typename: 'ModelTractoConnection';
      nextToken?: string | null;
    } | null;
    tanques?: {
      __typename: 'ModelTanqueConnection';
      nextToken?: string | null;
    } | null;
    semirremolques?: {
      __typename: 'ModelSemirremolqueConnection';
      nextToken?: string | null;
    } | null;
    drivers?: {
      __typename: 'ModelDriverConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  speedings?: {
    __typename: 'ModelSpeedingConnection';
    items: Array<{
      __typename: 'Speeding';
      speedingId: string;
      speedingDatetime: string;
      latitude?: string | null;
      longitude?: string | null;
      speedLimit: number;
      actualSpeed: number;
      counted?: boolean | null;
      driverId: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedBy?: string | null;
  archiveId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  carrierDriversCarrierId?: string | null;
};

export type OnCreateDocumentSubscription = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: {
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null;
  envasado?: {
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null;
  cisterna?: {
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null;
  tracto?: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null;
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  driver?: {
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null;
  archive?: {
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type OnUpdateDocumentSubscription = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: {
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null;
  envasado?: {
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null;
  cisterna?: {
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null;
  tracto?: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null;
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  driver?: {
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null;
  archive?: {
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type OnDeleteDocumentSubscription = {
  __typename: 'Document';
  documentId: string;
  masterValueId: string;
  name: string;
  status: string;
  s3Path: string;
  issueDate: string;
  expirationDate: string;
  vehicle?: {
    __typename: 'Vehicle';
    vehicleId: string;
    brand: string;
    licensePlate: string;
    color: string;
    model: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    imei: string;
    cameraImei?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    isSpot?: boolean | null;
    sapId?: string | null;
    trailerLicensePlate?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierVehiclesCarrierId?: string | null;
  } | null;
  envasado?: {
    __typename: 'Envasado';
    envasadoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    engineType?: string | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bodyType?: string | null;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierEnvasadosCarrierId?: string | null;
  } | null;
  cisterna?: {
    __typename: 'Cisterna';
    cisternaId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierCisternasCarrierId?: string | null;
  } | null;
  tracto?: {
    __typename: 'Tracto';
    tractoId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    engineType?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTractosCarrierId?: string | null;
    tractoCoupleCoupleId?: string | null;
  } | null;
  tanque?: {
    __typename: 'Tanque';
    tanqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    bulkheadType?: string | null;
    hasPump?: boolean | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierTanquesCarrierId?: string | null;
    tanqueCoupleCoupleId?: string | null;
  } | null;
  semirremolque?: {
    __typename: 'Semirremolque';
    semirremolqueId: string;
    sapId?: string | null;
    brand: string;
    licensePlate: string;
    model: string;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    color: string;
    year: number;
    type: string;
    capacity: string;
    maximumPalletCapacity?: number | null;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    business: string;
    subBusiness: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    gpsImei: string;
    cameraImei: string;
    blockingMotives?: Array<string | null> | null;
    forMining?: boolean | null;
    hasRanchAccreditation?: boolean | null;
    isSpot?: boolean | null;
    rampType?: string | null;
    MOPCode?: string | null;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    couple?: {
      __typename: 'Couple';
      coupleId: string;
      business: string;
      center: string;
      comment?: string | null;
      company: string;
      status: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      coupleTractoTractoId: string;
      coupleTanqueTanqueId?: string | null;
      coupleSemirremolqueSemirremolqueId?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierSemirremolquesCarrierId?: string | null;
    semirremolqueCoupleCoupleId?: string | null;
  } | null;
  driver?: {
    __typename: 'Driver';
    driverId: string;
    firstName: string;
    lastName: string;
    rut: string;
    birthDate?: string | null;
    phone: string;
    email: string;
    business: string;
    center: string;
    originalCenter?: string | null;
    comment?: string | null;
    blockingMotives?: Array<string | null> | null;
    documentsToExpire?: number | null;
    documentsExpired?: number | null;
    status: string;
    lastStatusInformed?: string | null;
    lastStatusInformedAt?: string | null;
    sapId?: string | null;
    company?: string | null;
    carrier: {
      __typename: 'Carrier';
      carrierId: string;
      name: string;
      rut: string;
      email: string;
      additionalEmails?: Array<string | null> | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    speedings?: {
      __typename: 'ModelSpeedingConnection';
      nextToken?: string | null;
    } | null;
    updatedBy?: string | null;
    archiveId?: string | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
    carrierDriversCarrierId?: string | null;
  } | null;
  archive?: {
    __typename: 'Archive';
    archiveId: string;
    archivedAt: string;
    archivedBy: string;
    business: string;
    carrier: string;
    center: string;
    modelTypeName: string;
    motiveForArchiving: string;
    documents?: {
      __typename: 'ModelDocumentConnection';
      nextToken?: string | null;
    } | null;
    comment?: string | null;
    entity: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  vehicleDocumentsVehicleId?: string | null;
  envasadoDocumentsEnvasadoId?: string | null;
  cisternaDocumentsCisternaId?: string | null;
  tractoDocumentsTractoId?: string | null;
  tanqueDocumentsTanqueId?: string | null;
  semirremolqueDocumentsSemirremolqueId?: string | null;
  driverDocumentsDriverId?: string | null;
  archiveDocumentsArchiveId?: string | null;
  archiveDocumentsArchivedAt?: string | null;
};

export type OnCreateMasterSubscription = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMasterSubscription = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMasterSubscription = {
  __typename: 'Master';
  businessId: string;
  masterId: string;
  masterGroup: string;
  valueId: string;
  valueToDisplay: string;
  sapId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateDocumentSpecsSubscription = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateDocumentSpecsSubscription = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteDocumentSpecsSubscription = {
  __typename: 'DocumentSpecs';
  businessId: string;
  documentSpecsId: string;
  isMandatory: boolean;
  canBlock: boolean;
  extensionDays: number;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: 'User';
  userId: string;
  firstName: string;
  lastName: string;
  rut: string;
  phone: string;
  email: string;
  business: string;
  hasAccessTo?: string | null;
  authGroup: string;
  status: string;
  company: string;
  centers?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateNotificationSubscription = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateNotificationSubscription = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteNotificationSubscription = {
  __typename: 'Notification';
  businessId: string;
  notificationId: string;
  status: string;
  retries?: number | null;
  motive: string;
  model: string;
  vehicleId?: string | null;
  driverId?: string | null;
  envasadoId?: string | null;
  cisternaId?: string | null;
  tractoId?: string | null;
  tanqueId?: string | null;
  semirremolqueId?: string | null;
  userId?: string | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMailSubscription = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMailSubscription = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMailSubscription = {
  __typename: 'Mail';
  businessId: string;
  mailId: string;
  receivers: string;
  title: string;
  content: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSpeedingSubscription = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSpeedingSubscription = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSpeedingSubscription = {
  __typename: 'Speeding';
  speedingId: string;
  speedingDatetime: string;
  latitude?: string | null;
  longitude?: string | null;
  speedLimit: number;
  actualSpeed: number;
  counted?: boolean | null;
  driverId: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateFormQuestionTemplateSubscription = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateFormQuestionTemplateSubscription = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteFormQuestionTemplateSubscription = {
  __typename: 'FormQuestionTemplate';
  formTemplateId: string;
  formQuestionTemplateId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRozFormSubscription = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: {
    __typename: 'ModelFormQuestionConnection';
    items: Array<{
      __typename: 'FormQuestion';
      formQuestionId: string;
      question: string;
      criticality?: string | null;
      weight?: number | null;
      order?: number | null;
      answer?: string | null;
      comment?: string | null;
      imageS3Path?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      rozFormQuestionsFormId?: string | null;
      rozFormQuestionsFormDate?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRozFormSubscription = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: {
    __typename: 'ModelFormQuestionConnection';
    items: Array<{
      __typename: 'FormQuestion';
      formQuestionId: string;
      question: string;
      criticality?: string | null;
      weight?: number | null;
      order?: number | null;
      answer?: string | null;
      comment?: string | null;
      imageS3Path?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      rozFormQuestionsFormId?: string | null;
      rozFormQuestionsFormDate?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRozFormSubscription = {
  __typename: 'RozForm';
  formId: string;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions?: {
    __typename: 'ModelFormQuestionConnection';
    items: Array<{
      __typename: 'FormQuestion';
      formQuestionId: string;
      question: string;
      criticality?: string | null;
      weight?: number | null;
      order?: number | null;
      answer?: string | null;
      comment?: string | null;
      imageS3Path?: string | null;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      rozFormQuestionsFormId?: string | null;
      rozFormQuestionsFormDate?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEvaluatedRozFormSubscription = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEvaluatedRozFormSubscription = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEvaluatedRozFormSubscription = {
  __typename: 'EvaluatedRozForm';
  formId: string;
  evaluatedAt: string;
  business?: string | null;
  subBusiness?: string | null;
  carrier?: string | null;
  center?: string | null;
  formDate: string;
  status: string;
  comment?: string | null;
  createdBy: string;
  updatedBy: string;
  questions: Array<string | null>;
  score: number;
  s3Path: string;
  sapId?: string | null;
  year?: number | null;
  type?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateFormQuestionSubscription = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: {
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type OnUpdateFormQuestionSubscription = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: {
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type OnDeleteFormQuestionSubscription = {
  __typename: 'FormQuestion';
  formQuestionId: string;
  question: string;
  criticality?: string | null;
  weight?: number | null;
  order?: number | null;
  answer?: string | null;
  comment?: string | null;
  imageS3Path?: string | null;
  form: {
    __typename: 'RozForm';
    formId: string;
    formDate: string;
    status: string;
    comment?: string | null;
    createdBy: string;
    updatedBy: string;
    questions?: {
      __typename: 'ModelFormQuestionConnection';
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
  rozFormQuestionsFormId?: string | null;
  rozFormQuestionsFormDate?: string | null;
};

export type OnCreateEditionSubscription = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEditionSubscription = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEditionSubscription = {
  __typename: 'Edition';
  editionId: string;
  active: boolean;
  editor?: string | null;
  editorId?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMailPreferenceSubscription = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMailPreferenceSubscription = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMailPreferenceSubscription = {
  __typename: 'MailPreference';
  userId: string;
  mailId: string;
  name: string;
  receive: boolean;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateDriverExclusionSubscription = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateDriverExclusionSubscription = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteDriverExclusionSubscription = {
  __typename: 'DriverExclusion';
  driverId: string;
  startDate: string;
  endDate: string;
  exclusionMotives: Array<string | null>;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateStatusSubscription = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateStatusSubscription = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteStatusSubscription = {
  __typename: 'Status';
  statusId: string;
  status: string;
  startedAt: string;
  endedAt?: string | null;
  business: string;
  subBusiness?: string | null;
  carrier: string;
  center: string;
  modelTypeName: string;
  type?: string | null;
  entityId: string;
  sapId: string;
  coupleId?: string | null;
  blockingMotives?: Array<string | null> | null;
  aboutToExpireDocuments?: Array<string | null> | null;
  expiredDocuments?: Array<string | null> | null;
  missingDocuments?: Array<string | null> | null;
  comment?: string | null;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateArchiveSubscription = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateArchiveSubscription = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteArchiveSubscription = {
  __typename: 'Archive';
  archiveId: string;
  archivedAt: string;
  archivedBy: string;
  business: string;
  carrier: string;
  center: string;
  modelTypeName: string;
  motiveForArchiving: string;
  documents?: {
    __typename: 'ModelDocumentConnection';
    items: Array<{
      __typename: 'Document';
      documentId: string;
      masterValueId: string;
      name: string;
      status: string;
      s3Path: string;
      issueDate: string;
      expirationDate: string;
      owner?: string | null;
      createdAt: string;
      updatedAt: string;
      vehicleDocumentsVehicleId?: string | null;
      envasadoDocumentsEnvasadoId?: string | null;
      cisternaDocumentsCisternaId?: string | null;
      tractoDocumentsTractoId?: string | null;
      tanqueDocumentsTanqueId?: string | null;
      semirremolqueDocumentsSemirremolqueId?: string | null;
      driverDocumentsDriverId?: string | null;
      archiveDocumentsArchiveId?: string | null;
      archiveDocumentsArchivedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  comment?: string | null;
  entity: string;
  owner?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: 'root',
})
export class APIService {
  async CreateCarrier(
    input: CreateCarrierInput,
    condition?: ModelCarrierConditionInput
  ): Promise<CreateCarrierMutation> {
    const statement = `mutation CreateCarrier($input: CreateCarrierInput!, $condition: ModelCarrierConditionInput) {
        createCarrier(input: $input, condition: $condition) {
          __typename
          carrierId
          name
          rut
          email
          additionalEmails
          vehicles {
            __typename
            items {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            nextToken
          }
          envasados {
            __typename
            items {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            nextToken
          }
          cisternas {
            __typename
            items {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            nextToken
          }
          tractos {
            __typename
            items {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            nextToken
          }
          tanques {
            __typename
            items {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            nextToken
          }
          semirremolques {
            __typename
            items {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            nextToken
          }
          drivers {
            __typename
            items {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCarrierMutation>response.data.createCarrier;
  }
  async UpdateCarrier(
    input: UpdateCarrierInput,
    condition?: ModelCarrierConditionInput
  ): Promise<UpdateCarrierMutation> {
    const statement = `mutation UpdateCarrier($input: UpdateCarrierInput!, $condition: ModelCarrierConditionInput) {
        updateCarrier(input: $input, condition: $condition) {
          __typename
          carrierId
          name
          rut
          email
          additionalEmails
          vehicles {
            __typename
            items {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            nextToken
          }
          envasados {
            __typename
            items {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            nextToken
          }
          cisternas {
            __typename
            items {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            nextToken
          }
          tractos {
            __typename
            items {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            nextToken
          }
          tanques {
            __typename
            items {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            nextToken
          }
          semirremolques {
            __typename
            items {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            nextToken
          }
          drivers {
            __typename
            items {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCarrierMutation>response.data.updateCarrier;
  }
  async DeleteCarrier(
    input: DeleteCarrierInput,
    condition?: ModelCarrierConditionInput
  ): Promise<DeleteCarrierMutation> {
    const statement = `mutation DeleteCarrier($input: DeleteCarrierInput!, $condition: ModelCarrierConditionInput) {
        deleteCarrier(input: $input, condition: $condition) {
          __typename
          carrierId
          name
          rut
          email
          additionalEmails
          vehicles {
            __typename
            items {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            nextToken
          }
          envasados {
            __typename
            items {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            nextToken
          }
          cisternas {
            __typename
            items {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            nextToken
          }
          tractos {
            __typename
            items {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            nextToken
          }
          tanques {
            __typename
            items {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            nextToken
          }
          semirremolques {
            __typename
            items {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            nextToken
          }
          drivers {
            __typename
            items {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCarrierMutation>response.data.deleteCarrier;
  }
  async CreateVehicle(
    input: CreateVehicleInput,
    condition?: ModelVehicleConditionInput
  ): Promise<CreateVehicleMutation> {
    const statement = `mutation CreateVehicle($input: CreateVehicleInput!, $condition: ModelVehicleConditionInput) {
        createVehicle(input: $input, condition: $condition) {
          __typename
          vehicleId
          brand
          licensePlate
          color
          model
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          imei
          cameraImei
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          isSpot
          sapId
          trailerLicensePlate
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierVehiclesCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVehicleMutation>response.data.createVehicle;
  }
  async UpdateVehicle(
    input: UpdateVehicleInput,
    condition?: ModelVehicleConditionInput
  ): Promise<UpdateVehicleMutation> {
    const statement = `mutation UpdateVehicle($input: UpdateVehicleInput!, $condition: ModelVehicleConditionInput) {
        updateVehicle(input: $input, condition: $condition) {
          __typename
          vehicleId
          brand
          licensePlate
          color
          model
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          imei
          cameraImei
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          isSpot
          sapId
          trailerLicensePlate
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierVehiclesCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVehicleMutation>response.data.updateVehicle;
  }
  async DeleteVehicle(
    input: DeleteVehicleInput,
    condition?: ModelVehicleConditionInput
  ): Promise<DeleteVehicleMutation> {
    const statement = `mutation DeleteVehicle($input: DeleteVehicleInput!, $condition: ModelVehicleConditionInput) {
        deleteVehicle(input: $input, condition: $condition) {
          __typename
          vehicleId
          brand
          licensePlate
          color
          model
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          imei
          cameraImei
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          isSpot
          sapId
          trailerLicensePlate
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierVehiclesCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVehicleMutation>response.data.deleteVehicle;
  }
  async CreateEnvasado(
    input: CreateEnvasadoInput,
    condition?: ModelEnvasadoConditionInput
  ): Promise<CreateEnvasadoMutation> {
    const statement = `mutation CreateEnvasado($input: CreateEnvasadoInput!, $condition: ModelEnvasadoConditionInput) {
        createEnvasado(input: $input, condition: $condition) {
          __typename
          envasadoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          engineType
          hasRanchAccreditation
          isSpot
          bodyType
          maximumPalletCapacity
          height
          width
          length
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierEnvasadosCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEnvasadoMutation>response.data.createEnvasado;
  }
  async UpdateEnvasado(
    input: UpdateEnvasadoInput,
    condition?: ModelEnvasadoConditionInput
  ): Promise<UpdateEnvasadoMutation> {
    const statement = `mutation UpdateEnvasado($input: UpdateEnvasadoInput!, $condition: ModelEnvasadoConditionInput) {
        updateEnvasado(input: $input, condition: $condition) {
          __typename
          envasadoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          engineType
          hasRanchAccreditation
          isSpot
          bodyType
          maximumPalletCapacity
          height
          width
          length
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierEnvasadosCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEnvasadoMutation>response.data.updateEnvasado;
  }
  async DeleteEnvasado(
    input: DeleteEnvasadoInput,
    condition?: ModelEnvasadoConditionInput
  ): Promise<DeleteEnvasadoMutation> {
    const statement = `mutation DeleteEnvasado($input: DeleteEnvasadoInput!, $condition: ModelEnvasadoConditionInput) {
        deleteEnvasado(input: $input, condition: $condition) {
          __typename
          envasadoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          engineType
          hasRanchAccreditation
          isSpot
          bodyType
          maximumPalletCapacity
          height
          width
          length
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierEnvasadosCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEnvasadoMutation>response.data.deleteEnvasado;
  }
  async CreateCisterna(
    input: CreateCisternaInput,
    condition?: ModelCisternaConditionInput
  ): Promise<CreateCisternaMutation> {
    const statement = `mutation CreateCisterna($input: CreateCisternaInput!, $condition: ModelCisternaConditionInput) {
        createCisterna(input: $input, condition: $condition) {
          __typename
          cisternaId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierCisternasCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCisternaMutation>response.data.createCisterna;
  }
  async UpdateCisterna(
    input: UpdateCisternaInput,
    condition?: ModelCisternaConditionInput
  ): Promise<UpdateCisternaMutation> {
    const statement = `mutation UpdateCisterna($input: UpdateCisternaInput!, $condition: ModelCisternaConditionInput) {
        updateCisterna(input: $input, condition: $condition) {
          __typename
          cisternaId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierCisternasCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCisternaMutation>response.data.updateCisterna;
  }
  async DeleteCisterna(
    input: DeleteCisternaInput,
    condition?: ModelCisternaConditionInput
  ): Promise<DeleteCisternaMutation> {
    const statement = `mutation DeleteCisterna($input: DeleteCisternaInput!, $condition: ModelCisternaConditionInput) {
        deleteCisterna(input: $input, condition: $condition) {
          __typename
          cisternaId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierCisternasCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCisternaMutation>response.data.deleteCisterna;
  }
  async CreateTracto(
    input: CreateTractoInput,
    condition?: ModelTractoConditionInput
  ): Promise<CreateTractoMutation> {
    const statement = `mutation CreateTracto($input: CreateTractoInput!, $condition: ModelTractoConditionInput) {
        createTracto(input: $input, condition: $condition) {
          __typename
          tractoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTractosCarrierId
          tractoCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTractoMutation>response.data.createTracto;
  }
  async UpdateTracto(
    input: UpdateTractoInput,
    condition?: ModelTractoConditionInput
  ): Promise<UpdateTractoMutation> {
    const statement = `mutation UpdateTracto($input: UpdateTractoInput!, $condition: ModelTractoConditionInput) {
        updateTracto(input: $input, condition: $condition) {
          __typename
          tractoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTractosCarrierId
          tractoCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTractoMutation>response.data.updateTracto;
  }
  async DeleteTracto(
    input: DeleteTractoInput,
    condition?: ModelTractoConditionInput
  ): Promise<DeleteTractoMutation> {
    const statement = `mutation DeleteTracto($input: DeleteTractoInput!, $condition: ModelTractoConditionInput) {
        deleteTracto(input: $input, condition: $condition) {
          __typename
          tractoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTractosCarrierId
          tractoCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTractoMutation>response.data.deleteTracto;
  }
  async CreateTanque(
    input: CreateTanqueInput,
    condition?: ModelTanqueConditionInput
  ): Promise<CreateTanqueMutation> {
    const statement = `mutation CreateTanque($input: CreateTanqueInput!, $condition: ModelTanqueConditionInput) {
        createTanque(input: $input, condition: $condition) {
          __typename
          tanqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTanquesCarrierId
          tanqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTanqueMutation>response.data.createTanque;
  }
  async UpdateTanque(
    input: UpdateTanqueInput,
    condition?: ModelTanqueConditionInput
  ): Promise<UpdateTanqueMutation> {
    const statement = `mutation UpdateTanque($input: UpdateTanqueInput!, $condition: ModelTanqueConditionInput) {
        updateTanque(input: $input, condition: $condition) {
          __typename
          tanqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTanquesCarrierId
          tanqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTanqueMutation>response.data.updateTanque;
  }
  async DeleteTanque(
    input: DeleteTanqueInput,
    condition?: ModelTanqueConditionInput
  ): Promise<DeleteTanqueMutation> {
    const statement = `mutation DeleteTanque($input: DeleteTanqueInput!, $condition: ModelTanqueConditionInput) {
        deleteTanque(input: $input, condition: $condition) {
          __typename
          tanqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTanquesCarrierId
          tanqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTanqueMutation>response.data.deleteTanque;
  }
  async CreateSemirremolque(
    input: CreateSemirremolqueInput,
    condition?: ModelSemirremolqueConditionInput
  ): Promise<CreateSemirremolqueMutation> {
    const statement = `mutation CreateSemirremolque($input: CreateSemirremolqueInput!, $condition: ModelSemirremolqueConditionInput) {
        createSemirremolque(input: $input, condition: $condition) {
          __typename
          semirremolqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          maximumPalletCapacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          rampType
          MOPCode
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierSemirremolquesCarrierId
          semirremolqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSemirremolqueMutation>response.data.createSemirremolque;
  }
  async UpdateSemirremolque(
    input: UpdateSemirremolqueInput,
    condition?: ModelSemirremolqueConditionInput
  ): Promise<UpdateSemirremolqueMutation> {
    const statement = `mutation UpdateSemirremolque($input: UpdateSemirremolqueInput!, $condition: ModelSemirremolqueConditionInput) {
        updateSemirremolque(input: $input, condition: $condition) {
          __typename
          semirremolqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          maximumPalletCapacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          rampType
          MOPCode
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierSemirremolquesCarrierId
          semirremolqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSemirremolqueMutation>response.data.updateSemirremolque;
  }
  async DeleteSemirremolque(
    input: DeleteSemirremolqueInput,
    condition?: ModelSemirremolqueConditionInput
  ): Promise<DeleteSemirremolqueMutation> {
    const statement = `mutation DeleteSemirremolque($input: DeleteSemirremolqueInput!, $condition: ModelSemirremolqueConditionInput) {
        deleteSemirremolque(input: $input, condition: $condition) {
          __typename
          semirremolqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          maximumPalletCapacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          rampType
          MOPCode
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierSemirremolquesCarrierId
          semirremolqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSemirremolqueMutation>response.data.deleteSemirremolque;
  }
  async CreateCouple(
    input: CreateCoupleInput,
    condition?: ModelCoupleConditionInput
  ): Promise<CreateCoupleMutation> {
    const statement = `mutation CreateCouple($input: CreateCoupleInput!, $condition: ModelCoupleConditionInput) {
        createCouple(input: $input, condition: $condition) {
          __typename
          coupleId
          business
          center
          comment
          company
          status
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          owner
          createdAt
          updatedAt
          coupleTractoTractoId
          coupleTanqueTanqueId
          coupleSemirremolqueSemirremolqueId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCoupleMutation>response.data.createCouple;
  }
  async UpdateCouple(
    input: UpdateCoupleInput,
    condition?: ModelCoupleConditionInput
  ): Promise<UpdateCoupleMutation> {
    const statement = `mutation UpdateCouple($input: UpdateCoupleInput!, $condition: ModelCoupleConditionInput) {
        updateCouple(input: $input, condition: $condition) {
          __typename
          coupleId
          business
          center
          comment
          company
          status
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          owner
          createdAt
          updatedAt
          coupleTractoTractoId
          coupleTanqueTanqueId
          coupleSemirremolqueSemirremolqueId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCoupleMutation>response.data.updateCouple;
  }
  async DeleteCouple(
    input: DeleteCoupleInput,
    condition?: ModelCoupleConditionInput
  ): Promise<DeleteCoupleMutation> {
    const statement = `mutation DeleteCouple($input: DeleteCoupleInput!, $condition: ModelCoupleConditionInput) {
        deleteCouple(input: $input, condition: $condition) {
          __typename
          coupleId
          business
          center
          comment
          company
          status
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          owner
          createdAt
          updatedAt
          coupleTractoTractoId
          coupleTanqueTanqueId
          coupleSemirremolqueSemirremolqueId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCoupleMutation>response.data.deleteCouple;
  }
  async CreateDriver(
    input: CreateDriverInput,
    condition?: ModelDriverConditionInput
  ): Promise<CreateDriverMutation> {
    const statement = `mutation CreateDriver($input: CreateDriverInput!, $condition: ModelDriverConditionInput) {
        createDriver(input: $input, condition: $condition) {
          __typename
          driverId
          firstName
          lastName
          rut
          birthDate
          phone
          email
          business
          center
          originalCenter
          comment
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          sapId
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          speedings {
            __typename
            items {
              __typename
              speedingId
              speedingDatetime
              latitude
              longitude
              speedLimit
              actualSpeed
              counted
              driverId
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierDriversCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDriverMutation>response.data.createDriver;
  }
  async UpdateDriver(
    input: UpdateDriverInput,
    condition?: ModelDriverConditionInput
  ): Promise<UpdateDriverMutation> {
    const statement = `mutation UpdateDriver($input: UpdateDriverInput!, $condition: ModelDriverConditionInput) {
        updateDriver(input: $input, condition: $condition) {
          __typename
          driverId
          firstName
          lastName
          rut
          birthDate
          phone
          email
          business
          center
          originalCenter
          comment
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          sapId
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          speedings {
            __typename
            items {
              __typename
              speedingId
              speedingDatetime
              latitude
              longitude
              speedLimit
              actualSpeed
              counted
              driverId
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierDriversCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDriverMutation>response.data.updateDriver;
  }
  async DeleteDriver(
    input: DeleteDriverInput,
    condition?: ModelDriverConditionInput
  ): Promise<DeleteDriverMutation> {
    const statement = `mutation DeleteDriver($input: DeleteDriverInput!, $condition: ModelDriverConditionInput) {
        deleteDriver(input: $input, condition: $condition) {
          __typename
          driverId
          firstName
          lastName
          rut
          birthDate
          phone
          email
          business
          center
          originalCenter
          comment
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          sapId
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          speedings {
            __typename
            items {
              __typename
              speedingId
              speedingDatetime
              latitude
              longitude
              speedLimit
              actualSpeed
              counted
              driverId
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierDriversCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDriverMutation>response.data.deleteDriver;
  }
  async CreateDocument(
    input: CreateDocumentInput,
    condition?: ModelDocumentConditionInput
  ): Promise<CreateDocumentMutation> {
    const statement = `mutation CreateDocument($input: CreateDocumentInput!, $condition: ModelDocumentConditionInput) {
        createDocument(input: $input, condition: $condition) {
          __typename
          documentId
          masterValueId
          name
          status
          s3Path
          issueDate
          expirationDate
          vehicle {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          envasado {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          cisterna {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          driver {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          archive {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          vehicleDocumentsVehicleId
          envasadoDocumentsEnvasadoId
          cisternaDocumentsCisternaId
          tractoDocumentsTractoId
          tanqueDocumentsTanqueId
          semirremolqueDocumentsSemirremolqueId
          driverDocumentsDriverId
          archiveDocumentsArchiveId
          archiveDocumentsArchivedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDocumentMutation>response.data.createDocument;
  }
  async UpdateDocument(
    input: UpdateDocumentInput,
    condition?: ModelDocumentConditionInput
  ): Promise<UpdateDocumentMutation> {
    const statement = `mutation UpdateDocument($input: UpdateDocumentInput!, $condition: ModelDocumentConditionInput) {
        updateDocument(input: $input, condition: $condition) {
          __typename
          documentId
          masterValueId
          name
          status
          s3Path
          issueDate
          expirationDate
          vehicle {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          envasado {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          cisterna {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          driver {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          archive {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          vehicleDocumentsVehicleId
          envasadoDocumentsEnvasadoId
          cisternaDocumentsCisternaId
          tractoDocumentsTractoId
          tanqueDocumentsTanqueId
          semirremolqueDocumentsSemirremolqueId
          driverDocumentsDriverId
          archiveDocumentsArchiveId
          archiveDocumentsArchivedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDocumentMutation>response.data.updateDocument;
  }
  async DeleteDocument(
    input: DeleteDocumentInput,
    condition?: ModelDocumentConditionInput
  ): Promise<DeleteDocumentMutation> {
    const statement = `mutation DeleteDocument($input: DeleteDocumentInput!, $condition: ModelDocumentConditionInput) {
        deleteDocument(input: $input, condition: $condition) {
          __typename
          documentId
          masterValueId
          name
          status
          s3Path
          issueDate
          expirationDate
          vehicle {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          envasado {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          cisterna {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          driver {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          archive {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          vehicleDocumentsVehicleId
          envasadoDocumentsEnvasadoId
          cisternaDocumentsCisternaId
          tractoDocumentsTractoId
          tanqueDocumentsTanqueId
          semirremolqueDocumentsSemirremolqueId
          driverDocumentsDriverId
          archiveDocumentsArchiveId
          archiveDocumentsArchivedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDocumentMutation>response.data.deleteDocument;
  }
  async CreateMaster(
    input: CreateMasterInput,
    condition?: ModelMasterConditionInput
  ): Promise<CreateMasterMutation> {
    const statement = `mutation CreateMaster($input: CreateMasterInput!, $condition: ModelMasterConditionInput) {
        createMaster(input: $input, condition: $condition) {
          __typename
          businessId
          masterId
          masterGroup
          valueId
          valueToDisplay
          sapId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMasterMutation>response.data.createMaster;
  }
  async UpdateMaster(
    input: UpdateMasterInput,
    condition?: ModelMasterConditionInput
  ): Promise<UpdateMasterMutation> {
    const statement = `mutation UpdateMaster($input: UpdateMasterInput!, $condition: ModelMasterConditionInput) {
        updateMaster(input: $input, condition: $condition) {
          __typename
          businessId
          masterId
          masterGroup
          valueId
          valueToDisplay
          sapId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMasterMutation>response.data.updateMaster;
  }
  async DeleteMaster(
    input: DeleteMasterInput,
    condition?: ModelMasterConditionInput
  ): Promise<DeleteMasterMutation> {
    const statement = `mutation DeleteMaster($input: DeleteMasterInput!, $condition: ModelMasterConditionInput) {
        deleteMaster(input: $input, condition: $condition) {
          __typename
          businessId
          masterId
          masterGroup
          valueId
          valueToDisplay
          sapId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMasterMutation>response.data.deleteMaster;
  }
  async CreateDocumentSpecs(
    input: CreateDocumentSpecsInput,
    condition?: ModelDocumentSpecsConditionInput
  ): Promise<CreateDocumentSpecsMutation> {
    const statement = `mutation CreateDocumentSpecs($input: CreateDocumentSpecsInput!, $condition: ModelDocumentSpecsConditionInput) {
        createDocumentSpecs(input: $input, condition: $condition) {
          __typename
          businessId
          documentSpecsId
          isMandatory
          canBlock
          extensionDays
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDocumentSpecsMutation>response.data.createDocumentSpecs;
  }
  async UpdateDocumentSpecs(
    input: UpdateDocumentSpecsInput,
    condition?: ModelDocumentSpecsConditionInput
  ): Promise<UpdateDocumentSpecsMutation> {
    const statement = `mutation UpdateDocumentSpecs($input: UpdateDocumentSpecsInput!, $condition: ModelDocumentSpecsConditionInput) {
        updateDocumentSpecs(input: $input, condition: $condition) {
          __typename
          businessId
          documentSpecsId
          isMandatory
          canBlock
          extensionDays
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDocumentSpecsMutation>response.data.updateDocumentSpecs;
  }
  async DeleteDocumentSpecs(
    input: DeleteDocumentSpecsInput,
    condition?: ModelDocumentSpecsConditionInput
  ): Promise<DeleteDocumentSpecsMutation> {
    const statement = `mutation DeleteDocumentSpecs($input: DeleteDocumentSpecsInput!, $condition: ModelDocumentSpecsConditionInput) {
        deleteDocumentSpecs(input: $input, condition: $condition) {
          __typename
          businessId
          documentSpecsId
          isMandatory
          canBlock
          extensionDays
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDocumentSpecsMutation>response.data.deleteDocumentSpecs;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          userId
          firstName
          lastName
          rut
          phone
          email
          business
          hasAccessTo
          authGroup
          status
          company
          centers
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          userId
          firstName
          lastName
          rut
          phone
          email
          business
          hasAccessTo
          authGroup
          status
          company
          centers
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          userId
          firstName
          lastName
          rut
          phone
          email
          business
          hasAccessTo
          authGroup
          status
          company
          centers
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateNotification(
    input: CreateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<CreateNotificationMutation> {
    const statement = `mutation CreateNotification($input: CreateNotificationInput!, $condition: ModelNotificationConditionInput) {
        createNotification(input: $input, condition: $condition) {
          __typename
          businessId
          notificationId
          status
          retries
          motive
          model
          vehicleId
          driverId
          envasadoId
          cisternaId
          tractoId
          tanqueId
          semirremolqueId
          userId
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNotificationMutation>response.data.createNotification;
  }
  async UpdateNotification(
    input: UpdateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<UpdateNotificationMutation> {
    const statement = `mutation UpdateNotification($input: UpdateNotificationInput!, $condition: ModelNotificationConditionInput) {
        updateNotification(input: $input, condition: $condition) {
          __typename
          businessId
          notificationId
          status
          retries
          motive
          model
          vehicleId
          driverId
          envasadoId
          cisternaId
          tractoId
          tanqueId
          semirremolqueId
          userId
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNotificationMutation>response.data.updateNotification;
  }
  async DeleteNotification(
    input: DeleteNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<DeleteNotificationMutation> {
    const statement = `mutation DeleteNotification($input: DeleteNotificationInput!, $condition: ModelNotificationConditionInput) {
        deleteNotification(input: $input, condition: $condition) {
          __typename
          businessId
          notificationId
          status
          retries
          motive
          model
          vehicleId
          driverId
          envasadoId
          cisternaId
          tractoId
          tanqueId
          semirremolqueId
          userId
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNotificationMutation>response.data.deleteNotification;
  }
  async CreateMail(
    input: CreateMailInput,
    condition?: ModelMailConditionInput
  ): Promise<CreateMailMutation> {
    const statement = `mutation CreateMail($input: CreateMailInput!, $condition: ModelMailConditionInput) {
        createMail(input: $input, condition: $condition) {
          __typename
          businessId
          mailId
          receivers
          title
          content
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMailMutation>response.data.createMail;
  }
  async UpdateMail(
    input: UpdateMailInput,
    condition?: ModelMailConditionInput
  ): Promise<UpdateMailMutation> {
    const statement = `mutation UpdateMail($input: UpdateMailInput!, $condition: ModelMailConditionInput) {
        updateMail(input: $input, condition: $condition) {
          __typename
          businessId
          mailId
          receivers
          title
          content
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMailMutation>response.data.updateMail;
  }
  async DeleteMail(
    input: DeleteMailInput,
    condition?: ModelMailConditionInput
  ): Promise<DeleteMailMutation> {
    const statement = `mutation DeleteMail($input: DeleteMailInput!, $condition: ModelMailConditionInput) {
        deleteMail(input: $input, condition: $condition) {
          __typename
          businessId
          mailId
          receivers
          title
          content
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMailMutation>response.data.deleteMail;
  }
  async CreateSpeeding(
    input: CreateSpeedingInput,
    condition?: ModelSpeedingConditionInput
  ): Promise<CreateSpeedingMutation> {
    const statement = `mutation CreateSpeeding($input: CreateSpeedingInput!, $condition: ModelSpeedingConditionInput) {
        createSpeeding(input: $input, condition: $condition) {
          __typename
          speedingId
          speedingDatetime
          latitude
          longitude
          speedLimit
          actualSpeed
          counted
          driverId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSpeedingMutation>response.data.createSpeeding;
  }
  async UpdateSpeeding(
    input: UpdateSpeedingInput,
    condition?: ModelSpeedingConditionInput
  ): Promise<UpdateSpeedingMutation> {
    const statement = `mutation UpdateSpeeding($input: UpdateSpeedingInput!, $condition: ModelSpeedingConditionInput) {
        updateSpeeding(input: $input, condition: $condition) {
          __typename
          speedingId
          speedingDatetime
          latitude
          longitude
          speedLimit
          actualSpeed
          counted
          driverId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSpeedingMutation>response.data.updateSpeeding;
  }
  async DeleteSpeeding(
    input: DeleteSpeedingInput,
    condition?: ModelSpeedingConditionInput
  ): Promise<DeleteSpeedingMutation> {
    const statement = `mutation DeleteSpeeding($input: DeleteSpeedingInput!, $condition: ModelSpeedingConditionInput) {
        deleteSpeeding(input: $input, condition: $condition) {
          __typename
          speedingId
          speedingDatetime
          latitude
          longitude
          speedLimit
          actualSpeed
          counted
          driverId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSpeedingMutation>response.data.deleteSpeeding;
  }
  async CreateFormQuestionTemplate(
    input: CreateFormQuestionTemplateInput,
    condition?: ModelFormQuestionTemplateConditionInput
  ): Promise<CreateFormQuestionTemplateMutation> {
    const statement = `mutation CreateFormQuestionTemplate($input: CreateFormQuestionTemplateInput!, $condition: ModelFormQuestionTemplateConditionInput) {
        createFormQuestionTemplate(input: $input, condition: $condition) {
          __typename
          formTemplateId
          formQuestionTemplateId
          question
          criticality
          weight
          order
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFormQuestionTemplateMutation>(
      response.data.createFormQuestionTemplate
    );
  }
  async UpdateFormQuestionTemplate(
    input: UpdateFormQuestionTemplateInput,
    condition?: ModelFormQuestionTemplateConditionInput
  ): Promise<UpdateFormQuestionTemplateMutation> {
    const statement = `mutation UpdateFormQuestionTemplate($input: UpdateFormQuestionTemplateInput!, $condition: ModelFormQuestionTemplateConditionInput) {
        updateFormQuestionTemplate(input: $input, condition: $condition) {
          __typename
          formTemplateId
          formQuestionTemplateId
          question
          criticality
          weight
          order
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFormQuestionTemplateMutation>(
      response.data.updateFormQuestionTemplate
    );
  }
  async DeleteFormQuestionTemplate(
    input: DeleteFormQuestionTemplateInput,
    condition?: ModelFormQuestionTemplateConditionInput
  ): Promise<DeleteFormQuestionTemplateMutation> {
    const statement = `mutation DeleteFormQuestionTemplate($input: DeleteFormQuestionTemplateInput!, $condition: ModelFormQuestionTemplateConditionInput) {
        deleteFormQuestionTemplate(input: $input, condition: $condition) {
          __typename
          formTemplateId
          formQuestionTemplateId
          question
          criticality
          weight
          order
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFormQuestionTemplateMutation>(
      response.data.deleteFormQuestionTemplate
    );
  }
  async CreateRozForm(
    input: CreateRozFormInput,
    condition?: ModelRozFormConditionInput
  ): Promise<CreateRozFormMutation> {
    const statement = `mutation CreateRozForm($input: CreateRozFormInput!, $condition: ModelRozFormConditionInput) {
        createRozForm(input: $input, condition: $condition) {
          __typename
          formId
          formDate
          status
          comment
          createdBy
          updatedBy
          questions {
            __typename
            items {
              __typename
              formQuestionId
              question
              criticality
              weight
              order
              answer
              comment
              imageS3Path
              owner
              createdAt
              updatedAt
              rozFormQuestionsFormId
              rozFormQuestionsFormDate
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRozFormMutation>response.data.createRozForm;
  }
  async UpdateRozForm(
    input: UpdateRozFormInput,
    condition?: ModelRozFormConditionInput
  ): Promise<UpdateRozFormMutation> {
    const statement = `mutation UpdateRozForm($input: UpdateRozFormInput!, $condition: ModelRozFormConditionInput) {
        updateRozForm(input: $input, condition: $condition) {
          __typename
          formId
          formDate
          status
          comment
          createdBy
          updatedBy
          questions {
            __typename
            items {
              __typename
              formQuestionId
              question
              criticality
              weight
              order
              answer
              comment
              imageS3Path
              owner
              createdAt
              updatedAt
              rozFormQuestionsFormId
              rozFormQuestionsFormDate
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRozFormMutation>response.data.updateRozForm;
  }
  async DeleteRozForm(
    input: DeleteRozFormInput,
    condition?: ModelRozFormConditionInput
  ): Promise<DeleteRozFormMutation> {
    const statement = `mutation DeleteRozForm($input: DeleteRozFormInput!, $condition: ModelRozFormConditionInput) {
        deleteRozForm(input: $input, condition: $condition) {
          __typename
          formId
          formDate
          status
          comment
          createdBy
          updatedBy
          questions {
            __typename
            items {
              __typename
              formQuestionId
              question
              criticality
              weight
              order
              answer
              comment
              imageS3Path
              owner
              createdAt
              updatedAt
              rozFormQuestionsFormId
              rozFormQuestionsFormDate
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRozFormMutation>response.data.deleteRozForm;
  }
  async CreateEvaluatedRozForm(
    input: CreateEvaluatedRozFormInput,
    condition?: ModelEvaluatedRozFormConditionInput
  ): Promise<CreateEvaluatedRozFormMutation> {
    const statement = `mutation CreateEvaluatedRozForm($input: CreateEvaluatedRozFormInput!, $condition: ModelEvaluatedRozFormConditionInput) {
        createEvaluatedRozForm(input: $input, condition: $condition) {
          __typename
          formId
          evaluatedAt
          business
          subBusiness
          carrier
          center
          formDate
          status
          comment
          createdBy
          updatedBy
          questions
          score
          s3Path
          sapId
          year
          type
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEvaluatedRozFormMutation>response.data.createEvaluatedRozForm;
  }
  async UpdateEvaluatedRozForm(
    input: UpdateEvaluatedRozFormInput,
    condition?: ModelEvaluatedRozFormConditionInput
  ): Promise<UpdateEvaluatedRozFormMutation> {
    const statement = `mutation UpdateEvaluatedRozForm($input: UpdateEvaluatedRozFormInput!, $condition: ModelEvaluatedRozFormConditionInput) {
        updateEvaluatedRozForm(input: $input, condition: $condition) {
          __typename
          formId
          evaluatedAt
          business
          subBusiness
          carrier
          center
          formDate
          status
          comment
          createdBy
          updatedBy
          questions
          score
          s3Path
          sapId
          year
          type
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEvaluatedRozFormMutation>response.data.updateEvaluatedRozForm;
  }
  async DeleteEvaluatedRozForm(
    input: DeleteEvaluatedRozFormInput,
    condition?: ModelEvaluatedRozFormConditionInput
  ): Promise<DeleteEvaluatedRozFormMutation> {
    const statement = `mutation DeleteEvaluatedRozForm($input: DeleteEvaluatedRozFormInput!, $condition: ModelEvaluatedRozFormConditionInput) {
        deleteEvaluatedRozForm(input: $input, condition: $condition) {
          __typename
          formId
          evaluatedAt
          business
          subBusiness
          carrier
          center
          formDate
          status
          comment
          createdBy
          updatedBy
          questions
          score
          s3Path
          sapId
          year
          type
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEvaluatedRozFormMutation>response.data.deleteEvaluatedRozForm;
  }
  async CreateFormQuestion(
    input: CreateFormQuestionInput,
    condition?: ModelFormQuestionConditionInput
  ): Promise<CreateFormQuestionMutation> {
    const statement = `mutation CreateFormQuestion($input: CreateFormQuestionInput!, $condition: ModelFormQuestionConditionInput) {
        createFormQuestion(input: $input, condition: $condition) {
          __typename
          formQuestionId
          question
          criticality
          weight
          order
          answer
          comment
          imageS3Path
          form {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          rozFormQuestionsFormId
          rozFormQuestionsFormDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFormQuestionMutation>response.data.createFormQuestion;
  }
  async UpdateFormQuestion(
    input: UpdateFormQuestionInput,
    condition?: ModelFormQuestionConditionInput
  ): Promise<UpdateFormQuestionMutation> {
    const statement = `mutation UpdateFormQuestion($input: UpdateFormQuestionInput!, $condition: ModelFormQuestionConditionInput) {
        updateFormQuestion(input: $input, condition: $condition) {
          __typename
          formQuestionId
          question
          criticality
          weight
          order
          answer
          comment
          imageS3Path
          form {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          rozFormQuestionsFormId
          rozFormQuestionsFormDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFormQuestionMutation>response.data.updateFormQuestion;
  }
  async DeleteFormQuestion(
    input: DeleteFormQuestionInput,
    condition?: ModelFormQuestionConditionInput
  ): Promise<DeleteFormQuestionMutation> {
    const statement = `mutation DeleteFormQuestion($input: DeleteFormQuestionInput!, $condition: ModelFormQuestionConditionInput) {
        deleteFormQuestion(input: $input, condition: $condition) {
          __typename
          formQuestionId
          question
          criticality
          weight
          order
          answer
          comment
          imageS3Path
          form {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          rozFormQuestionsFormId
          rozFormQuestionsFormDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFormQuestionMutation>response.data.deleteFormQuestion;
  }
  async CreateEdition(
    input: CreateEditionInput,
    condition?: ModelEditionConditionInput
  ): Promise<CreateEditionMutation> {
    const statement = `mutation CreateEdition($input: CreateEditionInput!, $condition: ModelEditionConditionInput) {
        createEdition(input: $input, condition: $condition) {
          __typename
          editionId
          active
          editor
          editorId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEditionMutation>response.data.createEdition;
  }
  async UpdateEdition(
    input: UpdateEditionInput,
    condition?: ModelEditionConditionInput
  ): Promise<UpdateEditionMutation> {
    const statement = `mutation UpdateEdition($input: UpdateEditionInput!, $condition: ModelEditionConditionInput) {
        updateEdition(input: $input, condition: $condition) {
          __typename
          editionId
          active
          editor
          editorId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEditionMutation>response.data.updateEdition;
  }
  async DeleteEdition(
    input: DeleteEditionInput,
    condition?: ModelEditionConditionInput
  ): Promise<DeleteEditionMutation> {
    const statement = `mutation DeleteEdition($input: DeleteEditionInput!, $condition: ModelEditionConditionInput) {
        deleteEdition(input: $input, condition: $condition) {
          __typename
          editionId
          active
          editor
          editorId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEditionMutation>response.data.deleteEdition;
  }
  async CreateMailPreference(
    input: CreateMailPreferenceInput,
    condition?: ModelMailPreferenceConditionInput
  ): Promise<CreateMailPreferenceMutation> {
    const statement = `mutation CreateMailPreference($input: CreateMailPreferenceInput!, $condition: ModelMailPreferenceConditionInput) {
        createMailPreference(input: $input, condition: $condition) {
          __typename
          userId
          mailId
          name
          receive
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMailPreferenceMutation>response.data.createMailPreference;
  }
  async UpdateMailPreference(
    input: UpdateMailPreferenceInput,
    condition?: ModelMailPreferenceConditionInput
  ): Promise<UpdateMailPreferenceMutation> {
    const statement = `mutation UpdateMailPreference($input: UpdateMailPreferenceInput!, $condition: ModelMailPreferenceConditionInput) {
        updateMailPreference(input: $input, condition: $condition) {
          __typename
          userId
          mailId
          name
          receive
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMailPreferenceMutation>response.data.updateMailPreference;
  }
  async DeleteMailPreference(
    input: DeleteMailPreferenceInput,
    condition?: ModelMailPreferenceConditionInput
  ): Promise<DeleteMailPreferenceMutation> {
    const statement = `mutation DeleteMailPreference($input: DeleteMailPreferenceInput!, $condition: ModelMailPreferenceConditionInput) {
        deleteMailPreference(input: $input, condition: $condition) {
          __typename
          userId
          mailId
          name
          receive
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMailPreferenceMutation>response.data.deleteMailPreference;
  }
  async CreateDriverExclusion(
    input: CreateDriverExclusionInput,
    condition?: ModelDriverExclusionConditionInput
  ): Promise<CreateDriverExclusionMutation> {
    const statement = `mutation CreateDriverExclusion($input: CreateDriverExclusionInput!, $condition: ModelDriverExclusionConditionInput) {
        createDriverExclusion(input: $input, condition: $condition) {
          __typename
          driverId
          startDate
          endDate
          exclusionMotives
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDriverExclusionMutation>response.data.createDriverExclusion;
  }
  async UpdateDriverExclusion(
    input: UpdateDriverExclusionInput,
    condition?: ModelDriverExclusionConditionInput
  ): Promise<UpdateDriverExclusionMutation> {
    const statement = `mutation UpdateDriverExclusion($input: UpdateDriverExclusionInput!, $condition: ModelDriverExclusionConditionInput) {
        updateDriverExclusion(input: $input, condition: $condition) {
          __typename
          driverId
          startDate
          endDate
          exclusionMotives
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDriverExclusionMutation>response.data.updateDriverExclusion;
  }
  async DeleteDriverExclusion(
    input: DeleteDriverExclusionInput,
    condition?: ModelDriverExclusionConditionInput
  ): Promise<DeleteDriverExclusionMutation> {
    const statement = `mutation DeleteDriverExclusion($input: DeleteDriverExclusionInput!, $condition: ModelDriverExclusionConditionInput) {
        deleteDriverExclusion(input: $input, condition: $condition) {
          __typename
          driverId
          startDate
          endDate
          exclusionMotives
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDriverExclusionMutation>response.data.deleteDriverExclusion;
  }
  async CreateStatus(
    input: CreateStatusInput,
    condition?: ModelStatusConditionInput
  ): Promise<CreateStatusMutation> {
    const statement = `mutation CreateStatus($input: CreateStatusInput!, $condition: ModelStatusConditionInput) {
        createStatus(input: $input, condition: $condition) {
          __typename
          statusId
          status
          startedAt
          endedAt
          business
          subBusiness
          carrier
          center
          modelTypeName
          type
          entityId
          sapId
          coupleId
          blockingMotives
          aboutToExpireDocuments
          expiredDocuments
          missingDocuments
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStatusMutation>response.data.createStatus;
  }
  async UpdateStatus(
    input: UpdateStatusInput,
    condition?: ModelStatusConditionInput
  ): Promise<UpdateStatusMutation> {
    const statement = `mutation UpdateStatus($input: UpdateStatusInput!, $condition: ModelStatusConditionInput) {
        updateStatus(input: $input, condition: $condition) {
          __typename
          statusId
          status
          startedAt
          endedAt
          business
          subBusiness
          carrier
          center
          modelTypeName
          type
          entityId
          sapId
          coupleId
          blockingMotives
          aboutToExpireDocuments
          expiredDocuments
          missingDocuments
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStatusMutation>response.data.updateStatus;
  }
  async DeleteStatus(
    input: DeleteStatusInput,
    condition?: ModelStatusConditionInput
  ): Promise<DeleteStatusMutation> {
    const statement = `mutation DeleteStatus($input: DeleteStatusInput!, $condition: ModelStatusConditionInput) {
        deleteStatus(input: $input, condition: $condition) {
          __typename
          statusId
          status
          startedAt
          endedAt
          business
          subBusiness
          carrier
          center
          modelTypeName
          type
          entityId
          sapId
          coupleId
          blockingMotives
          aboutToExpireDocuments
          expiredDocuments
          missingDocuments
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStatusMutation>response.data.deleteStatus;
  }
  async CreateArchive(
    input: CreateArchiveInput,
    condition?: ModelArchiveConditionInput
  ): Promise<CreateArchiveMutation> {
    const statement = `mutation CreateArchive($input: CreateArchiveInput!, $condition: ModelArchiveConditionInput) {
        createArchive(input: $input, condition: $condition) {
          __typename
          archiveId
          archivedAt
          archivedBy
          business
          carrier
          center
          modelTypeName
          motiveForArchiving
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          comment
          entity
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateArchiveMutation>response.data.createArchive;
  }
  async UpdateArchive(
    input: UpdateArchiveInput,
    condition?: ModelArchiveConditionInput
  ): Promise<UpdateArchiveMutation> {
    const statement = `mutation UpdateArchive($input: UpdateArchiveInput!, $condition: ModelArchiveConditionInput) {
        updateArchive(input: $input, condition: $condition) {
          __typename
          archiveId
          archivedAt
          archivedBy
          business
          carrier
          center
          modelTypeName
          motiveForArchiving
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          comment
          entity
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateArchiveMutation>response.data.updateArchive;
  }
  async DeleteArchive(
    input: DeleteArchiveInput,
    condition?: ModelArchiveConditionInput
  ): Promise<DeleteArchiveMutation> {
    const statement = `mutation DeleteArchive($input: DeleteArchiveInput!, $condition: ModelArchiveConditionInput) {
        deleteArchive(input: $input, condition: $condition) {
          __typename
          archiveId
          archivedAt
          archivedBy
          business
          carrier
          center
          modelTypeName
          motiveForArchiving
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          comment
          entity
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteArchiveMutation>response.data.deleteArchive;
  }
  async GetCarrier(carrierId: string): Promise<GetCarrierQuery> {
    const statement = `query GetCarrier($carrierId: ID!) {
        getCarrier(carrierId: $carrierId) {
          __typename
          carrierId
          name
          rut
          email
          additionalEmails
          vehicles {
            __typename
            items {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            nextToken
          }
          envasados {
            __typename
            items {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            nextToken
          }
          cisternas {
            __typename
            items {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            nextToken
          }
          tractos {
            __typename
            items {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            nextToken
          }
          tanques {
            __typename
            items {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            nextToken
          }
          semirremolques {
            __typename
            items {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            nextToken
          }
          drivers {
            __typename
            items {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      carrierId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCarrierQuery>response.data.getCarrier;
  }
  async ListCarriers(
    carrierId?: string,
    filter?: ModelCarrierFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCarriersQuery> {
    const statement = `query ListCarriers($carrierId: ID, $filter: ModelCarrierFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCarriers(
          carrierId: $carrierId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (carrierId) {
      gqlAPIServiceArguments.carrierId = carrierId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCarriersQuery>response.data.listCarriers;
  }
  async GetVehicle(vehicleId: string): Promise<GetVehicleQuery> {
    const statement = `query GetVehicle($vehicleId: ID!) {
        getVehicle(vehicleId: $vehicleId) {
          __typename
          vehicleId
          brand
          licensePlate
          color
          model
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          imei
          cameraImei
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          isSpot
          sapId
          trailerLicensePlate
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierVehiclesCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vehicleId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVehicleQuery>response.data.getVehicle;
  }
  async ListVehicles(
    vehicleId?: string,
    filter?: ModelVehicleFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListVehiclesQuery> {
    const statement = `query ListVehicles($vehicleId: ID, $filter: ModelVehicleFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listVehicles(
          vehicleId: $vehicleId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vehicleId) {
      gqlAPIServiceArguments.vehicleId = vehicleId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVehiclesQuery>response.data.listVehicles;
  }
  async VehiclesByBusinessAndVehicleId(
    business: string,
    vehicleId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVehicleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VehiclesByBusinessAndVehicleIdQuery> {
    const statement = `query VehiclesByBusinessAndVehicleId($business: String!, $vehicleId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVehicleFilterInput, $limit: Int, $nextToken: String) {
        vehiclesByBusinessAndVehicleId(
          business: $business
          vehicleId: $vehicleId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (vehicleId) {
      gqlAPIServiceArguments.vehicleId = vehicleId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VehiclesByBusinessAndVehicleIdQuery>(
      response.data.vehiclesByBusinessAndVehicleId
    );
  }
  async VehiclesByCenterAndVehicleId(
    center: string,
    vehicleId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVehicleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VehiclesByCenterAndVehicleIdQuery> {
    const statement = `query VehiclesByCenterAndVehicleId($center: String!, $vehicleId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVehicleFilterInput, $limit: Int, $nextToken: String) {
        vehiclesByCenterAndVehicleId(
          center: $center
          vehicleId: $vehicleId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (vehicleId) {
      gqlAPIServiceArguments.vehicleId = vehicleId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VehiclesByCenterAndVehicleIdQuery>(
      response.data.vehiclesByCenterAndVehicleId
    );
  }
  async VehiclesByCompanyAndVehicleId(
    company: string,
    vehicleId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVehicleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VehiclesByCompanyAndVehicleIdQuery> {
    const statement = `query VehiclesByCompanyAndVehicleId($company: String!, $vehicleId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVehicleFilterInput, $limit: Int, $nextToken: String) {
        vehiclesByCompanyAndVehicleId(
          company: $company
          vehicleId: $vehicleId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (vehicleId) {
      gqlAPIServiceArguments.vehicleId = vehicleId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VehiclesByCompanyAndVehicleIdQuery>(
      response.data.vehiclesByCompanyAndVehicleId
    );
  }
  async GetEnvasado(envasadoId: string): Promise<GetEnvasadoQuery> {
    const statement = `query GetEnvasado($envasadoId: ID!) {
        getEnvasado(envasadoId: $envasadoId) {
          __typename
          envasadoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          engineType
          hasRanchAccreditation
          isSpot
          bodyType
          maximumPalletCapacity
          height
          width
          length
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierEnvasadosCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      envasadoId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEnvasadoQuery>response.data.getEnvasado;
  }
  async ListEnvasados(
    envasadoId?: string,
    filter?: ModelEnvasadoFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListEnvasadosQuery> {
    const statement = `query ListEnvasados($envasadoId: ID, $filter: ModelEnvasadoFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listEnvasados(
          envasadoId: $envasadoId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (envasadoId) {
      gqlAPIServiceArguments.envasadoId = envasadoId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEnvasadosQuery>response.data.listEnvasados;
  }
  async EnvasadosByBusinessAndEnvasadoId(
    business: string,
    envasadoId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEnvasadoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EnvasadosByBusinessAndEnvasadoIdQuery> {
    const statement = `query EnvasadosByBusinessAndEnvasadoId($business: String!, $envasadoId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEnvasadoFilterInput, $limit: Int, $nextToken: String) {
        envasadosByBusinessAndEnvasadoId(
          business: $business
          envasadoId: $envasadoId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (envasadoId) {
      gqlAPIServiceArguments.envasadoId = envasadoId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EnvasadosByBusinessAndEnvasadoIdQuery>(
      response.data.envasadosByBusinessAndEnvasadoId
    );
  }
  async EnvasadosByCenterAndEnvasadoId(
    center: string,
    envasadoId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEnvasadoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EnvasadosByCenterAndEnvasadoIdQuery> {
    const statement = `query EnvasadosByCenterAndEnvasadoId($center: String!, $envasadoId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEnvasadoFilterInput, $limit: Int, $nextToken: String) {
        envasadosByCenterAndEnvasadoId(
          center: $center
          envasadoId: $envasadoId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (envasadoId) {
      gqlAPIServiceArguments.envasadoId = envasadoId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EnvasadosByCenterAndEnvasadoIdQuery>(
      response.data.envasadosByCenterAndEnvasadoId
    );
  }
  async EnvasadosByCompanyAndEnvasadoId(
    company: string,
    envasadoId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEnvasadoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EnvasadosByCompanyAndEnvasadoIdQuery> {
    const statement = `query EnvasadosByCompanyAndEnvasadoId($company: String!, $envasadoId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEnvasadoFilterInput, $limit: Int, $nextToken: String) {
        envasadosByCompanyAndEnvasadoId(
          company: $company
          envasadoId: $envasadoId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (envasadoId) {
      gqlAPIServiceArguments.envasadoId = envasadoId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EnvasadosByCompanyAndEnvasadoIdQuery>(
      response.data.envasadosByCompanyAndEnvasadoId
    );
  }
  async GetCisterna(cisternaId: string): Promise<GetCisternaQuery> {
    const statement = `query GetCisterna($cisternaId: ID!) {
        getCisterna(cisternaId: $cisternaId) {
          __typename
          cisternaId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierCisternasCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      cisternaId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCisternaQuery>response.data.getCisterna;
  }
  async ListCisternas(
    cisternaId?: string,
    filter?: ModelCisternaFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCisternasQuery> {
    const statement = `query ListCisternas($cisternaId: ID, $filter: ModelCisternaFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCisternas(
          cisternaId: $cisternaId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cisternaId) {
      gqlAPIServiceArguments.cisternaId = cisternaId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCisternasQuery>response.data.listCisternas;
  }
  async CisternasByBusinessAndCisternaId(
    business: string,
    cisternaId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCisternaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CisternasByBusinessAndCisternaIdQuery> {
    const statement = `query CisternasByBusinessAndCisternaId($business: String!, $cisternaId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCisternaFilterInput, $limit: Int, $nextToken: String) {
        cisternasByBusinessAndCisternaId(
          business: $business
          cisternaId: $cisternaId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (cisternaId) {
      gqlAPIServiceArguments.cisternaId = cisternaId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CisternasByBusinessAndCisternaIdQuery>(
      response.data.cisternasByBusinessAndCisternaId
    );
  }
  async CisternasByCenterAndCisternaId(
    center: string,
    cisternaId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCisternaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CisternasByCenterAndCisternaIdQuery> {
    const statement = `query CisternasByCenterAndCisternaId($center: String!, $cisternaId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCisternaFilterInput, $limit: Int, $nextToken: String) {
        cisternasByCenterAndCisternaId(
          center: $center
          cisternaId: $cisternaId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (cisternaId) {
      gqlAPIServiceArguments.cisternaId = cisternaId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CisternasByCenterAndCisternaIdQuery>(
      response.data.cisternasByCenterAndCisternaId
    );
  }
  async CisternasByCompanyAndCisternaId(
    company: string,
    cisternaId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCisternaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CisternasByCompanyAndCisternaIdQuery> {
    const statement = `query CisternasByCompanyAndCisternaId($company: String!, $cisternaId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCisternaFilterInput, $limit: Int, $nextToken: String) {
        cisternasByCompanyAndCisternaId(
          company: $company
          cisternaId: $cisternaId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (cisternaId) {
      gqlAPIServiceArguments.cisternaId = cisternaId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CisternasByCompanyAndCisternaIdQuery>(
      response.data.cisternasByCompanyAndCisternaId
    );
  }
  async GetTracto(tractoId: string): Promise<GetTractoQuery> {
    const statement = `query GetTracto($tractoId: ID!) {
        getTracto(tractoId: $tractoId) {
          __typename
          tractoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTractosCarrierId
          tractoCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      tractoId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTractoQuery>response.data.getTracto;
  }
  async ListTractos(
    tractoId?: string,
    filter?: ModelTractoFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTractosQuery> {
    const statement = `query ListTractos($tractoId: ID, $filter: ModelTractoFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTractos(
          tractoId: $tractoId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tractoId) {
      gqlAPIServiceArguments.tractoId = tractoId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTractosQuery>response.data.listTractos;
  }
  async TractosByBusinessAndTractoId(
    business: string,
    tractoId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTractoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TractosByBusinessAndTractoIdQuery> {
    const statement = `query TractosByBusinessAndTractoId($business: String!, $tractoId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTractoFilterInput, $limit: Int, $nextToken: String) {
        tractosByBusinessAndTractoId(
          business: $business
          tractoId: $tractoId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (tractoId) {
      gqlAPIServiceArguments.tractoId = tractoId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TractosByBusinessAndTractoIdQuery>(
      response.data.tractosByBusinessAndTractoId
    );
  }
  async TractosByCenterAndTractoId(
    center: string,
    tractoId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTractoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TractosByCenterAndTractoIdQuery> {
    const statement = `query TractosByCenterAndTractoId($center: String!, $tractoId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTractoFilterInput, $limit: Int, $nextToken: String) {
        tractosByCenterAndTractoId(
          center: $center
          tractoId: $tractoId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (tractoId) {
      gqlAPIServiceArguments.tractoId = tractoId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TractosByCenterAndTractoIdQuery>(
      response.data.tractosByCenterAndTractoId
    );
  }
  async TractosByCompanyAndTractoId(
    company: string,
    tractoId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTractoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TractosByCompanyAndTractoIdQuery> {
    const statement = `query TractosByCompanyAndTractoId($company: String!, $tractoId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTractoFilterInput, $limit: Int, $nextToken: String) {
        tractosByCompanyAndTractoId(
          company: $company
          tractoId: $tractoId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (tractoId) {
      gqlAPIServiceArguments.tractoId = tractoId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TractosByCompanyAndTractoIdQuery>(
      response.data.tractosByCompanyAndTractoId
    );
  }
  async GetTanque(tanqueId: string): Promise<GetTanqueQuery> {
    const statement = `query GetTanque($tanqueId: ID!) {
        getTanque(tanqueId: $tanqueId) {
          __typename
          tanqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTanquesCarrierId
          tanqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      tanqueId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTanqueQuery>response.data.getTanque;
  }
  async ListTanques(
    tanqueId?: string,
    filter?: ModelTanqueFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTanquesQuery> {
    const statement = `query ListTanques($tanqueId: ID, $filter: ModelTanqueFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTanques(
          tanqueId: $tanqueId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tanqueId) {
      gqlAPIServiceArguments.tanqueId = tanqueId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTanquesQuery>response.data.listTanques;
  }
  async TanquesByBusinessAndTanqueId(
    business: string,
    tanqueId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTanqueFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TanquesByBusinessAndTanqueIdQuery> {
    const statement = `query TanquesByBusinessAndTanqueId($business: String!, $tanqueId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTanqueFilterInput, $limit: Int, $nextToken: String) {
        tanquesByBusinessAndTanqueId(
          business: $business
          tanqueId: $tanqueId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (tanqueId) {
      gqlAPIServiceArguments.tanqueId = tanqueId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TanquesByBusinessAndTanqueIdQuery>(
      response.data.tanquesByBusinessAndTanqueId
    );
  }
  async TanquesByCenterAndTanqueId(
    center: string,
    tanqueId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTanqueFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TanquesByCenterAndTanqueIdQuery> {
    const statement = `query TanquesByCenterAndTanqueId($center: String!, $tanqueId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTanqueFilterInput, $limit: Int, $nextToken: String) {
        tanquesByCenterAndTanqueId(
          center: $center
          tanqueId: $tanqueId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (tanqueId) {
      gqlAPIServiceArguments.tanqueId = tanqueId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TanquesByCenterAndTanqueIdQuery>(
      response.data.tanquesByCenterAndTanqueId
    );
  }
  async TanquesByCompanyAndTanqueId(
    company: string,
    tanqueId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTanqueFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TanquesByCompanyAndTanqueIdQuery> {
    const statement = `query TanquesByCompanyAndTanqueId($company: String!, $tanqueId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTanqueFilterInput, $limit: Int, $nextToken: String) {
        tanquesByCompanyAndTanqueId(
          company: $company
          tanqueId: $tanqueId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (tanqueId) {
      gqlAPIServiceArguments.tanqueId = tanqueId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TanquesByCompanyAndTanqueIdQuery>(
      response.data.tanquesByCompanyAndTanqueId
    );
  }
  async GetSemirremolque(
    semirremolqueId: string
  ): Promise<GetSemirremolqueQuery> {
    const statement = `query GetSemirremolque($semirremolqueId: ID!) {
        getSemirremolque(semirremolqueId: $semirremolqueId) {
          __typename
          semirremolqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          maximumPalletCapacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          rampType
          MOPCode
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierSemirremolquesCarrierId
          semirremolqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      semirremolqueId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSemirremolqueQuery>response.data.getSemirremolque;
  }
  async ListSemirremolques(
    semirremolqueId?: string,
    filter?: ModelSemirremolqueFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListSemirremolquesQuery> {
    const statement = `query ListSemirremolques($semirremolqueId: ID, $filter: ModelSemirremolqueFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listSemirremolques(
          semirremolqueId: $semirremolqueId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (semirremolqueId) {
      gqlAPIServiceArguments.semirremolqueId = semirremolqueId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSemirremolquesQuery>response.data.listSemirremolques;
  }
  async SemirremolquesByBusinessAndSemirremolqueId(
    business: string,
    semirremolqueId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSemirremolqueFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SemirremolquesByBusinessAndSemirremolqueIdQuery> {
    const statement = `query SemirremolquesByBusinessAndSemirremolqueId($business: String!, $semirremolqueId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSemirremolqueFilterInput, $limit: Int, $nextToken: String) {
        semirremolquesByBusinessAndSemirremolqueId(
          business: $business
          semirremolqueId: $semirremolqueId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (semirremolqueId) {
      gqlAPIServiceArguments.semirremolqueId = semirremolqueId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SemirremolquesByBusinessAndSemirremolqueIdQuery>(
      response.data.semirremolquesByBusinessAndSemirremolqueId
    );
  }
  async SemirremolquesByCenterAndSemirremolqueId(
    center: string,
    semirremolqueId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSemirremolqueFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SemirremolquesByCenterAndSemirremolqueIdQuery> {
    const statement = `query SemirremolquesByCenterAndSemirremolqueId($center: String!, $semirremolqueId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSemirremolqueFilterInput, $limit: Int, $nextToken: String) {
        semirremolquesByCenterAndSemirremolqueId(
          center: $center
          semirremolqueId: $semirremolqueId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (semirremolqueId) {
      gqlAPIServiceArguments.semirremolqueId = semirremolqueId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SemirremolquesByCenterAndSemirremolqueIdQuery>(
      response.data.semirremolquesByCenterAndSemirremolqueId
    );
  }
  async SemirremolquesByCompanyAndSemirremolqueId(
    company: string,
    semirremolqueId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSemirremolqueFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SemirremolquesByCompanyAndSemirremolqueIdQuery> {
    const statement = `query SemirremolquesByCompanyAndSemirremolqueId($company: String!, $semirremolqueId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSemirremolqueFilterInput, $limit: Int, $nextToken: String) {
        semirremolquesByCompanyAndSemirremolqueId(
          company: $company
          semirremolqueId: $semirremolqueId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (semirremolqueId) {
      gqlAPIServiceArguments.semirremolqueId = semirremolqueId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SemirremolquesByCompanyAndSemirremolqueIdQuery>(
      response.data.semirremolquesByCompanyAndSemirremolqueId
    );
  }
  async GetCouple(coupleId: string): Promise<GetCoupleQuery> {
    const statement = `query GetCouple($coupleId: ID!) {
        getCouple(coupleId: $coupleId) {
          __typename
          coupleId
          business
          center
          comment
          company
          status
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          owner
          createdAt
          updatedAt
          coupleTractoTractoId
          coupleTanqueTanqueId
          coupleSemirremolqueSemirremolqueId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      coupleId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCoupleQuery>response.data.getCouple;
  }
  async ListCouples(
    coupleId?: string,
    filter?: ModelCoupleFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCouplesQuery> {
    const statement = `query ListCouples($coupleId: ID, $filter: ModelCoupleFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCouples(
          coupleId: $coupleId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (coupleId) {
      gqlAPIServiceArguments.coupleId = coupleId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCouplesQuery>response.data.listCouples;
  }
  async CouplesByBusinessAndCoupleId(
    business: string,
    coupleId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCoupleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CouplesByBusinessAndCoupleIdQuery> {
    const statement = `query CouplesByBusinessAndCoupleId($business: String!, $coupleId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCoupleFilterInput, $limit: Int, $nextToken: String) {
        couplesByBusinessAndCoupleId(
          business: $business
          coupleId: $coupleId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (coupleId) {
      gqlAPIServiceArguments.coupleId = coupleId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CouplesByBusinessAndCoupleIdQuery>(
      response.data.couplesByBusinessAndCoupleId
    );
  }
  async CouplesByCenterAndCoupleId(
    center: string,
    coupleId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCoupleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CouplesByCenterAndCoupleIdQuery> {
    const statement = `query CouplesByCenterAndCoupleId($center: String!, $coupleId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCoupleFilterInput, $limit: Int, $nextToken: String) {
        couplesByCenterAndCoupleId(
          center: $center
          coupleId: $coupleId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (coupleId) {
      gqlAPIServiceArguments.coupleId = coupleId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CouplesByCenterAndCoupleIdQuery>(
      response.data.couplesByCenterAndCoupleId
    );
  }
  async CouplesByCompanyAndCoupleId(
    company: string,
    coupleId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCoupleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CouplesByCompanyAndCoupleIdQuery> {
    const statement = `query CouplesByCompanyAndCoupleId($company: String!, $coupleId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCoupleFilterInput, $limit: Int, $nextToken: String) {
        couplesByCompanyAndCoupleId(
          company: $company
          coupleId: $coupleId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (coupleId) {
      gqlAPIServiceArguments.coupleId = coupleId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CouplesByCompanyAndCoupleIdQuery>(
      response.data.couplesByCompanyAndCoupleId
    );
  }
  async GetDriver(driverId: string): Promise<GetDriverQuery> {
    const statement = `query GetDriver($driverId: ID!) {
        getDriver(driverId: $driverId) {
          __typename
          driverId
          firstName
          lastName
          rut
          birthDate
          phone
          email
          business
          center
          originalCenter
          comment
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          sapId
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          speedings {
            __typename
            items {
              __typename
              speedingId
              speedingDatetime
              latitude
              longitude
              speedLimit
              actualSpeed
              counted
              driverId
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierDriversCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      driverId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDriverQuery>response.data.getDriver;
  }
  async ListDrivers(
    driverId?: string,
    filter?: ModelDriverFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListDriversQuery> {
    const statement = `query ListDrivers($driverId: ID, $filter: ModelDriverFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listDrivers(
          driverId: $driverId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (driverId) {
      gqlAPIServiceArguments.driverId = driverId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDriversQuery>response.data.listDrivers;
  }
  async DriversByBusinessAndDriverId(
    business: string,
    driverId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelDriverFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DriversByBusinessAndDriverIdQuery> {
    const statement = `query DriversByBusinessAndDriverId($business: String!, $driverId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelDriverFilterInput, $limit: Int, $nextToken: String) {
        driversByBusinessAndDriverId(
          business: $business
          driverId: $driverId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (driverId) {
      gqlAPIServiceArguments.driverId = driverId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DriversByBusinessAndDriverIdQuery>(
      response.data.driversByBusinessAndDriverId
    );
  }
  async DriversByCenterAndDriverId(
    center: string,
    driverId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelDriverFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DriversByCenterAndDriverIdQuery> {
    const statement = `query DriversByCenterAndDriverId($center: String!, $driverId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelDriverFilterInput, $limit: Int, $nextToken: String) {
        driversByCenterAndDriverId(
          center: $center
          driverId: $driverId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (driverId) {
      gqlAPIServiceArguments.driverId = driverId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DriversByCenterAndDriverIdQuery>(
      response.data.driversByCenterAndDriverId
    );
  }
  async DriversByCompanyAndDriverId(
    company: string,
    driverId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelDriverFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DriversByCompanyAndDriverIdQuery> {
    const statement = `query DriversByCompanyAndDriverId($company: String!, $driverId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelDriverFilterInput, $limit: Int, $nextToken: String) {
        driversByCompanyAndDriverId(
          company: $company
          driverId: $driverId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (driverId) {
      gqlAPIServiceArguments.driverId = driverId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DriversByCompanyAndDriverIdQuery>(
      response.data.driversByCompanyAndDriverId
    );
  }
  async GetDocument(documentId: string): Promise<GetDocumentQuery> {
    const statement = `query GetDocument($documentId: ID!) {
        getDocument(documentId: $documentId) {
          __typename
          documentId
          masterValueId
          name
          status
          s3Path
          issueDate
          expirationDate
          vehicle {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          envasado {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          cisterna {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          driver {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          archive {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          vehicleDocumentsVehicleId
          envasadoDocumentsEnvasadoId
          cisternaDocumentsCisternaId
          tractoDocumentsTractoId
          tanqueDocumentsTanqueId
          semirremolqueDocumentsSemirremolqueId
          driverDocumentsDriverId
          archiveDocumentsArchiveId
          archiveDocumentsArchivedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      documentId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDocumentQuery>response.data.getDocument;
  }
  async ListDocuments(
    documentId?: string,
    filter?: ModelDocumentFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListDocumentsQuery> {
    const statement = `query ListDocuments($documentId: ID, $filter: ModelDocumentFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listDocuments(
          documentId: $documentId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            documentId
            masterValueId
            name
            status
            s3Path
            issueDate
            expirationDate
            vehicle {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            envasado {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            cisterna {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            driver {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            archive {
              __typename
              archiveId
              archivedAt
              archivedBy
              business
              carrier
              center
              modelTypeName
              motiveForArchiving
              comment
              entity
              owner
              createdAt
              updatedAt
            }
            owner
            createdAt
            updatedAt
            vehicleDocumentsVehicleId
            envasadoDocumentsEnvasadoId
            cisternaDocumentsCisternaId
            tractoDocumentsTractoId
            tanqueDocumentsTanqueId
            semirremolqueDocumentsSemirremolqueId
            driverDocumentsDriverId
            archiveDocumentsArchiveId
            archiveDocumentsArchivedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (documentId) {
      gqlAPIServiceArguments.documentId = documentId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDocumentsQuery>response.data.listDocuments;
  }
  async GetMaster(
    businessId: string,
    masterId: string
  ): Promise<GetMasterQuery> {
    const statement = `query GetMaster($businessId: ID!, $masterId: ID!) {
        getMaster(businessId: $businessId, masterId: $masterId) {
          __typename
          businessId
          masterId
          masterGroup
          valueId
          valueToDisplay
          sapId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      businessId,
      masterId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMasterQuery>response.data.getMaster;
  }
  async ListMasters(
    businessId?: string,
    masterId?: ModelIDKeyConditionInput,
    filter?: ModelMasterFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMastersQuery> {
    const statement = `query ListMasters($businessId: ID, $masterId: ModelIDKeyConditionInput, $filter: ModelMasterFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMasters(
          businessId: $businessId
          masterId: $masterId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            businessId
            masterId
            masterGroup
            valueId
            valueToDisplay
            sapId
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessId) {
      gqlAPIServiceArguments.businessId = businessId;
    }
    if (masterId) {
      gqlAPIServiceArguments.masterId = masterId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMastersQuery>response.data.listMasters;
  }
  async GetDocumentSpecs(
    businessId: string,
    documentSpecsId: string
  ): Promise<GetDocumentSpecsQuery> {
    const statement = `query GetDocumentSpecs($businessId: ID!, $documentSpecsId: ID!) {
        getDocumentSpecs(businessId: $businessId, documentSpecsId: $documentSpecsId) {
          __typename
          businessId
          documentSpecsId
          isMandatory
          canBlock
          extensionDays
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      businessId,
      documentSpecsId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDocumentSpecsQuery>response.data.getDocumentSpecs;
  }
  async ListDocumentSpecs(
    businessId?: string,
    documentSpecsId?: ModelIDKeyConditionInput,
    filter?: ModelDocumentSpecsFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListDocumentSpecsQuery> {
    const statement = `query ListDocumentSpecs($businessId: ID, $documentSpecsId: ModelIDKeyConditionInput, $filter: ModelDocumentSpecsFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listDocumentSpecs(
          businessId: $businessId
          documentSpecsId: $documentSpecsId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            businessId
            documentSpecsId
            isMandatory
            canBlock
            extensionDays
            updatedBy
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessId) {
      gqlAPIServiceArguments.businessId = businessId;
    }
    if (documentSpecsId) {
      gqlAPIServiceArguments.documentSpecsId = documentSpecsId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDocumentSpecsQuery>response.data.listDocumentSpecs;
  }
  async GetUser(userId: string): Promise<GetUserQuery> {
    const statement = `query GetUser($userId: ID!) {
        getUser(userId: $userId) {
          __typename
          userId
          firstName
          lastName
          rut
          phone
          email
          business
          hasAccessTo
          authGroup
          status
          company
          centers
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    userId?: string,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($userId: ID, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listUsers(
          userId: $userId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            userId
            firstName
            lastName
            rut
            phone
            email
            business
            hasAccessTo
            authGroup
            status
            company
            centers
            updatedBy
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async UsersByBusinessAndUserId(
    business: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UsersByBusinessAndUserIdQuery> {
    const statement = `query UsersByBusinessAndUserId($business: String!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        usersByBusinessAndUserId(
          business: $business
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            userId
            firstName
            lastName
            rut
            phone
            email
            business
            hasAccessTo
            authGroup
            status
            company
            centers
            updatedBy
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UsersByBusinessAndUserIdQuery>(
      response.data.usersByBusinessAndUserId
    );
  }
  async UsersByCompanyAndUserId(
    company: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UsersByCompanyAndUserIdQuery> {
    const statement = `query UsersByCompanyAndUserId($company: String!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        usersByCompanyAndUserId(
          company: $company
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            userId
            firstName
            lastName
            rut
            phone
            email
            business
            hasAccessTo
            authGroup
            status
            company
            centers
            updatedBy
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UsersByCompanyAndUserIdQuery>response.data.usersByCompanyAndUserId;
  }
  async GetNotification(
    businessId: string,
    notificationId: string
  ): Promise<GetNotificationQuery> {
    const statement = `query GetNotification($businessId: ID!, $notificationId: ID!) {
        getNotification(businessId: $businessId, notificationId: $notificationId) {
          __typename
          businessId
          notificationId
          status
          retries
          motive
          model
          vehicleId
          driverId
          envasadoId
          cisternaId
          tractoId
          tanqueId
          semirremolqueId
          userId
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      businessId,
      notificationId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNotificationQuery>response.data.getNotification;
  }
  async ListNotifications(
    businessId?: string,
    notificationId?: ModelIDKeyConditionInput,
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListNotificationsQuery> {
    const statement = `query ListNotifications($businessId: ID, $notificationId: ModelIDKeyConditionInput, $filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listNotifications(
          businessId: $businessId
          notificationId: $notificationId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            businessId
            notificationId
            status
            retries
            motive
            model
            vehicleId
            driverId
            envasadoId
            cisternaId
            tractoId
            tanqueId
            semirremolqueId
            userId
            comment
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessId) {
      gqlAPIServiceArguments.businessId = businessId;
    }
    if (notificationId) {
      gqlAPIServiceArguments.notificationId = notificationId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationsQuery>response.data.listNotifications;
  }
  async GetMail(businessId: string, mailId: string): Promise<GetMailQuery> {
    const statement = `query GetMail($businessId: ID!, $mailId: ID!) {
        getMail(businessId: $businessId, mailId: $mailId) {
          __typename
          businessId
          mailId
          receivers
          title
          content
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      businessId,
      mailId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMailQuery>response.data.getMail;
  }
  async ListMail(
    businessId?: string,
    mailId?: ModelIDKeyConditionInput,
    filter?: ModelMailFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMailQuery> {
    const statement = `query ListMail($businessId: ID, $mailId: ModelIDKeyConditionInput, $filter: ModelMailFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMail(
          businessId: $businessId
          mailId: $mailId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            businessId
            mailId
            receivers
            title
            content
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (businessId) {
      gqlAPIServiceArguments.businessId = businessId;
    }
    if (mailId) {
      gqlAPIServiceArguments.mailId = mailId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMailQuery>response.data.listMail;
  }
  async GetSpeeding(
    speedingId: string,
    speedingDatetime: string
  ): Promise<GetSpeedingQuery> {
    const statement = `query GetSpeeding($speedingId: ID!, $speedingDatetime: ID!) {
        getSpeeding(speedingId: $speedingId, speedingDatetime: $speedingDatetime) {
          __typename
          speedingId
          speedingDatetime
          latitude
          longitude
          speedLimit
          actualSpeed
          counted
          driverId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      speedingId,
      speedingDatetime,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSpeedingQuery>response.data.getSpeeding;
  }
  async ListSpeedings(
    speedingId?: string,
    speedingDatetime?: ModelIDKeyConditionInput,
    filter?: ModelSpeedingFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListSpeedingsQuery> {
    const statement = `query ListSpeedings($speedingId: ID, $speedingDatetime: ModelIDKeyConditionInput, $filter: ModelSpeedingFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listSpeedings(
          speedingId: $speedingId
          speedingDatetime: $speedingDatetime
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            speedingId
            speedingDatetime
            latitude
            longitude
            speedLimit
            actualSpeed
            counted
            driverId
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (speedingId) {
      gqlAPIServiceArguments.speedingId = speedingId;
    }
    if (speedingDatetime) {
      gqlAPIServiceArguments.speedingDatetime = speedingDatetime;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSpeedingsQuery>response.data.listSpeedings;
  }
  async SpeedingsByDriverIdAndSpeedingDatetime(
    driverId: string,
    speedingDatetime?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSpeedingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SpeedingsByDriverIdAndSpeedingDatetimeQuery> {
    const statement = `query SpeedingsByDriverIdAndSpeedingDatetime($driverId: ID!, $speedingDatetime: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSpeedingFilterInput, $limit: Int, $nextToken: String) {
        speedingsByDriverIdAndSpeedingDatetime(
          driverId: $driverId
          speedingDatetime: $speedingDatetime
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            speedingId
            speedingDatetime
            latitude
            longitude
            speedLimit
            actualSpeed
            counted
            driverId
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      driverId,
    };
    if (speedingDatetime) {
      gqlAPIServiceArguments.speedingDatetime = speedingDatetime;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SpeedingsByDriverIdAndSpeedingDatetimeQuery>(
      response.data.speedingsByDriverIdAndSpeedingDatetime
    );
  }
  async GetFormQuestionTemplate(
    formTemplateId: string,
    formQuestionTemplateId: string
  ): Promise<GetFormQuestionTemplateQuery> {
    const statement = `query GetFormQuestionTemplate($formTemplateId: ID!, $formQuestionTemplateId: ID!) {
        getFormQuestionTemplate(
          formTemplateId: $formTemplateId
          formQuestionTemplateId: $formQuestionTemplateId
        ) {
          __typename
          formTemplateId
          formQuestionTemplateId
          question
          criticality
          weight
          order
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formTemplateId,
      formQuestionTemplateId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFormQuestionTemplateQuery>response.data.getFormQuestionTemplate;
  }
  async ListFormQuestionTemplates(
    formTemplateId?: string,
    formQuestionTemplateId?: ModelIDKeyConditionInput,
    filter?: ModelFormQuestionTemplateFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListFormQuestionTemplatesQuery> {
    const statement = `query ListFormQuestionTemplates($formTemplateId: ID, $formQuestionTemplateId: ModelIDKeyConditionInput, $filter: ModelFormQuestionTemplateFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listFormQuestionTemplates(
          formTemplateId: $formTemplateId
          formQuestionTemplateId: $formQuestionTemplateId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            formTemplateId
            formQuestionTemplateId
            question
            criticality
            weight
            order
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (formTemplateId) {
      gqlAPIServiceArguments.formTemplateId = formTemplateId;
    }
    if (formQuestionTemplateId) {
      gqlAPIServiceArguments.formQuestionTemplateId = formQuestionTemplateId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFormQuestionTemplatesQuery>(
      response.data.listFormQuestionTemplates
    );
  }
  async GetRozForm(formId: string, formDate: string): Promise<GetRozFormQuery> {
    const statement = `query GetRozForm($formId: ID!, $formDate: ID!) {
        getRozForm(formId: $formId, formDate: $formDate) {
          __typename
          formId
          formDate
          status
          comment
          createdBy
          updatedBy
          questions {
            __typename
            items {
              __typename
              formQuestionId
              question
              criticality
              weight
              order
              answer
              comment
              imageS3Path
              owner
              createdAt
              updatedAt
              rozFormQuestionsFormId
              rozFormQuestionsFormDate
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formId,
      formDate,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRozFormQuery>response.data.getRozForm;
  }
  async ListRozForms(
    formId?: string,
    formDate?: ModelIDKeyConditionInput,
    filter?: ModelRozFormFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListRozFormsQuery> {
    const statement = `query ListRozForms($formId: ID, $formDate: ModelIDKeyConditionInput, $filter: ModelRozFormFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listRozForms(
          formId: $formId
          formDate: $formDate
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (formId) {
      gqlAPIServiceArguments.formId = formId;
    }
    if (formDate) {
      gqlAPIServiceArguments.formDate = formDate;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRozFormsQuery>response.data.listRozForms;
  }
  async GetEvaluatedRozForm(
    formId: string,
    evaluatedAt: string
  ): Promise<GetEvaluatedRozFormQuery> {
    const statement = `query GetEvaluatedRozForm($formId: ID!, $evaluatedAt: ID!) {
        getEvaluatedRozForm(formId: $formId, evaluatedAt: $evaluatedAt) {
          __typename
          formId
          evaluatedAt
          business
          subBusiness
          carrier
          center
          formDate
          status
          comment
          createdBy
          updatedBy
          questions
          score
          s3Path
          sapId
          year
          type
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formId,
      evaluatedAt,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEvaluatedRozFormQuery>response.data.getEvaluatedRozForm;
  }
  async ListEvaluatedRozForms(
    formId?: string,
    evaluatedAt?: ModelIDKeyConditionInput,
    filter?: ModelEvaluatedRozFormFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListEvaluatedRozFormsQuery> {
    const statement = `query ListEvaluatedRozForms($formId: ID, $evaluatedAt: ModelIDKeyConditionInput, $filter: ModelEvaluatedRozFormFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listEvaluatedRozForms(
          formId: $formId
          evaluatedAt: $evaluatedAt
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            formId
            evaluatedAt
            business
            subBusiness
            carrier
            center
            formDate
            status
            comment
            createdBy
            updatedBy
            questions
            score
            s3Path
            sapId
            year
            type
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (formId) {
      gqlAPIServiceArguments.formId = formId;
    }
    if (evaluatedAt) {
      gqlAPIServiceArguments.evaluatedAt = evaluatedAt;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEvaluatedRozFormsQuery>response.data.listEvaluatedRozForms;
  }
  async EvaluatedRozFormsByBusinessAndEvaluatedAt(
    business: string,
    evaluatedAt?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEvaluatedRozFormFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EvaluatedRozFormsByBusinessAndEvaluatedAtQuery> {
    const statement = `query EvaluatedRozFormsByBusinessAndEvaluatedAt($business: ID!, $evaluatedAt: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEvaluatedRozFormFilterInput, $limit: Int, $nextToken: String) {
        evaluatedRozFormsByBusinessAndEvaluatedAt(
          business: $business
          evaluatedAt: $evaluatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            formId
            evaluatedAt
            business
            subBusiness
            carrier
            center
            formDate
            status
            comment
            createdBy
            updatedBy
            questions
            score
            s3Path
            sapId
            year
            type
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (evaluatedAt) {
      gqlAPIServiceArguments.evaluatedAt = evaluatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EvaluatedRozFormsByBusinessAndEvaluatedAtQuery>(
      response.data.evaluatedRozFormsByBusinessAndEvaluatedAt
    );
  }
  async EvaluatedRozFormsByCarrierAndEvaluatedAt(
    carrier: string,
    evaluatedAt?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEvaluatedRozFormFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EvaluatedRozFormsByCarrierAndEvaluatedAtQuery> {
    const statement = `query EvaluatedRozFormsByCarrierAndEvaluatedAt($carrier: String!, $evaluatedAt: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEvaluatedRozFormFilterInput, $limit: Int, $nextToken: String) {
        evaluatedRozFormsByCarrierAndEvaluatedAt(
          carrier: $carrier
          evaluatedAt: $evaluatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            formId
            evaluatedAt
            business
            subBusiness
            carrier
            center
            formDate
            status
            comment
            createdBy
            updatedBy
            questions
            score
            s3Path
            sapId
            year
            type
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      carrier,
    };
    if (evaluatedAt) {
      gqlAPIServiceArguments.evaluatedAt = evaluatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EvaluatedRozFormsByCarrierAndEvaluatedAtQuery>(
      response.data.evaluatedRozFormsByCarrierAndEvaluatedAt
    );
  }
  async EvaluatedRozFormsByCenterAndEvaluatedAt(
    center: string,
    evaluatedAt?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEvaluatedRozFormFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EvaluatedRozFormsByCenterAndEvaluatedAtQuery> {
    const statement = `query EvaluatedRozFormsByCenterAndEvaluatedAt($center: String!, $evaluatedAt: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEvaluatedRozFormFilterInput, $limit: Int, $nextToken: String) {
        evaluatedRozFormsByCenterAndEvaluatedAt(
          center: $center
          evaluatedAt: $evaluatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            formId
            evaluatedAt
            business
            subBusiness
            carrier
            center
            formDate
            status
            comment
            createdBy
            updatedBy
            questions
            score
            s3Path
            sapId
            year
            type
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (evaluatedAt) {
      gqlAPIServiceArguments.evaluatedAt = evaluatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EvaluatedRozFormsByCenterAndEvaluatedAtQuery>(
      response.data.evaluatedRozFormsByCenterAndEvaluatedAt
    );
  }
  async GetFormQuestion(formQuestionId: string): Promise<GetFormQuestionQuery> {
    const statement = `query GetFormQuestion($formQuestionId: ID!) {
        getFormQuestion(formQuestionId: $formQuestionId) {
          __typename
          formQuestionId
          question
          criticality
          weight
          order
          answer
          comment
          imageS3Path
          form {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          rozFormQuestionsFormId
          rozFormQuestionsFormDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formQuestionId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFormQuestionQuery>response.data.getFormQuestion;
  }
  async ListFormQuestions(
    formQuestionId?: string,
    filter?: ModelFormQuestionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListFormQuestionsQuery> {
    const statement = `query ListFormQuestions($formQuestionId: ID, $filter: ModelFormQuestionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listFormQuestions(
          formQuestionId: $formQuestionId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            formQuestionId
            question
            criticality
            weight
            order
            answer
            comment
            imageS3Path
            form {
              __typename
              formId
              formDate
              status
              comment
              createdBy
              updatedBy
              owner
              createdAt
              updatedAt
            }
            owner
            createdAt
            updatedAt
            rozFormQuestionsFormId
            rozFormQuestionsFormDate
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (formQuestionId) {
      gqlAPIServiceArguments.formQuestionId = formQuestionId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFormQuestionsQuery>response.data.listFormQuestions;
  }
  async GetEdition(editionId: string): Promise<GetEditionQuery> {
    const statement = `query GetEdition($editionId: ID!) {
        getEdition(editionId: $editionId) {
          __typename
          editionId
          active
          editor
          editorId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      editionId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEditionQuery>response.data.getEdition;
  }
  async ListEditions(
    editionId?: string,
    filter?: ModelEditionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListEditionsQuery> {
    const statement = `query ListEditions($editionId: ID, $filter: ModelEditionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listEditions(
          editionId: $editionId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            editionId
            active
            editor
            editorId
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (editionId) {
      gqlAPIServiceArguments.editionId = editionId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEditionsQuery>response.data.listEditions;
  }
  async GetMailPreference(
    userId: string,
    mailId: string
  ): Promise<GetMailPreferenceQuery> {
    const statement = `query GetMailPreference($userId: ID!, $mailId: ID!) {
        getMailPreference(userId: $userId, mailId: $mailId) {
          __typename
          userId
          mailId
          name
          receive
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      mailId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMailPreferenceQuery>response.data.getMailPreference;
  }
  async ListMailPreferences(
    userId?: string,
    mailId?: ModelIDKeyConditionInput,
    filter?: ModelMailPreferenceFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMailPreferencesQuery> {
    const statement = `query ListMailPreferences($userId: ID, $mailId: ModelIDKeyConditionInput, $filter: ModelMailPreferenceFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMailPreferences(
          userId: $userId
          mailId: $mailId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            userId
            mailId
            name
            receive
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (mailId) {
      gqlAPIServiceArguments.mailId = mailId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMailPreferencesQuery>response.data.listMailPreferences;
  }
  async GetDriverExclusion(driverId: string): Promise<GetDriverExclusionQuery> {
    const statement = `query GetDriverExclusion($driverId: ID!) {
        getDriverExclusion(driverId: $driverId) {
          __typename
          driverId
          startDate
          endDate
          exclusionMotives
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      driverId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDriverExclusionQuery>response.data.getDriverExclusion;
  }
  async ListDriverExclusions(
    driverId?: string,
    filter?: ModelDriverExclusionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListDriverExclusionsQuery> {
    const statement = `query ListDriverExclusions($driverId: ID, $filter: ModelDriverExclusionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listDriverExclusions(
          driverId: $driverId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            driverId
            startDate
            endDate
            exclusionMotives
            comment
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (driverId) {
      gqlAPIServiceArguments.driverId = driverId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDriverExclusionsQuery>response.data.listDriverExclusions;
  }
  async GetStatus(statusId: string): Promise<GetStatusQuery> {
    const statement = `query GetStatus($statusId: ID!) {
        getStatus(statusId: $statusId) {
          __typename
          statusId
          status
          startedAt
          endedAt
          business
          subBusiness
          carrier
          center
          modelTypeName
          type
          entityId
          sapId
          coupleId
          blockingMotives
          aboutToExpireDocuments
          expiredDocuments
          missingDocuments
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      statusId,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStatusQuery>response.data.getStatus;
  }
  async ListStatuses(
    statusId?: string,
    filter?: ModelStatusFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListStatusesQuery> {
    const statement = `query ListStatuses($statusId: ID, $filter: ModelStatusFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listStatuses(
          statusId: $statusId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            statusId
            status
            startedAt
            endedAt
            business
            subBusiness
            carrier
            center
            modelTypeName
            type
            entityId
            sapId
            coupleId
            blockingMotives
            aboutToExpireDocuments
            expiredDocuments
            missingDocuments
            comment
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (statusId) {
      gqlAPIServiceArguments.statusId = statusId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStatusesQuery>response.data.listStatuses;
  }
  async StatusesByBusinessAndStartedAt(
    business: string,
    startedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StatusesByBusinessAndStartedAtQuery> {
    const statement = `query StatusesByBusinessAndStartedAt($business: String!, $startedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelStatusFilterInput, $limit: Int, $nextToken: String) {
        statusesByBusinessAndStartedAt(
          business: $business
          startedAt: $startedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            statusId
            status
            startedAt
            endedAt
            business
            subBusiness
            carrier
            center
            modelTypeName
            type
            entityId
            sapId
            coupleId
            blockingMotives
            aboutToExpireDocuments
            expiredDocuments
            missingDocuments
            comment
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (startedAt) {
      gqlAPIServiceArguments.startedAt = startedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StatusesByBusinessAndStartedAtQuery>(
      response.data.statusesByBusinessAndStartedAt
    );
  }
  async StatusesByCarrierAndStartedAt(
    carrier: string,
    startedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StatusesByCarrierAndStartedAtQuery> {
    const statement = `query StatusesByCarrierAndStartedAt($carrier: String!, $startedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelStatusFilterInput, $limit: Int, $nextToken: String) {
        statusesByCarrierAndStartedAt(
          carrier: $carrier
          startedAt: $startedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            statusId
            status
            startedAt
            endedAt
            business
            subBusiness
            carrier
            center
            modelTypeName
            type
            entityId
            sapId
            coupleId
            blockingMotives
            aboutToExpireDocuments
            expiredDocuments
            missingDocuments
            comment
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      carrier,
    };
    if (startedAt) {
      gqlAPIServiceArguments.startedAt = startedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StatusesByCarrierAndStartedAtQuery>(
      response.data.statusesByCarrierAndStartedAt
    );
  }
  async StatusesByCenterAndStartedAt(
    center: string,
    startedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StatusesByCenterAndStartedAtQuery> {
    const statement = `query StatusesByCenterAndStartedAt($center: String!, $startedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelStatusFilterInput, $limit: Int, $nextToken: String) {
        statusesByCenterAndStartedAt(
          center: $center
          startedAt: $startedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            statusId
            status
            startedAt
            endedAt
            business
            subBusiness
            carrier
            center
            modelTypeName
            type
            entityId
            sapId
            coupleId
            blockingMotives
            aboutToExpireDocuments
            expiredDocuments
            missingDocuments
            comment
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (startedAt) {
      gqlAPIServiceArguments.startedAt = startedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StatusesByCenterAndStartedAtQuery>(
      response.data.statusesByCenterAndStartedAt
    );
  }
  async StatusesByEntityIdAndStartedAt(
    entityId: string,
    startedAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StatusesByEntityIdAndStartedAtQuery> {
    const statement = `query StatusesByEntityIdAndStartedAt($entityId: String!, $startedAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelStatusFilterInput, $limit: Int, $nextToken: String) {
        statusesByEntityIdAndStartedAt(
          entityId: $entityId
          startedAt: $startedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            statusId
            status
            startedAt
            endedAt
            business
            subBusiness
            carrier
            center
            modelTypeName
            type
            entityId
            sapId
            coupleId
            blockingMotives
            aboutToExpireDocuments
            expiredDocuments
            missingDocuments
            comment
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (startedAt) {
      gqlAPIServiceArguments.startedAt = startedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StatusesByEntityIdAndStartedAtQuery>(
      response.data.statusesByEntityIdAndStartedAt
    );
  }
  async GetArchive(
    archiveId: string,
    archivedAt: string
  ): Promise<GetArchiveQuery> {
    const statement = `query GetArchive($archiveId: ID!, $archivedAt: ID!) {
        getArchive(archiveId: $archiveId, archivedAt: $archivedAt) {
          __typename
          archiveId
          archivedAt
          archivedBy
          business
          carrier
          center
          modelTypeName
          motiveForArchiving
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          comment
          entity
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      archiveId,
      archivedAt,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetArchiveQuery>response.data.getArchive;
  }
  async ListArchives(
    archiveId?: string,
    archivedAt?: ModelIDKeyConditionInput,
    filter?: ModelArchiveFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListArchivesQuery> {
    const statement = `query ListArchives($archiveId: ID, $archivedAt: ModelIDKeyConditionInput, $filter: ModelArchiveFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listArchives(
          archiveId: $archiveId
          archivedAt: $archivedAt
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (archiveId) {
      gqlAPIServiceArguments.archiveId = archiveId;
    }
    if (archivedAt) {
      gqlAPIServiceArguments.archivedAt = archivedAt;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListArchivesQuery>response.data.listArchives;
  }
  async ArchivesByBusinessAndArchivedAt(
    business: string,
    archivedAt?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelArchiveFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ArchivesByBusinessAndArchivedAtQuery> {
    const statement = `query ArchivesByBusinessAndArchivedAt($business: String!, $archivedAt: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelArchiveFilterInput, $limit: Int, $nextToken: String) {
        archivesByBusinessAndArchivedAt(
          business: $business
          archivedAt: $archivedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      business,
    };
    if (archivedAt) {
      gqlAPIServiceArguments.archivedAt = archivedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ArchivesByBusinessAndArchivedAtQuery>(
      response.data.archivesByBusinessAndArchivedAt
    );
  }
  async ArchivesByCarrierAndArchivedAt(
    carrier: string,
    archivedAt?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelArchiveFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ArchivesByCarrierAndArchivedAtQuery> {
    const statement = `query ArchivesByCarrierAndArchivedAt($carrier: String!, $archivedAt: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelArchiveFilterInput, $limit: Int, $nextToken: String) {
        archivesByCarrierAndArchivedAt(
          carrier: $carrier
          archivedAt: $archivedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      carrier,
    };
    if (archivedAt) {
      gqlAPIServiceArguments.archivedAt = archivedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ArchivesByCarrierAndArchivedAtQuery>(
      response.data.archivesByCarrierAndArchivedAt
    );
  }
  async ArchivesByCenterAndArchivedAt(
    center: string,
    archivedAt?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelArchiveFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ArchivesByCenterAndArchivedAtQuery> {
    const statement = `query ArchivesByCenterAndArchivedAt($center: String!, $archivedAt: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelArchiveFilterInput, $limit: Int, $nextToken: String) {
        archivesByCenterAndArchivedAt(
          center: $center
          archivedAt: $archivedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      center,
    };
    if (archivedAt) {
      gqlAPIServiceArguments.archivedAt = archivedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ArchivesByCenterAndArchivedAtQuery>(
      response.data.archivesByCenterAndArchivedAt
    );
  }
  OnCreateCarrierListener(
    filter?: ModelSubscriptionCarrierFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateCarrier'>>
  > {
    const statement = `subscription OnCreateCarrier($filter: ModelSubscriptionCarrierFilterInput) {
        onCreateCarrier(filter: $filter) {
          __typename
          carrierId
          name
          rut
          email
          additionalEmails
          vehicles {
            __typename
            items {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            nextToken
          }
          envasados {
            __typename
            items {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            nextToken
          }
          cisternas {
            __typename
            items {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            nextToken
          }
          tractos {
            __typename
            items {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            nextToken
          }
          tanques {
            __typename
            items {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            nextToken
          }
          semirremolques {
            __typename
            items {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            nextToken
          }
          drivers {
            __typename
            items {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateCarrier'>>
    >;
  }

  OnUpdateCarrierListener(
    filter?: ModelSubscriptionCarrierFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateCarrier'>>
  > {
    const statement = `subscription OnUpdateCarrier($filter: ModelSubscriptionCarrierFilterInput) {
        onUpdateCarrier(filter: $filter) {
          __typename
          carrierId
          name
          rut
          email
          additionalEmails
          vehicles {
            __typename
            items {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            nextToken
          }
          envasados {
            __typename
            items {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            nextToken
          }
          cisternas {
            __typename
            items {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            nextToken
          }
          tractos {
            __typename
            items {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            nextToken
          }
          tanques {
            __typename
            items {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            nextToken
          }
          semirremolques {
            __typename
            items {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            nextToken
          }
          drivers {
            __typename
            items {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateCarrier'>>
    >;
  }

  OnDeleteCarrierListener(
    filter?: ModelSubscriptionCarrierFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteCarrier'>>
  > {
    const statement = `subscription OnDeleteCarrier($filter: ModelSubscriptionCarrierFilterInput) {
        onDeleteCarrier(filter: $filter) {
          __typename
          carrierId
          name
          rut
          email
          additionalEmails
          vehicles {
            __typename
            items {
              __typename
              vehicleId
              brand
              licensePlate
              color
              model
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              imei
              cameraImei
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              isSpot
              sapId
              trailerLicensePlate
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierVehiclesCarrierId
            }
            nextToken
          }
          envasados {
            __typename
            items {
              __typename
              envasadoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              engineType
              hasRanchAccreditation
              isSpot
              bodyType
              maximumPalletCapacity
              height
              width
              length
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierEnvasadosCarrierId
            }
            nextToken
          }
          cisternas {
            __typename
            items {
              __typename
              cisternaId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierCisternasCarrierId
            }
            nextToken
          }
          tractos {
            __typename
            items {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            nextToken
          }
          tanques {
            __typename
            items {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            nextToken
          }
          semirremolques {
            __typename
            items {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            nextToken
          }
          drivers {
            __typename
            items {
              __typename
              driverId
              firstName
              lastName
              rut
              birthDate
              phone
              email
              business
              center
              originalCenter
              comment
              blockingMotives
              documentsToExpire
              documentsExpired
              status
              lastStatusInformed
              lastStatusInformedAt
              sapId
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierDriversCarrierId
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteCarrier'>>
    >;
  }

  OnCreateVehicleListener(
    filter?: ModelSubscriptionVehicleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateVehicle'>>
  > {
    const statement = `subscription OnCreateVehicle($filter: ModelSubscriptionVehicleFilterInput) {
        onCreateVehicle(filter: $filter) {
          __typename
          vehicleId
          brand
          licensePlate
          color
          model
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          imei
          cameraImei
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          isSpot
          sapId
          trailerLicensePlate
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierVehiclesCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateVehicle'>>
    >;
  }

  OnUpdateVehicleListener(
    filter?: ModelSubscriptionVehicleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateVehicle'>>
  > {
    const statement = `subscription OnUpdateVehicle($filter: ModelSubscriptionVehicleFilterInput) {
        onUpdateVehicle(filter: $filter) {
          __typename
          vehicleId
          brand
          licensePlate
          color
          model
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          imei
          cameraImei
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          isSpot
          sapId
          trailerLicensePlate
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierVehiclesCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateVehicle'>>
    >;
  }

  OnDeleteVehicleListener(
    filter?: ModelSubscriptionVehicleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteVehicle'>>
  > {
    const statement = `subscription OnDeleteVehicle($filter: ModelSubscriptionVehicleFilterInput) {
        onDeleteVehicle(filter: $filter) {
          __typename
          vehicleId
          brand
          licensePlate
          color
          model
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          imei
          cameraImei
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          isSpot
          sapId
          trailerLicensePlate
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierVehiclesCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteVehicle'>>
    >;
  }

  OnCreateEnvasadoListener(
    filter?: ModelSubscriptionEnvasadoFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateEnvasado'>>
  > {
    const statement = `subscription OnCreateEnvasado($filter: ModelSubscriptionEnvasadoFilterInput) {
        onCreateEnvasado(filter: $filter) {
          __typename
          envasadoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          engineType
          hasRanchAccreditation
          isSpot
          bodyType
          maximumPalletCapacity
          height
          width
          length
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierEnvasadosCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateEnvasado'>>
    >;
  }

  OnUpdateEnvasadoListener(
    filter?: ModelSubscriptionEnvasadoFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateEnvasado'>>
  > {
    const statement = `subscription OnUpdateEnvasado($filter: ModelSubscriptionEnvasadoFilterInput) {
        onUpdateEnvasado(filter: $filter) {
          __typename
          envasadoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          engineType
          hasRanchAccreditation
          isSpot
          bodyType
          maximumPalletCapacity
          height
          width
          length
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierEnvasadosCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateEnvasado'>>
    >;
  }

  OnDeleteEnvasadoListener(
    filter?: ModelSubscriptionEnvasadoFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteEnvasado'>>
  > {
    const statement = `subscription OnDeleteEnvasado($filter: ModelSubscriptionEnvasadoFilterInput) {
        onDeleteEnvasado(filter: $filter) {
          __typename
          envasadoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          engineType
          hasRanchAccreditation
          isSpot
          bodyType
          maximumPalletCapacity
          height
          width
          length
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierEnvasadosCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteEnvasado'>>
    >;
  }

  OnCreateCisternaListener(
    filter?: ModelSubscriptionCisternaFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateCisterna'>>
  > {
    const statement = `subscription OnCreateCisterna($filter: ModelSubscriptionCisternaFilterInput) {
        onCreateCisterna(filter: $filter) {
          __typename
          cisternaId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierCisternasCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateCisterna'>>
    >;
  }

  OnUpdateCisternaListener(
    filter?: ModelSubscriptionCisternaFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateCisterna'>>
  > {
    const statement = `subscription OnUpdateCisterna($filter: ModelSubscriptionCisternaFilterInput) {
        onUpdateCisterna(filter: $filter) {
          __typename
          cisternaId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierCisternasCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateCisterna'>>
    >;
  }

  OnDeleteCisternaListener(
    filter?: ModelSubscriptionCisternaFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteCisterna'>>
  > {
    const statement = `subscription OnDeleteCisterna($filter: ModelSubscriptionCisternaFilterInput) {
        onDeleteCisterna(filter: $filter) {
          __typename
          cisternaId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierCisternasCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteCisterna'>>
    >;
  }

  OnCreateTractoListener(
    filter?: ModelSubscriptionTractoFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateTracto'>>
  > {
    const statement = `subscription OnCreateTracto($filter: ModelSubscriptionTractoFilterInput) {
        onCreateTracto(filter: $filter) {
          __typename
          tractoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTractosCarrierId
          tractoCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateTracto'>>
    >;
  }

  OnUpdateTractoListener(
    filter?: ModelSubscriptionTractoFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateTracto'>>
  > {
    const statement = `subscription OnUpdateTracto($filter: ModelSubscriptionTractoFilterInput) {
        onUpdateTracto(filter: $filter) {
          __typename
          tractoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTractosCarrierId
          tractoCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateTracto'>>
    >;
  }

  OnDeleteTractoListener(
    filter?: ModelSubscriptionTractoFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteTracto'>>
  > {
    const statement = `subscription OnDeleteTracto($filter: ModelSubscriptionTractoFilterInput) {
        onDeleteTracto(filter: $filter) {
          __typename
          tractoId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          business
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          engineType
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTractosCarrierId
          tractoCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteTracto'>>
    >;
  }

  OnCreateTanqueListener(
    filter?: ModelSubscriptionTanqueFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateTanque'>>
  > {
    const statement = `subscription OnCreateTanque($filter: ModelSubscriptionTanqueFilterInput) {
        onCreateTanque(filter: $filter) {
          __typename
          tanqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTanquesCarrierId
          tanqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateTanque'>>
    >;
  }

  OnUpdateTanqueListener(
    filter?: ModelSubscriptionTanqueFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateTanque'>>
  > {
    const statement = `subscription OnUpdateTanque($filter: ModelSubscriptionTanqueFilterInput) {
        onUpdateTanque(filter: $filter) {
          __typename
          tanqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTanquesCarrierId
          tanqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateTanque'>>
    >;
  }

  OnDeleteTanqueListener(
    filter?: ModelSubscriptionTanqueFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteTanque'>>
  > {
    const statement = `subscription OnDeleteTanque($filter: ModelSubscriptionTanqueFilterInput) {
        onDeleteTanque(filter: $filter) {
          __typename
          tanqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          bulkheadType
          hasPump
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierTanquesCarrierId
          tanqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteTanque'>>
    >;
  }

  OnCreateSemirremolqueListener(
    filter?: ModelSubscriptionSemirremolqueFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateSemirremolque'>>
  > {
    const statement = `subscription OnCreateSemirremolque($filter: ModelSubscriptionSemirremolqueFilterInput) {
        onCreateSemirremolque(filter: $filter) {
          __typename
          semirremolqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          maximumPalletCapacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          rampType
          MOPCode
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierSemirremolquesCarrierId
          semirremolqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateSemirremolque'>
      >
    >;
  }

  OnUpdateSemirremolqueListener(
    filter?: ModelSubscriptionSemirremolqueFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateSemirremolque'>>
  > {
    const statement = `subscription OnUpdateSemirremolque($filter: ModelSubscriptionSemirremolqueFilterInput) {
        onUpdateSemirremolque(filter: $filter) {
          __typename
          semirremolqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          maximumPalletCapacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          rampType
          MOPCode
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierSemirremolquesCarrierId
          semirremolqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateSemirremolque'>
      >
    >;
  }

  OnDeleteSemirremolqueListener(
    filter?: ModelSubscriptionSemirremolqueFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteSemirremolque'>>
  > {
    const statement = `subscription OnDeleteSemirremolque($filter: ModelSubscriptionSemirremolqueFilterInput) {
        onDeleteSemirremolque(filter: $filter) {
          __typename
          semirremolqueId
          sapId
          brand
          licensePlate
          model
          status
          lastStatusInformed
          lastStatusInformedAt
          color
          year
          type
          capacity
          maximumPalletCapacity
          height
          width
          length
          business
          subBusiness
          center
          originalCenter
          comment
          gpsImei
          cameraImei
          blockingMotives
          forMining
          hasRanchAccreditation
          isSpot
          rampType
          MOPCode
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          documentsToExpire
          documentsExpired
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          couple {
            __typename
            coupleId
            business
            center
            comment
            company
            status
            tracto {
              __typename
              tractoId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              business
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              engineType
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTractosCarrierId
              tractoCoupleCoupleId
            }
            tanque {
              __typename
              tanqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              bulkheadType
              hasPump
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierTanquesCarrierId
              tanqueCoupleCoupleId
            }
            semirremolque {
              __typename
              semirremolqueId
              sapId
              brand
              licensePlate
              model
              status
              lastStatusInformed
              lastStatusInformedAt
              color
              year
              type
              capacity
              maximumPalletCapacity
              height
              width
              length
              business
              subBusiness
              center
              originalCenter
              comment
              gpsImei
              cameraImei
              blockingMotives
              forMining
              hasRanchAccreditation
              isSpot
              rampType
              MOPCode
              documentsToExpire
              documentsExpired
              company
              updatedBy
              archiveId
              owner
              createdAt
              updatedAt
              carrierSemirremolquesCarrierId
              semirremolqueCoupleCoupleId
            }
            owner
            createdAt
            updatedAt
            coupleTractoTractoId
            coupleTanqueTanqueId
            coupleSemirremolqueSemirremolqueId
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierSemirremolquesCarrierId
          semirremolqueCoupleCoupleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteSemirremolque'>
      >
    >;
  }

  OnCreateCoupleListener(
    filter?: ModelSubscriptionCoupleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateCouple'>>
  > {
    const statement = `subscription OnCreateCouple($filter: ModelSubscriptionCoupleFilterInput) {
        onCreateCouple(filter: $filter) {
          __typename
          coupleId
          business
          center
          comment
          company
          status
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          owner
          createdAt
          updatedAt
          coupleTractoTractoId
          coupleTanqueTanqueId
          coupleSemirremolqueSemirremolqueId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateCouple'>>
    >;
  }

  OnUpdateCoupleListener(
    filter?: ModelSubscriptionCoupleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateCouple'>>
  > {
    const statement = `subscription OnUpdateCouple($filter: ModelSubscriptionCoupleFilterInput) {
        onUpdateCouple(filter: $filter) {
          __typename
          coupleId
          business
          center
          comment
          company
          status
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          owner
          createdAt
          updatedAt
          coupleTractoTractoId
          coupleTanqueTanqueId
          coupleSemirremolqueSemirremolqueId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateCouple'>>
    >;
  }

  OnDeleteCoupleListener(
    filter?: ModelSubscriptionCoupleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteCouple'>>
  > {
    const statement = `subscription OnDeleteCouple($filter: ModelSubscriptionCoupleFilterInput) {
        onDeleteCouple(filter: $filter) {
          __typename
          coupleId
          business
          center
          comment
          company
          status
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          owner
          createdAt
          updatedAt
          coupleTractoTractoId
          coupleTanqueTanqueId
          coupleSemirremolqueSemirremolqueId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteCouple'>>
    >;
  }

  OnCreateDriverListener(
    filter?: ModelSubscriptionDriverFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateDriver'>>
  > {
    const statement = `subscription OnCreateDriver($filter: ModelSubscriptionDriverFilterInput) {
        onCreateDriver(filter: $filter) {
          __typename
          driverId
          firstName
          lastName
          rut
          birthDate
          phone
          email
          business
          center
          originalCenter
          comment
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          sapId
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          speedings {
            __typename
            items {
              __typename
              speedingId
              speedingDatetime
              latitude
              longitude
              speedLimit
              actualSpeed
              counted
              driverId
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierDriversCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateDriver'>>
    >;
  }

  OnUpdateDriverListener(
    filter?: ModelSubscriptionDriverFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateDriver'>>
  > {
    const statement = `subscription OnUpdateDriver($filter: ModelSubscriptionDriverFilterInput) {
        onUpdateDriver(filter: $filter) {
          __typename
          driverId
          firstName
          lastName
          rut
          birthDate
          phone
          email
          business
          center
          originalCenter
          comment
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          sapId
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          speedings {
            __typename
            items {
              __typename
              speedingId
              speedingDatetime
              latitude
              longitude
              speedLimit
              actualSpeed
              counted
              driverId
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierDriversCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateDriver'>>
    >;
  }

  OnDeleteDriverListener(
    filter?: ModelSubscriptionDriverFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteDriver'>>
  > {
    const statement = `subscription OnDeleteDriver($filter: ModelSubscriptionDriverFilterInput) {
        onDeleteDriver(filter: $filter) {
          __typename
          driverId
          firstName
          lastName
          rut
          birthDate
          phone
          email
          business
          center
          originalCenter
          comment
          blockingMotives
          documentsToExpire
          documentsExpired
          status
          lastStatusInformed
          lastStatusInformedAt
          sapId
          company
          carrier {
            __typename
            carrierId
            name
            rut
            email
            additionalEmails
            vehicles {
              __typename
              nextToken
            }
            envasados {
              __typename
              nextToken
            }
            cisternas {
              __typename
              nextToken
            }
            tractos {
              __typename
              nextToken
            }
            tanques {
              __typename
              nextToken
            }
            semirremolques {
              __typename
              nextToken
            }
            drivers {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          speedings {
            __typename
            items {
              __typename
              speedingId
              speedingDatetime
              latitude
              longitude
              speedLimit
              actualSpeed
              counted
              driverId
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedBy
          archiveId
          owner
          createdAt
          updatedAt
          carrierDriversCarrierId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteDriver'>>
    >;
  }

  OnCreateDocumentListener(
    filter?: ModelSubscriptionDocumentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateDocument'>>
  > {
    const statement = `subscription OnCreateDocument($filter: ModelSubscriptionDocumentFilterInput) {
        onCreateDocument(filter: $filter) {
          __typename
          documentId
          masterValueId
          name
          status
          s3Path
          issueDate
          expirationDate
          vehicle {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          envasado {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          cisterna {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          driver {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          archive {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          vehicleDocumentsVehicleId
          envasadoDocumentsEnvasadoId
          cisternaDocumentsCisternaId
          tractoDocumentsTractoId
          tanqueDocumentsTanqueId
          semirremolqueDocumentsSemirremolqueId
          driverDocumentsDriverId
          archiveDocumentsArchiveId
          archiveDocumentsArchivedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateDocument'>>
    >;
  }

  OnUpdateDocumentListener(
    filter?: ModelSubscriptionDocumentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateDocument'>>
  > {
    const statement = `subscription OnUpdateDocument($filter: ModelSubscriptionDocumentFilterInput) {
        onUpdateDocument(filter: $filter) {
          __typename
          documentId
          masterValueId
          name
          status
          s3Path
          issueDate
          expirationDate
          vehicle {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          envasado {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          cisterna {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          driver {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          archive {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          vehicleDocumentsVehicleId
          envasadoDocumentsEnvasadoId
          cisternaDocumentsCisternaId
          tractoDocumentsTractoId
          tanqueDocumentsTanqueId
          semirremolqueDocumentsSemirremolqueId
          driverDocumentsDriverId
          archiveDocumentsArchiveId
          archiveDocumentsArchivedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateDocument'>>
    >;
  }

  OnDeleteDocumentListener(
    filter?: ModelSubscriptionDocumentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteDocument'>>
  > {
    const statement = `subscription OnDeleteDocument($filter: ModelSubscriptionDocumentFilterInput) {
        onDeleteDocument(filter: $filter) {
          __typename
          documentId
          masterValueId
          name
          status
          s3Path
          issueDate
          expirationDate
          vehicle {
            __typename
            vehicleId
            brand
            licensePlate
            color
            model
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            imei
            cameraImei
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            isSpot
            sapId
            trailerLicensePlate
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierVehiclesCarrierId
          }
          envasado {
            __typename
            envasadoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            engineType
            hasRanchAccreditation
            isSpot
            bodyType
            maximumPalletCapacity
            height
            width
            length
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierEnvasadosCarrierId
          }
          cisterna {
            __typename
            cisternaId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierCisternasCarrierId
          }
          tracto {
            __typename
            tractoId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            business
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            engineType
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTractosCarrierId
            tractoCoupleCoupleId
          }
          tanque {
            __typename
            tanqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            bulkheadType
            hasPump
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierTanquesCarrierId
            tanqueCoupleCoupleId
          }
          semirremolque {
            __typename
            semirremolqueId
            sapId
            brand
            licensePlate
            model
            status
            lastStatusInformed
            lastStatusInformedAt
            color
            year
            type
            capacity
            maximumPalletCapacity
            height
            width
            length
            business
            subBusiness
            center
            originalCenter
            comment
            gpsImei
            cameraImei
            blockingMotives
            forMining
            hasRanchAccreditation
            isSpot
            rampType
            MOPCode
            documents {
              __typename
              nextToken
            }
            documentsToExpire
            documentsExpired
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            couple {
              __typename
              coupleId
              business
              center
              comment
              company
              status
              owner
              createdAt
              updatedAt
              coupleTractoTractoId
              coupleTanqueTanqueId
              coupleSemirremolqueSemirremolqueId
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierSemirremolquesCarrierId
            semirremolqueCoupleCoupleId
          }
          driver {
            __typename
            driverId
            firstName
            lastName
            rut
            birthDate
            phone
            email
            business
            center
            originalCenter
            comment
            blockingMotives
            documentsToExpire
            documentsExpired
            status
            lastStatusInformed
            lastStatusInformedAt
            sapId
            company
            carrier {
              __typename
              carrierId
              name
              rut
              email
              additionalEmails
              owner
              createdAt
              updatedAt
            }
            documents {
              __typename
              nextToken
            }
            speedings {
              __typename
              nextToken
            }
            updatedBy
            archiveId
            owner
            createdAt
            updatedAt
            carrierDriversCarrierId
          }
          archive {
            __typename
            archiveId
            archivedAt
            archivedBy
            business
            carrier
            center
            modelTypeName
            motiveForArchiving
            documents {
              __typename
              nextToken
            }
            comment
            entity
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          vehicleDocumentsVehicleId
          envasadoDocumentsEnvasadoId
          cisternaDocumentsCisternaId
          tractoDocumentsTractoId
          tanqueDocumentsTanqueId
          semirremolqueDocumentsSemirremolqueId
          driverDocumentsDriverId
          archiveDocumentsArchiveId
          archiveDocumentsArchivedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteDocument'>>
    >;
  }

  OnCreateMasterListener(
    filter?: ModelSubscriptionMasterFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateMaster'>>
  > {
    const statement = `subscription OnCreateMaster($filter: ModelSubscriptionMasterFilterInput) {
        onCreateMaster(filter: $filter) {
          __typename
          businessId
          masterId
          masterGroup
          valueId
          valueToDisplay
          sapId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateMaster'>>
    >;
  }

  OnUpdateMasterListener(
    filter?: ModelSubscriptionMasterFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateMaster'>>
  > {
    const statement = `subscription OnUpdateMaster($filter: ModelSubscriptionMasterFilterInput) {
        onUpdateMaster(filter: $filter) {
          __typename
          businessId
          masterId
          masterGroup
          valueId
          valueToDisplay
          sapId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateMaster'>>
    >;
  }

  OnDeleteMasterListener(
    filter?: ModelSubscriptionMasterFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteMaster'>>
  > {
    const statement = `subscription OnDeleteMaster($filter: ModelSubscriptionMasterFilterInput) {
        onDeleteMaster(filter: $filter) {
          __typename
          businessId
          masterId
          masterGroup
          valueId
          valueToDisplay
          sapId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteMaster'>>
    >;
  }

  OnCreateDocumentSpecsListener(
    filter?: ModelSubscriptionDocumentSpecsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateDocumentSpecs'>>
  > {
    const statement = `subscription OnCreateDocumentSpecs($filter: ModelSubscriptionDocumentSpecsFilterInput) {
        onCreateDocumentSpecs(filter: $filter) {
          __typename
          businessId
          documentSpecsId
          isMandatory
          canBlock
          extensionDays
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateDocumentSpecs'>
      >
    >;
  }

  OnUpdateDocumentSpecsListener(
    filter?: ModelSubscriptionDocumentSpecsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateDocumentSpecs'>>
  > {
    const statement = `subscription OnUpdateDocumentSpecs($filter: ModelSubscriptionDocumentSpecsFilterInput) {
        onUpdateDocumentSpecs(filter: $filter) {
          __typename
          businessId
          documentSpecsId
          isMandatory
          canBlock
          extensionDays
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateDocumentSpecs'>
      >
    >;
  }

  OnDeleteDocumentSpecsListener(
    filter?: ModelSubscriptionDocumentSpecsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteDocumentSpecs'>>
  > {
    const statement = `subscription OnDeleteDocumentSpecs($filter: ModelSubscriptionDocumentSpecsFilterInput) {
        onDeleteDocumentSpecs(filter: $filter) {
          __typename
          businessId
          documentSpecsId
          isMandatory
          canBlock
          extensionDays
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteDocumentSpecs'>
      >
    >;
  }

  OnCreateUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateUser'>>
  > {
    const statement = `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
        onCreateUser(filter: $filter) {
          __typename
          userId
          firstName
          lastName
          rut
          phone
          email
          business
          hasAccessTo
          authGroup
          status
          company
          centers
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateUser'>>
    >;
  }

  OnUpdateUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateUser'>>
  > {
    const statement = `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
        onUpdateUser(filter: $filter) {
          __typename
          userId
          firstName
          lastName
          rut
          phone
          email
          business
          hasAccessTo
          authGroup
          status
          company
          centers
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateUser'>>
    >;
  }

  OnDeleteUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteUser'>>
  > {
    const statement = `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
        onDeleteUser(filter: $filter) {
          __typename
          userId
          firstName
          lastName
          rut
          phone
          email
          business
          hasAccessTo
          authGroup
          status
          company
          centers
          updatedBy
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteUser'>>
    >;
  }

  OnCreateNotificationListener(
    filter?: ModelSubscriptionNotificationFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateNotification'>>
  > {
    const statement = `subscription OnCreateNotification($filter: ModelSubscriptionNotificationFilterInput) {
        onCreateNotification(filter: $filter) {
          __typename
          businessId
          notificationId
          status
          retries
          motive
          model
          vehicleId
          driverId
          envasadoId
          cisternaId
          tractoId
          tanqueId
          semirremolqueId
          userId
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateNotification'>
      >
    >;
  }

  OnUpdateNotificationListener(
    filter?: ModelSubscriptionNotificationFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateNotification'>>
  > {
    const statement = `subscription OnUpdateNotification($filter: ModelSubscriptionNotificationFilterInput) {
        onUpdateNotification(filter: $filter) {
          __typename
          businessId
          notificationId
          status
          retries
          motive
          model
          vehicleId
          driverId
          envasadoId
          cisternaId
          tractoId
          tanqueId
          semirremolqueId
          userId
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateNotification'>
      >
    >;
  }

  OnDeleteNotificationListener(
    filter?: ModelSubscriptionNotificationFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteNotification'>>
  > {
    const statement = `subscription OnDeleteNotification($filter: ModelSubscriptionNotificationFilterInput) {
        onDeleteNotification(filter: $filter) {
          __typename
          businessId
          notificationId
          status
          retries
          motive
          model
          vehicleId
          driverId
          envasadoId
          cisternaId
          tractoId
          tanqueId
          semirremolqueId
          userId
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteNotification'>
      >
    >;
  }

  OnCreateMailListener(
    filter?: ModelSubscriptionMailFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateMail'>>
  > {
    const statement = `subscription OnCreateMail($filter: ModelSubscriptionMailFilterInput) {
        onCreateMail(filter: $filter) {
          __typename
          businessId
          mailId
          receivers
          title
          content
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateMail'>>
    >;
  }

  OnUpdateMailListener(
    filter?: ModelSubscriptionMailFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateMail'>>
  > {
    const statement = `subscription OnUpdateMail($filter: ModelSubscriptionMailFilterInput) {
        onUpdateMail(filter: $filter) {
          __typename
          businessId
          mailId
          receivers
          title
          content
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateMail'>>
    >;
  }

  OnDeleteMailListener(
    filter?: ModelSubscriptionMailFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteMail'>>
  > {
    const statement = `subscription OnDeleteMail($filter: ModelSubscriptionMailFilterInput) {
        onDeleteMail(filter: $filter) {
          __typename
          businessId
          mailId
          receivers
          title
          content
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteMail'>>
    >;
  }

  OnCreateSpeedingListener(
    filter?: ModelSubscriptionSpeedingFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateSpeeding'>>
  > {
    const statement = `subscription OnCreateSpeeding($filter: ModelSubscriptionSpeedingFilterInput) {
        onCreateSpeeding(filter: $filter) {
          __typename
          speedingId
          speedingDatetime
          latitude
          longitude
          speedLimit
          actualSpeed
          counted
          driverId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateSpeeding'>>
    >;
  }

  OnUpdateSpeedingListener(
    filter?: ModelSubscriptionSpeedingFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateSpeeding'>>
  > {
    const statement = `subscription OnUpdateSpeeding($filter: ModelSubscriptionSpeedingFilterInput) {
        onUpdateSpeeding(filter: $filter) {
          __typename
          speedingId
          speedingDatetime
          latitude
          longitude
          speedLimit
          actualSpeed
          counted
          driverId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateSpeeding'>>
    >;
  }

  OnDeleteSpeedingListener(
    filter?: ModelSubscriptionSpeedingFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteSpeeding'>>
  > {
    const statement = `subscription OnDeleteSpeeding($filter: ModelSubscriptionSpeedingFilterInput) {
        onDeleteSpeeding(filter: $filter) {
          __typename
          speedingId
          speedingDatetime
          latitude
          longitude
          speedLimit
          actualSpeed
          counted
          driverId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteSpeeding'>>
    >;
  }

  OnCreateFormQuestionTemplateListener(
    filter?: ModelSubscriptionFormQuestionTemplateFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onCreateFormQuestionTemplate'>
    >
  > {
    const statement = `subscription OnCreateFormQuestionTemplate($filter: ModelSubscriptionFormQuestionTemplateFilterInput) {
        onCreateFormQuestionTemplate(filter: $filter) {
          __typename
          formTemplateId
          formQuestionTemplateId
          question
          criticality
          weight
          order
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateFormQuestionTemplate'>
      >
    >;
  }

  OnUpdateFormQuestionTemplateListener(
    filter?: ModelSubscriptionFormQuestionTemplateFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onUpdateFormQuestionTemplate'>
    >
  > {
    const statement = `subscription OnUpdateFormQuestionTemplate($filter: ModelSubscriptionFormQuestionTemplateFilterInput) {
        onUpdateFormQuestionTemplate(filter: $filter) {
          __typename
          formTemplateId
          formQuestionTemplateId
          question
          criticality
          weight
          order
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateFormQuestionTemplate'>
      >
    >;
  }

  OnDeleteFormQuestionTemplateListener(
    filter?: ModelSubscriptionFormQuestionTemplateFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onDeleteFormQuestionTemplate'>
    >
  > {
    const statement = `subscription OnDeleteFormQuestionTemplate($filter: ModelSubscriptionFormQuestionTemplateFilterInput) {
        onDeleteFormQuestionTemplate(filter: $filter) {
          __typename
          formTemplateId
          formQuestionTemplateId
          question
          criticality
          weight
          order
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteFormQuestionTemplate'>
      >
    >;
  }

  OnCreateRozFormListener(
    filter?: ModelSubscriptionRozFormFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateRozForm'>>
  > {
    const statement = `subscription OnCreateRozForm($filter: ModelSubscriptionRozFormFilterInput) {
        onCreateRozForm(filter: $filter) {
          __typename
          formId
          formDate
          status
          comment
          createdBy
          updatedBy
          questions {
            __typename
            items {
              __typename
              formQuestionId
              question
              criticality
              weight
              order
              answer
              comment
              imageS3Path
              owner
              createdAt
              updatedAt
              rozFormQuestionsFormId
              rozFormQuestionsFormDate
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateRozForm'>>
    >;
  }

  OnUpdateRozFormListener(
    filter?: ModelSubscriptionRozFormFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateRozForm'>>
  > {
    const statement = `subscription OnUpdateRozForm($filter: ModelSubscriptionRozFormFilterInput) {
        onUpdateRozForm(filter: $filter) {
          __typename
          formId
          formDate
          status
          comment
          createdBy
          updatedBy
          questions {
            __typename
            items {
              __typename
              formQuestionId
              question
              criticality
              weight
              order
              answer
              comment
              imageS3Path
              owner
              createdAt
              updatedAt
              rozFormQuestionsFormId
              rozFormQuestionsFormDate
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateRozForm'>>
    >;
  }

  OnDeleteRozFormListener(
    filter?: ModelSubscriptionRozFormFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteRozForm'>>
  > {
    const statement = `subscription OnDeleteRozForm($filter: ModelSubscriptionRozFormFilterInput) {
        onDeleteRozForm(filter: $filter) {
          __typename
          formId
          formDate
          status
          comment
          createdBy
          updatedBy
          questions {
            __typename
            items {
              __typename
              formQuestionId
              question
              criticality
              weight
              order
              answer
              comment
              imageS3Path
              owner
              createdAt
              updatedAt
              rozFormQuestionsFormId
              rozFormQuestionsFormDate
            }
            nextToken
          }
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteRozForm'>>
    >;
  }

  OnCreateEvaluatedRozFormListener(
    filter?: ModelSubscriptionEvaluatedRozFormFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onCreateEvaluatedRozForm'>
    >
  > {
    const statement = `subscription OnCreateEvaluatedRozForm($filter: ModelSubscriptionEvaluatedRozFormFilterInput) {
        onCreateEvaluatedRozForm(filter: $filter) {
          __typename
          formId
          evaluatedAt
          business
          subBusiness
          carrier
          center
          formDate
          status
          comment
          createdBy
          updatedBy
          questions
          score
          s3Path
          sapId
          year
          type
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateEvaluatedRozForm'>
      >
    >;
  }

  OnUpdateEvaluatedRozFormListener(
    filter?: ModelSubscriptionEvaluatedRozFormFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onUpdateEvaluatedRozForm'>
    >
  > {
    const statement = `subscription OnUpdateEvaluatedRozForm($filter: ModelSubscriptionEvaluatedRozFormFilterInput) {
        onUpdateEvaluatedRozForm(filter: $filter) {
          __typename
          formId
          evaluatedAt
          business
          subBusiness
          carrier
          center
          formDate
          status
          comment
          createdBy
          updatedBy
          questions
          score
          s3Path
          sapId
          year
          type
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateEvaluatedRozForm'>
      >
    >;
  }

  OnDeleteEvaluatedRozFormListener(
    filter?: ModelSubscriptionEvaluatedRozFormFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onDeleteEvaluatedRozForm'>
    >
  > {
    const statement = `subscription OnDeleteEvaluatedRozForm($filter: ModelSubscriptionEvaluatedRozFormFilterInput) {
        onDeleteEvaluatedRozForm(filter: $filter) {
          __typename
          formId
          evaluatedAt
          business
          subBusiness
          carrier
          center
          formDate
          status
          comment
          createdBy
          updatedBy
          questions
          score
          s3Path
          sapId
          year
          type
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteEvaluatedRozForm'>
      >
    >;
  }

  OnCreateFormQuestionListener(
    filter?: ModelSubscriptionFormQuestionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateFormQuestion'>>
  > {
    const statement = `subscription OnCreateFormQuestion($filter: ModelSubscriptionFormQuestionFilterInput) {
        onCreateFormQuestion(filter: $filter) {
          __typename
          formQuestionId
          question
          criticality
          weight
          order
          answer
          comment
          imageS3Path
          form {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          rozFormQuestionsFormId
          rozFormQuestionsFormDate
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateFormQuestion'>
      >
    >;
  }

  OnUpdateFormQuestionListener(
    filter?: ModelSubscriptionFormQuestionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateFormQuestion'>>
  > {
    const statement = `subscription OnUpdateFormQuestion($filter: ModelSubscriptionFormQuestionFilterInput) {
        onUpdateFormQuestion(filter: $filter) {
          __typename
          formQuestionId
          question
          criticality
          weight
          order
          answer
          comment
          imageS3Path
          form {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          rozFormQuestionsFormId
          rozFormQuestionsFormDate
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateFormQuestion'>
      >
    >;
  }

  OnDeleteFormQuestionListener(
    filter?: ModelSubscriptionFormQuestionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteFormQuestion'>>
  > {
    const statement = `subscription OnDeleteFormQuestion($filter: ModelSubscriptionFormQuestionFilterInput) {
        onDeleteFormQuestion(filter: $filter) {
          __typename
          formQuestionId
          question
          criticality
          weight
          order
          answer
          comment
          imageS3Path
          form {
            __typename
            formId
            formDate
            status
            comment
            createdBy
            updatedBy
            questions {
              __typename
              nextToken
            }
            owner
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
          rozFormQuestionsFormId
          rozFormQuestionsFormDate
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteFormQuestion'>
      >
    >;
  }

  OnCreateEditionListener(
    filter?: ModelSubscriptionEditionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateEdition'>>
  > {
    const statement = `subscription OnCreateEdition($filter: ModelSubscriptionEditionFilterInput) {
        onCreateEdition(filter: $filter) {
          __typename
          editionId
          active
          editor
          editorId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateEdition'>>
    >;
  }

  OnUpdateEditionListener(
    filter?: ModelSubscriptionEditionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateEdition'>>
  > {
    const statement = `subscription OnUpdateEdition($filter: ModelSubscriptionEditionFilterInput) {
        onUpdateEdition(filter: $filter) {
          __typename
          editionId
          active
          editor
          editorId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateEdition'>>
    >;
  }

  OnDeleteEditionListener(
    filter?: ModelSubscriptionEditionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteEdition'>>
  > {
    const statement = `subscription OnDeleteEdition($filter: ModelSubscriptionEditionFilterInput) {
        onDeleteEdition(filter: $filter) {
          __typename
          editionId
          active
          editor
          editorId
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteEdition'>>
    >;
  }

  OnCreateMailPreferenceListener(
    filter?: ModelSubscriptionMailPreferenceFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onCreateMailPreference'>
    >
  > {
    const statement = `subscription OnCreateMailPreference($filter: ModelSubscriptionMailPreferenceFilterInput) {
        onCreateMailPreference(filter: $filter) {
          __typename
          userId
          mailId
          name
          receive
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateMailPreference'>
      >
    >;
  }

  OnUpdateMailPreferenceListener(
    filter?: ModelSubscriptionMailPreferenceFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onUpdateMailPreference'>
    >
  > {
    const statement = `subscription OnUpdateMailPreference($filter: ModelSubscriptionMailPreferenceFilterInput) {
        onUpdateMailPreference(filter: $filter) {
          __typename
          userId
          mailId
          name
          receive
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateMailPreference'>
      >
    >;
  }

  OnDeleteMailPreferenceListener(
    filter?: ModelSubscriptionMailPreferenceFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onDeleteMailPreference'>
    >
  > {
    const statement = `subscription OnDeleteMailPreference($filter: ModelSubscriptionMailPreferenceFilterInput) {
        onDeleteMailPreference(filter: $filter) {
          __typename
          userId
          mailId
          name
          receive
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteMailPreference'>
      >
    >;
  }

  OnCreateDriverExclusionListener(
    filter?: ModelSubscriptionDriverExclusionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onCreateDriverExclusion'>
    >
  > {
    const statement = `subscription OnCreateDriverExclusion($filter: ModelSubscriptionDriverExclusionFilterInput) {
        onCreateDriverExclusion(filter: $filter) {
          __typename
          driverId
          startDate
          endDate
          exclusionMotives
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onCreateDriverExclusion'>
      >
    >;
  }

  OnUpdateDriverExclusionListener(
    filter?: ModelSubscriptionDriverExclusionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onUpdateDriverExclusion'>
    >
  > {
    const statement = `subscription OnUpdateDriverExclusion($filter: ModelSubscriptionDriverExclusionFilterInput) {
        onUpdateDriverExclusion(filter: $filter) {
          __typename
          driverId
          startDate
          endDate
          exclusionMotives
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onUpdateDriverExclusion'>
      >
    >;
  }

  OnDeleteDriverExclusionListener(
    filter?: ModelSubscriptionDriverExclusionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'onDeleteDriverExclusion'>
    >
  > {
    const statement = `subscription OnDeleteDriverExclusion($filter: ModelSubscriptionDriverExclusionFilterInput) {
        onDeleteDriverExclusion(filter: $filter) {
          __typename
          driverId
          startDate
          endDate
          exclusionMotives
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'onDeleteDriverExclusion'>
      >
    >;
  }

  OnCreateStatusListener(
    filter?: ModelSubscriptionStatusFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateStatus'>>
  > {
    const statement = `subscription OnCreateStatus($filter: ModelSubscriptionStatusFilterInput) {
        onCreateStatus(filter: $filter) {
          __typename
          statusId
          status
          startedAt
          endedAt
          business
          subBusiness
          carrier
          center
          modelTypeName
          type
          entityId
          sapId
          coupleId
          blockingMotives
          aboutToExpireDocuments
          expiredDocuments
          missingDocuments
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateStatus'>>
    >;
  }

  OnUpdateStatusListener(
    filter?: ModelSubscriptionStatusFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateStatus'>>
  > {
    const statement = `subscription OnUpdateStatus($filter: ModelSubscriptionStatusFilterInput) {
        onUpdateStatus(filter: $filter) {
          __typename
          statusId
          status
          startedAt
          endedAt
          business
          subBusiness
          carrier
          center
          modelTypeName
          type
          entityId
          sapId
          coupleId
          blockingMotives
          aboutToExpireDocuments
          expiredDocuments
          missingDocuments
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateStatus'>>
    >;
  }

  OnDeleteStatusListener(
    filter?: ModelSubscriptionStatusFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteStatus'>>
  > {
    const statement = `subscription OnDeleteStatus($filter: ModelSubscriptionStatusFilterInput) {
        onDeleteStatus(filter: $filter) {
          __typename
          statusId
          status
          startedAt
          endedAt
          business
          subBusiness
          carrier
          center
          modelTypeName
          type
          entityId
          sapId
          coupleId
          blockingMotives
          aboutToExpireDocuments
          expiredDocuments
          missingDocuments
          comment
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteStatus'>>
    >;
  }

  OnCreateArchiveListener(
    filter?: ModelSubscriptionArchiveFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateArchive'>>
  > {
    const statement = `subscription OnCreateArchive($filter: ModelSubscriptionArchiveFilterInput) {
        onCreateArchive(filter: $filter) {
          __typename
          archiveId
          archivedAt
          archivedBy
          business
          carrier
          center
          modelTypeName
          motiveForArchiving
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          comment
          entity
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateArchive'>>
    >;
  }

  OnUpdateArchiveListener(
    filter?: ModelSubscriptionArchiveFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateArchive'>>
  > {
    const statement = `subscription OnUpdateArchive($filter: ModelSubscriptionArchiveFilterInput) {
        onUpdateArchive(filter: $filter) {
          __typename
          archiveId
          archivedAt
          archivedBy
          business
          carrier
          center
          modelTypeName
          motiveForArchiving
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          comment
          entity
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateArchive'>>
    >;
  }

  OnDeleteArchiveListener(
    filter?: ModelSubscriptionArchiveFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteArchive'>>
  > {
    const statement = `subscription OnDeleteArchive($filter: ModelSubscriptionArchiveFilterInput) {
        onDeleteArchive(filter: $filter) {
          __typename
          archiveId
          archivedAt
          archivedBy
          business
          carrier
          center
          modelTypeName
          motiveForArchiving
          documents {
            __typename
            items {
              __typename
              documentId
              masterValueId
              name
              status
              s3Path
              issueDate
              expirationDate
              owner
              createdAt
              updatedAt
              vehicleDocumentsVehicleId
              envasadoDocumentsEnvasadoId
              cisternaDocumentsCisternaId
              tractoDocumentsTractoId
              tanqueDocumentsTanqueId
              semirremolqueDocumentsSemirremolqueId
              driverDocumentsDriverId
              archiveDocumentsArchiveId
              archiveDocumentsArchivedAt
            }
            nextToken
          }
          comment
          entity
          owner
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteArchive'>>
    >;
  }
}
