import { Injectable } from '@angular/core';
import { zonedTimeToUtc } from 'date-fns-tz';
import { formatISO } from 'date-fns';
import {
  APIService,
  CreateArchiveInput,
  Driver,
  GetArchiveQuery,
} from '../../app-sync.service';
import { entities } from '../types/entities';
import { DocumentsService } from '../../pages/documents/documents.service';
import { DocumentFromForms } from '../interfaces/document-from-forms';
import { FeedbacksService } from '../feedbacks/feedbacks.service';
import { UsersService } from '../../pages/users/users.service';
import { appConstants } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class ArchivesService {
  archiveId: string | undefined = undefined;
  archivedAt: string | undefined = undefined;
  archiveEntity: entities | Driver = null;
  motiveForArchiving: string | null = null;
  private archiving: boolean = false;

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private feedbacksService: FeedbacksService,
    private usersService: UsersService
  ) {}

  /**
   * Crea un archivo.
   */
  async createArchive(
    entity: entities | Driver,
    motive: string,
    comment: string | null = null,
    documents: DocumentFromForms[] = []
  ): Promise<void> {
    // Fijamos las variables del servicio.
    this.archivedAt = this.getCurrentAWSDateTimeInSantiago();
    this.archiveEntity = entity;
    this.motiveForArchiving = motive;

    // Verificamos si la entidad tienen un archiveId previo.
    if (entity!.archiveId !== null && entity!.archiveId !== undefined) {
      this.archiveId = entity!.archiveId.split('#')[0];
    } else {
      // Si no tiene se genera una nueva.
      this.archiveId = window.crypto.randomUUID();
    }

    this.feedbacksService.showFeedback(
      `Creando archivo: ${this.archiveId}`,
      'info'
    );

    // 1. Se crea el archivo.
    const uploadDateYYYYMMDD: string = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    const uploadDateTimestamp: string = Math.floor(
      Date.now() / 1000
    ).toString();
    const createArchiveInput: CreateArchiveInput = {
      archiveId: this.archiveId,
      archivedAt: this.archivedAt,
      archivedBy: this.usersService.getActiveUser().userId,
      business: entity?.business!,
      carrier: entity?.company!,
      center: entity?.center!,
      modelTypeName: entity?.__typename!,
      motiveForArchiving: motive,
      comment: comment,
      entity: JSON.stringify(entity),
    };
    await this.api
      .CreateArchive(createArchiveInput)
      .then((archive: CreateArchiveInput): void => {
        this.feedbacksService.showFeedback(
          `Archivo ${archive.archiveId} creado correctamente`,
          'success'
        );

        // 2. Se cargan los documentos del archivo, si aplica.
        this.uploadDocuments(
          documents,
          uploadDateYYYYMMDD,
          uploadDateTimestamp
        );
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al archivar ${this.archiveId}`
        );
      });
  }

  /**
   * Carga una lista de documentos.
   * @param {DocumentFromForms[]} documentList Lista de documentos del formulario.
   * @param {string} uploadDateTimestamp Timestamp de la fecha de edición.
   * @param {string} uploadDateYYYYMMDD Fecha de edición.
   */
  private uploadDocuments(
    documentList: DocumentFromForms[],
    uploadDateYYYYMMDD: string,
    uploadDateTimestamp: string
  ): void {
    for (let document of documentList) {
      // Solo se actualizan los documentos que tengan un archivo asociado.
      if (document.documentSourceFile) {
        const inputDocument = {
          ...document,
          documentId: document.documentMasterValueId + '-' + this.archiveId,
        };
        this.documentsService
          .uploadDocument(
            inputDocument,
            uploadDateYYYYMMDD,
            uploadDateTimestamp,
            this.archiveEntity?.business!,
            'ARCHIVE',
            this.archiveId! + '#' + this.archivedAt
          )
          .then();
      }
    }
  }

  /**
   * Retorna la fecha actual en format válido para el tipo AWSDateTime.
   * @private
   * @return {string} Fecha actual
   */
  private getCurrentAWSDateTimeInSantiago(): string {
    const now: Date = new Date();
    const timeZone: string = 'America/Santiago';

    // Convierte la fecha actual a la zona horaria de Santiago
    const zonedTime: Date = zonedTimeToUtc(now, timeZone);

    // Formatea la fecha en ISO 8601 para AWSDateTime (sin milisegundos)
    return formatISO(zonedTime);
  }

  /**
   * Fija el valor de la variable que da cuenta si se
   * está en un proceso de archivado.
   * @param value
   */
  setArchiving(value: boolean): void {
    this.archiving = value;
  }

  /**
   * Retorna la variable que da cuenta de si se está
   * en un proceso de archivado.
   * @return {boolean}
   */
  getArchiving(): boolean {
    return Boolean(this.archiving);
  }

  /**
   * Retorna el archiveId usado en las entidades.
   * @return {string} archiveId asignado por las entidades.
   */
  getArchiveIdForEntity(): string {
    return this.archiveId + '#' + this.archivedAt;
  }

  /**
   * si el motivo de archivo es "Cambio de Patente" o
   * "Cambio de RUN", indica que se debe cambiar el identificador
   * de la nueva entidad.
   * @return {boolean} ¿Debe cambiar el ID?
   */
  hasToChangeID(): boolean {
    return (
      this.motiveForArchiving?.endsWith(
        appConstants.motives.archive.idChange
      ) || false
    );
  }

  async getArchiveById(archiveId: string): Promise<any> {
    const parts = archiveId.split('#');

    // Validación del formato de archiveId
    if (parts.length !== 2) {
      console.error('archiveId no tiene el formato esperado');
      return undefined;
    }

    const [id, archivatedAt] = parts;
    const archive = await this.api
      .GetArchive(id, archivatedAt)
      .catch((error) => {
        console.error('Error obteniendo el archivo:', error);
        return undefined;
      });

    // Si hubo un error o la API devolvió un valor inválido
    if (!archive) {
      return undefined;
    }

    // Parsear y retornar el archivo
    try {
      archive.entity = JSON.parse(archive.entity);
      return archive;
    } catch (parseError) {
      console.error('Error parseando el archivo:', parseError);
      return undefined;
    }
  }
}
