import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SpinnerComponent } from './spinner/spinner.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';

import { InputTrimDirective } from './directives/input-trim.directive';
import { InputUppercaseDirective } from './directives/input-uppercase.directive';

import { PhonePipe } from './pipes/phone.pipe';
import { SapIdPipe } from './pipes/sap-id.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateWithoutSecondsPipe } from './pipes/date-without-seconds.pipe';
import { ModelNamePipe } from './pipes/model-name.pipe';
import { ModalWithCommentComponent } from './modals/modal-with-comment/modal-with-comment.component';
import { ModalChangeAttributesComponent } from './modals/modal-change-attributes/modal-change-attributes.component';
import { ModalForExclusionsComponent } from './modals/modal-for-exclusions/modal-for-exclusions.component';
import { ModalForArchivingComponent } from './modals/modal-for-archiving/modal-for-archiving.component';
import { ModalDetailsComponent } from './modals/modal-details/modal-details.component';
import { MasterModule } from '../pages/master/master.module';

@NgModule({
  declarations: [
    DateFormatPipe,
    InputTrimDirective,
    InputUppercaseDirective,
    PhonePipe,
    SapIdPipe,
    DateWithoutSecondsPipe,
    ModelNamePipe,
    SpinnerComponent,
    ScrollToTopComponent,
    ModalWithCommentComponent,
    ModalChangeAttributesComponent,
    ModalForExclusionsComponent,
    ModalForArchivingComponent,
    ModalDetailsComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MasterModule],
  exports: [
    DateFormatPipe,
    InputTrimDirective,
    InputUppercaseDirective,
    PhonePipe,
    SapIdPipe,
    ModelNamePipe,
    SpinnerComponent,
    DateWithoutSecondsPipe,
    ScrollToTopComponent,
    ModalWithCommentComponent,
    ModalChangeAttributesComponent,
    ModalForExclusionsComponent,
    ModalForArchivingComponent,
    ModalDetailsComponent,
  ],
})
export class SharedModule {}
