import { Pipe, PipeTransform } from '@angular/core';

import { MasterService } from './master.service';

@Pipe({
  name: 'master',
})
export class MasterPipe implements PipeTransform {
  masterDisplayMap: any;

  constructor(private masterService: MasterService) {
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
  }

  transform(
    value: string,
    mastersId: string,
    defaultDisplay: string = ''
  ): string {
    if (!value) {
      return defaultDisplay;
    }
    if (mastersId === 'CARRIERS') {
      const consultant: string | null = this.masterDisplayMap.CONSULTANTS
        ? this.masterDisplayMap.CONSULTANTS[value]
        : null;
      return (
        this.masterDisplayMap[mastersId][value] || consultant || defaultDisplay
      );
    }
    return this.masterDisplayMap[mastersId][value] || defaultDisplay;
  }
}
