import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterService } from '../../../pages/master/master.service';

@Component({
  selector: 'app-modal-details',
  templateUrl: './modal-details.component.html',
})
export class ModalDetailsComponent {
  @Input() archive: any = {};
  @Input() updatedAt: any = {};
  @Input() comment: any = {};
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();

  readonly master: any = {};
  currentYear: number = new Date().getFullYear();
  constructor(private masterService: MasterService) {
    this.master = this.masterService.getMaster();
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }
}
