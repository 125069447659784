import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  APIService,
  Tracto,
  GetTractoQuery,
  ModelTractoFilterInput,
  ModelSubscriptionIDInput,
  ModelSubscriptionTractoFilterInput,
  User,
  TractosByBusinessAndTractoIdQuery,
  TractosByCenterAndTractoIdQuery,
  TractosByCompanyAndTractoIdQuery,
  UpdateTractoInput,
  UpdateTractoMutation,
  DeleteTractoInput,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { appConstants } from '../../shared/constants/constants';
import { filterInputs } from '../../shared/types/filter-inputs';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { nextToken } from '../../shared/types/next-token';
import { CommonsService } from '../../shared/services/commons.service';

@Injectable({
  providedIn: 'root',
})
export class TractosService {
  selectedTractoChanged = new Subject<Tracto>();
  tractosChanged = new Subject<Tracto[]>();
  decoupledTractosChanged = new Subject<Tracto[]>();

  private readonly initialTracto: Tracto;
  private selectedTracto: Tracto;
  private tractos: Tracto[] = [];
  private decoupledTractos: Tracto[] = [];
  private tractosFilterForSubscriptions: ModelSubscriptionTractoFilterInput =
    {};
  private decoupledTractosFilterForSubscriptions: ModelSubscriptionTractoFilterInput =
    {};
  private selectedTractoFilterForSubscriptions: ModelTractoFilterInput = {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService,
    private commonsService: CommonsService
  ) {
    this.initialTracto = {
      ...appConstants.tracto.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedTracto = this.initialTracto;
    this.setTractosFilter();
  }

  // ---------------------------------------
  // Métodos para el tracto default
  // ---------------------------------------
  /**
   * Retorna una estructura de tracto vacía para iniciar
   * un objeto de tipoTracto.
   * @return {Tracto}
   */
  getInitialTracto(): Tracto {
    return { ...this.initialTracto };
  }

  // --------------------------------------------
  // Métodos para el tracto seleccionado
  // --------------------------------------------
  /**
   * Configura el tracto seleccionado para referencia de
   * todos los componentes.
   * @param {Tracto} tracto Tracto seleccionado.
   */
  setSelectedTracto(tracto: Tracto): void {
    this.selectedTracto = tracto;
    this.setSelectedTractoFilterForSubscriptions();
    this.selectedTractoChanged.next({ ...this.selectedTracto });
    console.log('selectedTracto', this.selectedTracto);

    // Documentos
    this.documentsService.setId('tractoId', this.selectedTracto.tractoId);
    this.documentsService.setSelectedModel('TRACTO');
    this.documentsService.setSelectedCenter(tracto.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del tracto actualizados.'));
  }

  /**
   * Actualiza los datos del tracto seleccionado.
   * @return {Promise}
   */
  async refreshSelectedTracto(): Promise<void> {
    const getTractoResult: GetTractoQuery = await this.api.GetTracto(
      this.selectedTracto.tractoId
    );
    this.setSelectedTracto(<Tracto>getTractoResult);
  }

  /**
   * Retorna el tracto seleccionado.
   * @return {Tracto}
   */
  getSelectedTracto(): Tracto {
    return { ...this.selectedTracto };
  }

  /**
   * Elimina el tracto seleccionado.
   * @param deleteDocuments {boolean}: define si se le pedirá al backend
   * que elimine los documentos de la entidad.
   */
  async deleteSelectedTracto(deleteDocuments: boolean = true): Promise<void> {
    const tractoIdentification: string = this.commonsService.getIdentification(
      this.selectedTracto!.sapId!,
      this.selectedTracto!.licensePlate
    );
    // 1. Actualizamos para dejar constancia
    // del usuario que pidió la eliminación.
    const updateTractoInput: UpdateTractoInput = {
      tractoId: this.selectedTracto!.tractoId,
      updatedBy: this.usersService.getActiveUser().userId,
      comment: `deleteDocuments: ${deleteDocuments}`,
    };

    await this.api
      .UpdateTracto(updateTractoInput)
      .then(async (tracto: UpdateTractoMutation): Promise<void> => {
        // 2. Se procede con la eliminación.
        const deleteTractoInput: DeleteTractoInput = {
          tractoId: tracto.tractoId,
        };

        await this.api
          .DeleteTracto(deleteTractoInput)
          .then(async () => {
            this.feedbacksService.showFeedback(
              `Tracto ${tractoIdentification} eliminado.`,
              'success'
            );
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al borrar tracto ${tractoIdentification}`
            );
          });
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al actualizar tracto ${tractoIdentification}`
        );
      });
  }

  // --------------------------------
  // Métodos para la lista de Tractos
  // ---------------------------------
  /**
   * Configura la lista de tractos para referencia de
   * todos los componentes.
   * @param {Tracto[]} tractos Lista de tractos.
   */
  setTractos(tractos: Tracto[]): void {
    this.tractos = tractos;
    this.setTractosFilter();
    this.tractosChanged.next(this.tractos.slice());
  }

  /**
   * Actualiza la lista de tractos.
   * @return {Promise}
   */
  async refreshTractos(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListTractos: Tracto[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListTractos = await this.listTractosByCarrier(activeUser);
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListTractos = await this.listTractosByBusiness(business);
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListTractos = await this.listTractosByCenter(activeUser, business);
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo tractos. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setTractos(tempListTractos.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listTractosByBusiness(
    business: string,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Tracto[]> {
    let listTractosResult: TractosByBusinessAndTractoIdQuery;
    listTractosResult = await this.api.TractosByBusinessAndTractoId(
      business,
      undefined,
      undefined,
      filter
    );

    let tempListTractos: Tracto[] = <Tracto[]>listTractosResult.items;
    let nextToken: nextToken = listTractosResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListTractosResult: TractosByBusinessAndTractoIdQuery =
        await this.api.TractosByBusinessAndTractoId(
          business,
          undefined,
          undefined,
          filter,
          100,
          nextToken
        );
      tempListTractos.push(...(<Tracto[]>loopListTractosResult.items));
      nextToken = loopListTractosResult.nextToken;
    }
    return tempListTractos.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listTractosByCenter(
    activeUser: User,
    business: string,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Tracto[]> {
    let tempListTractos: Tracto[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const tractosPromises: Promise<Tracto[]>[] = centers.map(
      async (center: string): Promise<Tracto[]> => {
        let listTractosResult: TractosByCenterAndTractoIdQuery;

        listTractosResult = await this.api.TractosByCenterAndTractoId(
          center,
          undefined,
          undefined,
          filter
        );
        let tractos: Tracto[] = <Tracto[]>listTractosResult.items;

        let nextToken: nextToken = listTractosResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListTractosResult: TractosByCenterAndTractoIdQuery =
            await this.api.TractosByCenterAndTractoId(
              center,
              undefined,
              undefined,
              filter,
              100,
              nextToken
            );
          tractos.push(...(<Tracto[]>loopListTractosResult.items));
          nextToken = loopListTractosResult.nextToken;
        }

        return tractos;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Tracto[][] = await Promise.all(tractosPromises);

    // Combinar todos los resultados en una sola lista
    results.forEach((tractos: Tracto[]): void => {
      tempListTractos.push(...tractos);
    });

    return tempListTractos.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @param filter {ModelTractoFilterInput | undefined} Filtro de búsqueda.
   * @private
   */
  private async listTractosByCarrier(
    activeUser: User,
    filter: ModelTractoFilterInput | undefined = undefined
  ): Promise<Tracto[]> {
    let listTractosResult: TractosByCompanyAndTractoIdQuery;
    listTractosResult = await this.api.TractosByCompanyAndTractoId(
      activeUser.company,
      undefined,
      undefined,
      filter
    );

    let tempListTractos: Tracto[] = <Tracto[]>listTractosResult.items;
    let nextToken: nextToken = listTractosResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListTractosResult: TractosByCompanyAndTractoIdQuery =
        await this.api.TractosByCompanyAndTractoId(
          activeUser.company,
          undefined,
          undefined,
          filter,
          100,
          nextToken
        );
      tempListTractos.push(...(<Tracto[]>loopListTractosResult.items));
      nextToken = loopListTractosResult.nextToken;
    }
    return tempListTractos.slice();
  }

  /**
   * Retorna la lista de tractos.
   * @return {Tracto[]}
   */
  getTractos(): Tracto[] {
    return this.tractos.slice();
  }

  /**
   * Configura la lista de tractos desacoplados para referencia de
   * todos los componentes.
   * @param {Tracto[]} tractos Lista de tractos acoplados.
   */
  setDecoupledTractos(tractos: Tracto[]): void {
    this.decoupledTractos = tractos;
    this.setTractosFilter();
    this.decoupledTractosChanged.next(this.decoupledTractos.slice());
  }

  /**
   * Actualiza la lista de tractos desacoplados.
   * @return {Promise}
   */
  async refreshDecoupledTractos(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListDecoupledTractos: Tracto[] = [];
    const decoupledFixedFilters: ModelTractoFilterInput = {
      tractoCoupleCoupleId: { notContains: '#' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      ...(business !== 'FOREIGN' && { sapId: { ne: 'Sin Asignar' } }),
    };

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListDecoupledTractos = await this.listTractosByCarrier(
        activeUser,
        decoupledFixedFilters
      );
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListDecoupledTractos = await this.listTractosByBusiness(
        business,
        decoupledFixedFilters
      );
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListDecoupledTractos = await this.listTractosByCenter(
        activeUser,
        business,
        decoupledFixedFilters
      );
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo tractos. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setDecoupledTractos(tempListDecoupledTractos.slice());
  }

  /**
   * Retorna la lista de tractos desacoplados.
   * @return {Tracto[]}
   */
  getDecoupledTractos(): Tracto[] {
    return this.decoupledTractos.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de tractos y subscripciones.
   * @private
   */
  private setTractosFilter(): void {
    const business: string = this.usersService.business.value.toUpperCase();
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();
    const decoupledFixedFilters: ModelSubscriptionTractoFilterInput = {
      tractoCoupleCoupleId: { notContains: '#' },
      status: { eq: `${business}_${appConstants.entity.codes.blocked}` },
      ...(business !== 'FOREIGN' && { sapId: { ne: 'Sin Asignar' } }),
    };

    this.tractosFilterForSubscriptions = <ModelSubscriptionTractoFilterInput>(
      modelFilterInput
    );
    this.decoupledTractosFilterForSubscriptions = {
      ...modelFilterInput,
      ...decoupledFixedFilters,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de tractos.
   * @return {ModelSubscriptionTractoFilterInput}
   */
  getTractosFilterForSubscriptions(): ModelSubscriptionTractoFilterInput {
    return { ...this.tractosFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del tracto seleccionado.
   */
  private setSelectedTractoFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedTracto.tractoId,
    };
    this.selectedTractoFilterForSubscriptions = {
      tractoId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del tracto seleccionado.
   * @return {ModelSubscriptionTractoFilterInput}
   */
  getSelectedTractoFilterForSubscriptions(): ModelSubscriptionTractoFilterInput {
    return { ...this.selectedTractoFilterForSubscriptions };
  }

  /**
   * Retorna el filtro para las consultas
   * de listas de tractos desacoplados.
   * @return {ModelSubscriptionTractoFilterInput}
   */
  getDecoupledTractosFilterForSubscriptions(): ModelSubscriptionTractoFilterInput {
    return { ...this.decoupledTractosFilterForSubscriptions };
  }

  /**
   * Verifica la existencia de un tracto.
   * @param {string} tractoId ID del tracto.
   */
  async checkTractoExistence(tractoId: string): Promise<boolean> {
    const getTractoResult: GetTractoQuery = await this.api.GetTracto(tractoId);
    return Boolean(getTractoResult);
  }
}
