import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { appConstants } from '../../shared/constants/constants';

import {
  APIService,
  DeleteUserInput,
  GetUserQuery,
  ModelSubscriptionIDInput,
  ModelSubscriptionUserFilterInput,
  ModelUserFilterInput,
  UpdateUserInput,
  UpdateUserMutation,
  User,
  UsersByBusinessAndUserIdQuery,
} from '../../app-sync.service';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { filterInputs } from '../../shared/types/filter-inputs';
import { nextToken } from '../../shared/types/next-token';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  activeUserChanged = new Subject<User>();
  selectedUserChanged = new Subject<User>();
  usersChanged = new Subject<User[]>();
  // Las siguientes variables se refieren al usuario activo
  // (el que está haciendo uso de la aplicación).
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isDriver: boolean = false;
  isRTC: boolean = false;
  isViewer: boolean = false;
  business = new BehaviorSubject<string>('');
  authGroup = new BehaviorSubject<string>('');
  inHomePage = new BehaviorSubject<boolean>(true);

  private readonly initialUser: User;
  private activeUser: User;
  private selectedUser: User;
  private selectedUserFilterForSubscriptions: ModelUserFilterInput = {};
  private users: User[] = [];
  private usersFilterForSubscriptions: ModelSubscriptionUserFilterInput = {};

  constructor(
    private api: APIService,
    private feedbacksService: FeedbacksService
  ) {
    this.initialUser = appConstants.user.initialization;
    this.selectedUser = this.initialUser;
    this.activeUser = this.initialUser;
    this.setUsersFilter();
  }

  // ------------------------------------
  // Métodos para el usuario seleccionado
  // ------------------------------------
  /**
   * Configura el usuario seleccionado para referencia de
   * todos los componentes.
   * @param {User} user Usuario seleccionado.
   */
  setSelectedUser(user: User): void {
    this.selectedUser = user;
    this.setSelectedUserFilterForSubscriptions();
    this.selectedUserChanged.next({ ...this.selectedUser });
  }

  /**
   * Actualiza los datos del usuario seleccionado.
   * @return {Promise}
   */
  async refreshSelectedUser(): Promise<void> {
    const getUserResult: GetUserQuery = await this.api.GetUser(
      this.selectedUser.userId
    );
    this.setSelectedUser(<User>getUserResult);
  }

  /**
   * Retorna el usuario seleccionado.
   * @return {User}
   */
  getSelectedUser(): User {
    return { ...this.selectedUser };
  }

  /**
   * Elimina el usuario seleccionado.
   */
  async deleteSelectedUser(): Promise<void> {
    const userIdentification: string = this.selectedUser!.userId;
    // 1. Actualizamos para dejar constancia
    // del usuario que pidió la eliminación.
    const updateUserInput: UpdateUserInput = {
      userId: this.selectedUser!.userId,
      updatedBy: this.activeUser.userId,
    };

    await this.api
      .UpdateUser(updateUserInput)
      .then(async (user: UpdateUserMutation): Promise<void> => {
        // 2. Se procede con la eliminación.
        const deleteUserInput: DeleteUserInput = {
          userId: user.userId,
        };

        await this.api
          .DeleteUser(deleteUserInput)
          .then(async () => {
            this.feedbacksService.showFeedback(
              `Usuario ${userIdentification} eliminado.`,
              'success'
            );
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al borrar usuario ${userIdentification}`
            );
          });
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al actualizar usuario ${userIdentification}`
        );
      });
  }

  // ------------------------------
  // Métodos para el usuario activo
  // ------------------------------
  /**
   * Configura al usuario activo (el que está haciendo uso de la aplicación).
   * para referencia de todos los componentes.
   * @param {User} user
   */
  setActiveUser(user: User): void {
    this.activeUser = user;
    this.isAdmin = this.activeUser.authGroup.endsWith('_ADMINS');
    this.isApprover = this.activeUser.authGroup.endsWith('_APPROVERS');
    this.isCarrier = this.activeUser.authGroup.endsWith('_CARRIERS');
    this.isDriver = this.activeUser.authGroup.endsWith('_DRIVERS');
    this.isRTC = this.activeUser.authGroup.endsWith('_RTC');
    this.isViewer = this.activeUser.authGroup.endsWith('_VIEWERS');
    this.business.next(this.activeUser.business.toLowerCase());
    this.authGroup.next(this.activeUser.authGroup);
    this.activeUserChanged.next({ ...this.activeUser });
  }

  /**
   * Retorna el usuario activo (el que está haciendo uso de la aplicación).
   * @return {User}
   */
  getActiveUser(): User {
    return { ...this.activeUser };
  }

  /**
   * Retorna el estado de usuario activo (el que está haciendo uso de la aplicación).
   * @return {string}
   */
  getActiveUserStatus(): string {
    return this.activeUser.status;
  }

  // ---------------------------------
  // Métodos para la lista de Usuarios
  // ---------------------------------
  /**
   * Configura la lista de usuarios para referencia de
   * todos los componentes.
   * @param {User[]} users Lista de usuarios.
   */
  setUsers(users: User[]): void {
    this.users = users;
    this.setUsersFilter();
    this.usersChanged.next(this.users.slice());
  }

  /**
   * Actualiza la lista de usuarios.
   * @return {Promise}
   */
  async refreshUsers(): Promise<void> {
    let tempListUsers: User[] = [];

    // Verificamos el grupo del usuario
    if (this.activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListUsers = await this.listUsersByBusiness(
        this.business.value.toUpperCase()
      );
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo usuarios. Grupo ${this.activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setUsers(tempListUsers.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @private
   */
  private async listUsersByBusiness(business: string): Promise<User[]> {
    let listUsersResult: UsersByBusinessAndUserIdQuery;
    listUsersResult = await this.api.UsersByBusinessAndUserId(business);

    let tempListUsers: User[] = <User[]>listUsersResult.items;
    let nextToken: nextToken = listUsersResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListUsersResult: UsersByBusinessAndUserIdQuery =
        await this.api.UsersByBusinessAndUserId(
          business,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListUsers.push(...(<User[]>loopListUsersResult.items));
      nextToken = loopListUsersResult.nextToken;
    }
    return tempListUsers.slice();
  }

  /**
   * Retorna la lista de usuarios.
   * @return {User[]}
   */
  getUsers(): User[] {
    return this.users.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de usuarios y subscripciones.
   */
  setUsersFilter(): void {
    // Los usuarios solo son accesibles por Administradores
    let modelFilterInput:
      | ModelUserFilterInput
      | ModelSubscriptionUserFilterInput;

    // Verificamos el grupo del usuario
    if (this.activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los usuarios
      // asociados a su negocio
      modelFilterInput = {
        business: { eq: this.business.value.toUpperCase() },
      };
    } else {
      if (this.activeUser.authGroup !== '') {
        this.feedbacksService.showFeedback(
          `Error generando filtro de usuarios: Grupo ${this.business.value.toUpperCase()} no tiene regla definida.`,
          'danger'
        );
      }
      modelFilterInput = {
        business: { eq: 'NONE' },
      };
    }

    this.usersFilterForSubscriptions = modelFilterInput;
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de usuarios.
   * @return {ModelSubscriptionUserFilterInput}
   */
  getUsersFilterForSubscriptions(): ModelSubscriptionUserFilterInput {
    return { ...this.usersFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del usuario seleccionado.
   */
  private setSelectedUserFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedUser.userId,
    };
    this.selectedUserFilterForSubscriptions = {
      userId: modelSubscriptionIDInput,
    };
  }

  /**
   * Configura el filtro para las subscripciones
   * del usuario seleccionado.
   * @return {ModelSubscriptionUserFilterInput}
   */
  getSelectedUserFilterForSubscriptions(): ModelSubscriptionUserFilterInput {
    return { ...this.selectedUserFilterForSubscriptions };
  }

  /**
   * Retorna parte del filtro para las consultas
   * de listas de entidades y subscripciones.
   * @param {boolean} forDrivers Indica si el filtro es para conductores.
   * @return {filterInputs}
   */
  getEntitiesFilter(forDrivers: boolean = false): filterInputs {
    const business: string = this.business.value.toUpperCase();
    let modelFilterInput: filterInputs;

    // Verificamos el grupo del usuario
    if (this.activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos/conductores donde
      // ellos figuren como Carrier
      modelFilterInput = {
        company: { eq: this.activeUser.company },
      };
    } else if (this.activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      modelFilterInput = {
        business: { eq: business },
      };
    } else if (
      this.activeUser.authGroup.endsWith('_VIEWERS') ||
      this.activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos/conductores
      // asociados a los centros a los que pertenece el usuario.
      modelFilterInput = {
        or: this.getCentersFilterInput(),
      };
    } else if (this.activeUser.authGroup.endsWith('_DRIVERS')) {
      if (forDrivers) {
        // Los conductores podrán acceder solo a su registro
        modelFilterInput = {
          driverId: { eq: this.activeUser.rut.replace(/[^0-9K]/g, '') },
        };
      } else {
        // Los conductores no podrán acceder a los vehículos
        modelFilterInput = {
          business: { eq: 'NONE' },
        };
      }
    } else if (
      this.activeUser.authGroup === '' ||
      this.activeUser.authGroup.includes('_')
    ) {
      // Si no se ha cargado el usuario aún o tiene cualquier otro rol
      // no tiene ningún acceso.
      modelFilterInput = {
        business: { eq: 'NONE' },
      };
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo filtro. Grupo ${this.activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
      modelFilterInput = {
        business: { eq: 'NONE' },
      };
    }
    return modelFilterInput;
  }

  /**
   * Genera un arreglo de filtros para 10 centros a
   * los que pertenezca el usuario.
   * @return {{ center: { eq: string } }[]}
   * @private
   */
  private getCentersFilterInput(): { center: { eq: string } }[] {
    const business: string = this.business.value.toUpperCase();
    let inputsArray: { center: { eq: string } }[] = [];

    for (const center of JSON.parse(
      this.activeUser.centers || `{"${business}": []}`
    )[business] || []) {
      inputsArray.push({ center: { eq: center } });
      // Existe una restricción de 10 filtros en AppSync
      // (https://docs.aws.amazon.com/appsync/latest/devguide/extensions.html)
      if (inputsArray.length === 10) {
        break;
      }
    }

    return inputsArray;
  }

  // --------------------
  // Métodos para estilos
  // --------------------
  /**
   * Retorna un color dependiendo del estado del usuario.
   * @param {User} user Usuario a considerar.
   * @return {string}
   */
  getSatusColor(user: User): string {
    let statusColor: string;
    if (user.status === `${user.business}_${appConstants.user.codes.active}`) {
      statusColor = appConstants.colors.statuses.available;
    } else if (
      user.status === `${user.business}_${appConstants.user.codes.blocked}`
    ) {
      statusColor = appConstants.colors.statuses.blocked;
    } else {
      statusColor = appConstants.colors.statuses.rejected;
    }

    return statusColor;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del usuario.
   * @param {string} status Estado del usuario.
   * @param {string} business Negocio del usuario.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string, business: string): string {
    let imageSrc: string = 'assets/images/icons/';

    if (status === `${business}_${appConstants.user.codes.active}`) {
      imageSrc += 'available';
    } else if (status === `${business}_${appConstants.user.codes.blocked}`) {
      imageSrc += 'blocked';
    } else {
      imageSrc += 'rejected';
    }

    return imageSrc + '.svg';
  }

  /**
   * Verifica la existencia de un usuario.
   * @param {string} userId Correo del usuario.
   */
  async checkUserExistence(userId: string): Promise<boolean> {
    const getUserResult: GetUserQuery = await this.api.GetUser(userId);
    return Boolean(getUserResult);
  }
}
